import React, { useContext, useEffect, useRef } from 'react';
import {
	Routes, Route, useLocation,
} from 'react-router-dom';
import gsap from 'gsap';
import * as POPUPS from 'components/Popups';

import { UiContext } from 'context/ui';
import { AppDataContext } from "context/appData";
import { ToastContainer } from 'react-toastify';
import { Header } from 'components/Header';
import { PrivateRoute, PrivateAdminRoute } from 'components/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
import { ScrollToTop } from 'components/ScrollToTop';
import { TABLET_MEDIA_POINT, REACT_APP_CRISP_CHAT_WEBSITE_ID } from 'utils/constant';
import classNames from 'classnames';
import { useWindowSize } from 'hooks';
import { SideLogoList } from 'components/SideLogoList';
import { FaqBlock } from 'components/Faq';
import { MainMenu, MainMenuDecors } from 'components/MainMenu';
import { Crisp } from "crisp-sdk-web";
import { mouseflow } from "react-mouseflow";
import * as PAGES from './pages';

const App = () => {
	const location = useLocation();
	const { appPopups } = useContext(UiContext);
	const { langApp } = useContext(AppDataContext);

	const windowSize = useWindowSize();
	const wrapperClassElement = classNames('wrapper', {
		'wrapper--mobile_state': windowSize.width < TABLET_MEDIA_POINT,
	});
	const $bg = useRef(null);

	const bgAnimHandler = () => {
		let showAnimationValue = window.location.href.indexOf("showAnimation=true") + 1;

		if (showAnimationValue) {
			gsap.to($bg.current, {
				y: '-104vh',
				duration: 0.8,
				delay: 0.3,
				ease: 'power2.out',
			});
		} else {
			document.body.classList.add('body--no_anim_mod');
		}
	};

	useEffect(() => {
		bgAnimHandler();
		mouseflow.initialize(process.env.REACT_APP_MOUSEFLOW_WEBSITE_ID);
	}, []);

	Crisp.configure(REACT_APP_CRISP_CHAT_WEBSITE_ID, {
		locale: langApp,
	});

	return (
		<>
			<Header darkMode />
			<MainMenu />
			<MainMenuDecors />
			{windowSize.width < TABLET_MEDIA_POINT ? (
				<POPUPS.SupportedDevicesPopup />
			) : null}
			<SideLogoList />
			<FaqBlock />
			<div className="page_bg" ref={$bg} />
			<div className={wrapperClassElement}>
				<div className="base">
					<PAGES.HomePage />
					<ScrollToTop />
					<Routes location={location}>
						<Route element={<PAGES.IndexPage />} path="/" />
						<Route element={<PAGES.CartPage />} path="/cart" />
						<Route element={<PAGES.GetMyGlassPage />} path="/get-my-glass" />
						<Route element={<PAGES.SummaryPage />} path="/summary" />
						<Route element={<PAGES.RegisterByInvintationPage />} path="/register/:companyId" />
						<Route
							path="/account"
							element={(
								<PrivateRoute>
									<PAGES.AccountPage />
								</PrivateRoute>
							)}
						/>
						<Route
							path="/dashboard"
							element={(
								<PrivateRoute>
									<PAGES.CustomerDashboardPage />
								</PrivateRoute>
							)}
						/>
						<Route
							path="/client-dashboard/:companyId"
							element={(
								<PrivateAdminRoute>
									<PAGES.CustomerDashboardPage />
								</PrivateAdminRoute>
							)}
						/>
						<Route
							path="/preview/:configurationCode"
							element={(
								<PrivateRoute>
									<PAGES.PreviewPage />
								</PrivateRoute>
							)}
						/>
						<Route
							path="/admin-dashboard"
							element={(
								<PrivateAdminRoute>
									<PAGES.AdminDashboardPage />
								</PrivateAdminRoute>
							)}
						/>
						<Route
							path="*"
							element={<PAGES.NotFoundPage />}
						/>
					</Routes>
				</div>

				<POPUPS.GenericWarningPopup isOpen={appPopups.genericWarning.isOpen} />
				<POPUPS.RegistrationPopup isOpen={appPopups.registration.isOpen} />
				<POPUPS.LoginPopup isOpen={appPopups.login.isOpen} />
				<POPUPS.ConfigurationCodePopup isOpen={appPopups.configurationCode.isOpen} />
				<POPUPS.ConfirmFilterChangesPopup isOpen={appPopups.confirmFilterChanges.isOpen} />
				<POPUPS.LostPasswordPopup isOpen={appPopups.lostPassword.isOpen} />
				<POPUPS.ResetPasswordPopup isOpen={appPopups.resetPassword.isOpen} />
				<POPUPS.DeletePositionPopup isOpen={appPopups.deletePosition.isOpen} />
				<POPUPS.ResetConfigurationPopup isOpen={appPopups.resetConfiguration.isOpen} />
				<POPUPS.ResetGlassPopup isOpen={appPopups.resetGlass.isOpen} />
				<POPUPS.DeleteAttachmentPopup isOpen={appPopups.deleteAttachment.isOpen} />
				<POPUPS.FaqPopup isOpen={appPopups.faq.isOpen} />
				<ToastContainer />
			</div>
		</>
	);
};

export default App;
