// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BurgerIcon } from 'i/icons/burger.svg';
import jsonAppData from 'utils/jsonAppData';
import { cloneObjectWithoutReference, generateUniqueId, getCurrentDate } from 'utils';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfGlassDocument } from 'components/PdfComponents';
import { ConfigurationDataContext } from 'context/configuration';
import { UiContext } from 'context/ui';

const GlassConfigDropdown = (
	{
		parent,
		attrKey,
		glassData,
		showPrice,
	},
) => {
	const { t } = useTranslation('commonConfigurationAction');
	const { showPopupByKey } = useContext(UiContext);
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);
	const { editConfiguration, totalGlassSizes } = useContext(
		ConfigurationDataContext,
	);
	const isMounted = useRef(true);
	const menuButtonRef = useRef(null);

	useEffect(() => {
		// Component mounts
		return () => {
			// Component unmounts
			isMounted.current = false;
		};
	}, []);

	const toggleDropdownState = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const removeGlassItem = () => {
		if (parent.constructor === Array) {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfGlassDeletion'),
				confirmDialog: isConfirmed => isConfirmed && parent.splice(attrKey, 1),
			});
		}
	};

	const editGlassItem = () => {
		// editing item {glassData} with index {attrKey}
		if (parent.constructor === Array) {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfGlassEdit'),
				confirmDialog: isConfirmed => isConfirmed && editConfiguration(glassData),
			});
		}
	};

	const generatePdf = async () => {
		setPdfLoading(true);
		const currentDate = getCurrentDate();
		const documentFileName = (t("configurationDotPdfFmt")).replace("{date}", currentDate);
		const pdfDocument = (
			<PdfGlassDocument
				glassData={glassData}
				totalValues={totalGlassSizes?.[attrKey]}
				showPrice={showPrice}
			/>
		);
		const blob = await pdf(pdfDocument).toBlob();
		setPdfLoading(false);
		saveAs(blob, documentFileName);
	};

	const onClickOptionHandler = action => {
		if (action === 'delete') {
			removeGlassItem();
		} else if (action === 'edit') {
			editGlassItem();
		} else if (action === 'duplicate') {
			const duplicatedGlass = cloneObjectWithoutReference(parent[attrKey]);
			duplicatedGlass.id = generateUniqueId();
			let trans = parent.transact();

			if (duplicatedGlass.positions) {
				duplicatedGlass.positions.forEach(position => {
					// eslint-disable-next-line no-param-reassign
					position.id = generateUniqueId();
				});
			}

			trans.push(duplicatedGlass);
			parent.run();
		} else if (action === 'saveAsPdf') {
			generatePdf();
		}
	};

	const onClickBurgerHandler = () => {
		toggleDropdownState();
	};

	const onBlurBurgerButtonHandler = () => {
		setTimeout(() => {
			if (isMounted.current) {
				setDropdownOpen(false);
			}
		}, 200);
	};

	const checkDropdownFoldingUpwards = () => {
		if (!menuButtonRef.current) {
			return false;
		}
		const viewportHeight = window.innerHeight;
		const { bottom } = menuButtonRef.current.getBoundingClientRect();

		const spaceBelow = viewportHeight - bottom;
		// Check if the space below the button is less than 20% of the viewport height
		return (spaceBelow < viewportHeight * 0.2);
	};

	const dropdownFoldUpStyle = {
		top: 'auto',
		bottom: '100%',
	};

	const foldUp = checkDropdownFoldingUpwards();

	return (
		<div className="glass_config_dropdown">
			<button
				className="glass_config_dropdown__burger"
				type="button"
				onClick={onClickBurgerHandler}
				onBlur={onBlurBurgerButtonHandler}
			>
				<BurgerIcon ref={menuButtonRef} className="icon icon-burger" />
			</button>
			{isDropdownOpen ? (
				<ul style={foldUp ? dropdownFoldUpStyle : null} className="glass_config_dropdown__options v2_mod">
					{jsonAppData.glassConfigDropdown.map(({
						label,
					}, index) => {
						return (
							<li className="glass_config_dropdown__item" key={index}>
								{label === "saveAsPdf" && pdfLoading
									? (t('creatingPdf'))
									: (
										<button
											className="glass_config_dropdown__button"
											type="submit"
											onClick={
												(e) => {
													e.stopPropagation();
													onClickOptionHandler(label);
												}
											}
										>
											{t(label)}
										</button>
									)}
							</li>
						);
					})}
				</ul>
			) : null}

		</div>
	);
};

export default GlassConfigDropdown;
