import AbstractFilter from './AbstractFilter.js'

export default class ManufacturerFilter extends AbstractFilter {
	constructor() {
		super('ManufacturerFilter');
		this.i18n_prefix = "manufacturer_";
		this.filterOptions = {
			"N/A": "indep",
			"AGC": "AGC",
			"Glas Trösch": "GlasTroesch",
		};
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		// CDR: Note that 'N/A' (Vendor independent) is not any vendor so it needs to be filtered
		if (this.selectedFilter === null) {
			return;
		}

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (dbView['frg'][i].manufacturer !== this.selectedFilter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}
