import {
  BackSide, BoxGeometry, FrontSide, Mesh, MeshBasicMaterial, PlaneGeometry, sRGBEncoding,
} from 'three';
import { ResourceContainer } from 'ohzi-core';

import GenericStructureElement from './GenericStructureElement';
import AluminiumTapeMaterial from './../materials/AluminiumTapeMaterial';


export default class Tape extends GenericStructureElement {

  constructor(logoOnBack = false) {
    super();

    this.depth = 0.002;

    const length = 2, height = 2, thickness = 0.15, thickness_short = 0.03;

    const geometry_y = new BoxGeometry(height - thickness, thickness, this.depth);
    geometry_y.rotateZ(0.5 * Math.PI);
    geometry_y.translate(thickness * 0.5, (height + thickness) * 0.5, this.depth * 0.5);

    const geometry_x = new BoxGeometry(length, thickness, this.depth);
    geometry_x.translate(length * 0.5, thickness * 0.5, this.depth * 0.5);

    //short geo
    const geometry_y_short = new BoxGeometry(height - thickness_short, thickness_short, this.depth);
    geometry_y_short.rotateZ(0.5 * Math.PI);
    geometry_y_short.translate(thickness_short * 0.5, (height + thickness_short) * 0.5, this.depth * 0.5);

    const geometry_x_short = new BoxGeometry(length, thickness_short, this.depth);
    geometry_x_short.translate(length * 0.5, thickness_short * 0.5, this.depth * 0.5);

    const alu_material = new AluminiumTapeMaterial();
    this.alu_material = alu_material;

    this.cube_y = new Mesh(geometry_y, alu_material);
    this.cube_y.receiveShadow = true;
    this.cube_y.castShadow = true;
    this.cube_y.renderOrder = 1001;
    this.add(this.cube_y);

    this.cube_x = new Mesh(geometry_x, alu_material);
    this.cube_x.receiveShadow = true;
    this.cube_x.castShadow = true;
    this.cube_x.renderOrder = 1001;
    this.add(this.cube_x);

    // short alu tape
    this.cube_y_short = new Mesh(geometry_y_short, alu_material);
    this.cube_y_short.receiveShadow = true;
    this.cube_y_short.castShadow = true;
    this.cube_y_short.renderOrder = 1001;
    this.add(this.cube_y_short);

    this.cube_x_short = new Mesh(geometry_x_short, alu_material);
    this.cube_x_short.receiveShadow = true;
    this.cube_x_short.castShadow = true;
    this.cube_x_short.renderOrder = 1001;
    this.add(this.cube_x_short);
    this.applyLogo(logoOnBack);

    //this.set_short(false);
  }

  applyLogo(logoOnBack = false) {
    const logoTexture = ResourceContainer.get_resource('cdr_logo');
    logoTexture.encoding = sRGBEncoding;

    // Function to apply logo to a given cube
    const applyLogoToCube = (cube) => {
      const maxLogoHeight = Math.min(cube.geometry.parameters.height, cube.geometry.parameters.width) * 0.75;
      const logoAspect = logoTexture.image.width / logoTexture.image.height;
      const logoWidth = maxLogoHeight * logoAspect;

      // Create a material for the logo that blends over the aluminium tape
      const logoMaterial = new MeshBasicMaterial({
        map: logoTexture,
        transparent: true,
        depthTest: false,
        depthWrite: false,
        envMap: null,
        side: logoOnBack ? BackSide : FrontSide,
      });

      // Calculate size and position for the logo
      const logoGeometry = new PlaneGeometry(logoWidth, maxLogoHeight);
      const logoMesh = new Mesh(logoGeometry, logoMaterial);

      const zOffset = 0.001; // slight offset to avoid z-fighting
      // Center logo on the tape
      logoMesh.position.set(
        cube.geometry.parameters.width * 0.9,
        cube.geometry.parameters.height / 2,
        logoOnBack ? -zOffset : zOffset
      );

      // Mirror the logo on the Z-axis if it's on the back
      if (logoOnBack) {
        logoMesh.scale.set(-1, 1, 1); // Flips the mesh on the X-axis
      }

      cube.add(logoMesh);
    };

    // Apply logo to both long and short alu tape
    applyLogoToCube(this.cube_x);
    //applyLogoToCube(this.cube_x_short);
  }

  set_short(aBoolean) {
    if (aBoolean) {
      this.cube_y_short.visible = true;
      this.cube_x_short.visible = true;
      this.cube_y.visible = false;
      this.cube_x.visible = false;
    }
    else {
      this.cube_y_short.visible = false;
      this.cube_x_short.visible = false;
      this.cube_y.visible = true;
      this.cube_x.visible = true;
    }

  }

  update() {
    this.alu_material.update();
  }

  get width() {
    return this.depth;
  }
}
