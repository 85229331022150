export default class GlassConfigUtil {
	static translate(glassConfig, lang, uiHandlerRef) {
		if (lang === glassConfig.lang) {
			return null;
		}
		if (!uiHandlerRef || !uiHandlerRef.current || !uiHandlerRef.current.isUiLoaded) {
			return null;
		}
		const translateGlass = (g) => (g ? uiHandlerRef.current.glassDB.translateGlass(g, lang) : '-');
		let { flatGlassStructure } = glassConfig;
		let { insulationValue, soundDampening } = flatGlassStructure;
		let uiHandler = uiHandlerRef.current;
		let structureUI = uiHandler.structureFilterUIElement;
		let manufacturerUI = uiHandler.manufacturerFilterUIElement;
		let fireResistanceClassUI = uiHandler.fireClassFilterUIElement;
		// let fireGlassUI = uiHandler.uiFireResistant;
		// let middleGlassUI = uiHandler.uiMiddleGlass;
		// let outerGlassUI = uiHandler.uiOuterGlass;
		let insulationUI = uiHandler.insulationFilterUIElement;
		let applicationUI = uiHandler.applicationFilterUIElement;
		let whiteGlassUI = uiHandler.whiteGlassFilterUIElement;
		let resistanceClassUI = uiHandler.resistanceClassFilterUIElement;
		let buttJointsUI = uiHandler.buttJointsFilterUIElement;
		let soundUI = uiHandler.soundFilterUIElement;
		let spacerUI = uiHandler.spacer1UIElement;
		let gasUI = uiHandler.gas1UIElement;
		if (insulationValue === '-1.0') {
			// eslint-disable-next-line no-console
			console.error("No insulation value for this glass");
			insulationValue = '5.4'; // prevent a lookup error by substituting with the worst insulation
		}

		return {
			lang,
			flatGlassStructure: glassConfig.flatGlassStructure,
			thickness: `${glassConfig.flatGlassStructure.thickness} mm`,
			structure: structureUI.t(structureUI.filter.filterOptions[flatGlassStructure.structure]),
			manufacturer: manufacturerUI.t(manufacturerUI.filter.filterOptions[flatGlassStructure.manufacturer]),
			fireResistanceClass: fireResistanceClassUI.t(fireResistanceClassUI.filter.filterOptions[flatGlassStructure.fireResistance]),
			insulation: (insulationValue === -1) ? '-' : insulationUI.t(insulationUI.filter.filterOptions[insulationValue]).replace('{}', insulationValue),
			application: applicationUI.t(applicationUI.filter.filterOptions[flatGlassStructure.insideOutsideApplication]),
			whiteGlass: whiteGlassUI.t(flatGlassStructure.isClearGlass ? 'yes' : 'no'),
			resistanceClass: resistanceClassUI.t(resistanceClassUI.filter.filterOptions[flatGlassStructure.resistanceClass]),
			buttJoints: buttJointsUI.t(flatGlassStructure.isButtJointCompatible ? 'yes' : 'no'),
			sound: soundUI.t(soundUI.filter.filterOptions[soundDampening]).replace('{}', soundDampening),
			fireResistant: translateGlass(uiHandler.glassDB.getGlassById(flatGlassStructure.fireResistantGlassId, 'frg')),
			spacer1: spacerUI.tVal(flatGlassStructure.spacerMiddle), // spacers and gas reversed - maybe needs (struct === '2-glazed' ? out : mid)
			spacer2: spacerUI.tVal(flatGlassStructure.spacerOutside),
			gas1: gasUI.tVal(flatGlassStructure.gasMiddle),
			gas2: gasUI.tVal(flatGlassStructure.gasOutside),
			middleGlass: flatGlassStructure.middleGlassId ? translateGlass(uiHandler.glassDB.getGlassById(flatGlassStructure.middleGlassId, 'mid')) : '-',
			outsideGlass: flatGlassStructure.outerGlassId ? translateGlass(uiHandler.glassDB.getGlassById(flatGlassStructure.outerGlassId, 'out')) : '-',
		};
	}

	static convertFlatGlassStructureToGlassConfig(flatGlassStructure, uiHandlerRef, lang) {
		const glassConfig = {
			lang: null,
			flatGlassStructure,
		};
		return GlassConfigUtil.translate(glassConfig, lang, uiHandlerRef);
	}
}
