export default class SoundDampening {
    static soundDampeningTable = {
        '1-glazed': [
            // FSF
            // { glassIdsFRG: [], Rw: 36, C: -1, Ctr: -4 }, // 6-1P, ED 6.5
            { glassIdsFRG: [253], Rw: 38, C: -1, Ctr: -4 }, // VSG 8-1P (Schallschutzfolie), ED 8.5
            // { glassIdsFRG: [], Rw: 39, C: -1, Ctr: -3 }, // 10-1P, ED 10.5
            // { glassIdsFRG: [], Rw: 39, C: 0, Ctr: -2 }, // 12-1P, ED 12.5
            // { glassIdsFRG: [], Rw: 41, C: -1, Ctr: -3 }, // 16-1P, ED 16.5
            { glassIdsFRG: [103], Rw: 38, C: -1, Ctr: -3 }, // 30-15, ED 15.0
            // 30-16	16.0
            { glassIdsFRG: [107, 108], Rw: 39, C: -1, Ctr: -3 }, // 30-19, ED 19.0
            { glassIdsFRG: [109], Rw: 40, C: 0, Ctr: -3 }, // 30-19 6-2 SC, ED 19.0
            // { glassIdsFRG: [], Rw: 41, C: 0, Ctr: -3 }, // 30-19 10-2 SC, ED 23.0
            // 30-19/1	23.0
            { glassIdsFRG: [123], Rw: 43, C: -1, Ctr: -3 }, // 60-23, ED 23.0
            { glassIdsFRG: [125, 126], Rw: 44, C: -1, Ctr: -3 }, // 60-27, ED 27.0
            // { glassIdsFRG: [], Rw: 44, C: -1, Ctr: -3 }, // 60-27 6-2 SC, ED 27.0
            { glassIdsFRG: [129, 1290], Rw: 45, C: -1, Ctr: -4 }, // 60-27/1, ED 31.0
            // 60-27/2	35.0Value

            // Pyrobel
            { glassIdsFRG: [113], Rw: 39, C: -1, Ctr: -3 }, // PYB 16, ED 17.0
            { glassIdsFRG: [114], Rw: 39, C: -1, Ctr: -3 }, // PYB 16 CV, ED 17.0
            { glassIdsFRG: [115], Rw: 39, C: -1, Ctr: -3 }, // PYB 16 EG, ED 21.0
            { glassIdsFRG: [116, 1160], Rw: 39, C: -1, Ctr: -3 }, // PYB 16 EG CV, ED 21.0
            // { glassIdsFRG: [], Rw: 45, C: -1, Ctr: -4 }, // PYB 16 EG SPS st, ED 35.0
            { glassIdsFRG: [131], Rw: 40, C: -1, Ctr: -3 }, // PYB 25, ED 27.0
            { glassIdsFRG: [132], Rw: 40, C: -1, Ctr: -3 }, // PYB 25 CV, ED 27.0
            { glassIdsFRG: [133], Rw: 43, C: -1, Ctr: -4 }, // PYB 25 EG, ED 30.0
            { glassIdsFRG: [134, 1340], Rw: 43, C: -1, Ctr: -4 }, // PYB 25 EG CV, ED 30.0
            // { glassIdsFRG: [], Rw: 45, C: -1, Ctr: -4 }, // PYB 25 EG st, ED 30.0
            // { glassIdsFRG: [], Rw: 46, C: -1, Ctr: -4 }, // PYB 25 EG2 st, ED 34.0
        ],
        '2-glazed': [
            { glassIdsFRG: [103], spacer1: 8, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 41, C: -1, Ctr: -4 }, // FSF 30-15, ED 30, Out: VSG 6-2
            { glassIdsFRG: [103], spacer1: 14, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 44, C: -2, Ctr: -5 }, // FSF 30-15, ED 36, Out: VSG 6-2
            { glassIdsFRG: [103], spacer1: 14, glassIdsMid: [], spacer2: 0, glassIdsOut: [253], Rw: 47, C: -1, Ctr: -5 }, // FSF 30-15, ED 38, Out: VSG 8-1P (Schallschutzfolie)
            { glassIdsFRG: [103], spacer1: 14, glassIdsMid: [], spacer2: 0, glassIdsOut: [228, 235, 242, 249], Rw: 46, C: -1, Ctr: -6 }, // FSF 30-15, ED 42, Out: VSG 12-2
            { glassIdsFRG: [103], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 43, C: -2, Ctr: -5 }, // FSF 30-15, ED 37, Out: VSG 6-2, Spacer1 rounded up from 15
            { glassIdsFRG: [103], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [], Rw: 46, C: -1, Ctr: -5 }, // FSF 30-15, ED 41, Out: VSG P4A-0-10
            { glassIdsFRG: [107, 108], spacer1: 14, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 44, C: -2, Ctr: -6 }, // FSF 30-19, ED 40, Out: VSG 6-2
            { glassIdsFRG: [107, 108], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [], Rw: 47, C: 0, Ctr: -3 }, // FSF 30-19 VSG 6-2 SC, ED 44, Out: VSG 8-2 SC
            { glassIdsFRG: [123], spacer1: 12, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 42, C: -1, Ctr: -5 }, // FSF 60-23, ED 42, Out: VSG 6-2
            { glassIdsFRG: [123], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [225, 232, 239, 246], Rw: 45, C: -2, Ctr: -7 }, // FSF 60-23, ED 46, Out: VSG 6-2
            { glassIdsFRG: [123], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [226, 233, 240, 274], Rw: 46, C: -1, Ctr: -4 }, // FSF 60-23, ED 48, Out: VSG 8-2
            { glassIdsFRG: [123], spacer1: 16, glassIdsMid: [], spacer2: 0, glassIdsOut: [228, 235, 242, 249], Rw: 47, C: -2, Ctr: -7 }, // FSF 60-23, ED 52, Out: VSG 12-2
        ],
        '3-glazed': [
            { glassIdsFRG: [103], spacer1: 10, glassIdsMid: [203, 209, 215, 221], spacer2: 10, glassIdsOut: [225, 232, 239, 246], Rw: 46, C: -2, Ctr: -6 }, // FSF 30-15, ED 48, Mid: ESG 6, Out: VSG 6-2
            { glassIdsFRG: [103], spacer1: 10, glassIdsMid: [203, 209, 215, 221], spacer2: 10, glassIdsOut: [226, 233, 240, 247], Rw: 47, C: -2, Ctr: -5 }, // FSF 30-15, ED 50, Mid: ESG 6, Out: VSG 8-2
            { glassIdsFRG: [103], spacer1: 14, glassIdsMid: [203, 209, 215, 221], spacer2: 14, glassIdsOut: [225, 232, 239, 246], Rw: 47, C: -1, Ctr: -6 }, // FSF 30-15, ED 56, Mid: ESG 6, Out: VSG 6-2
            { glassIdsFRG: [103], spacer1: 14, glassIdsMid: [203, 209, 215, 221], spacer2: 14, glassIdsOut: [226, 233, 240, 274], Rw: 48, C: -1, Ctr: -5 }, // FSF 30-15, ED 58, Mid: ESG 6, Out: VSG 8-2
            { glassIdsFRG: [123], spacer1: 8, glassIdsMid: [203, 209, 215, 221], spacer2: 8, glassIdsOut: [225, 232, 239, 246], Rw: 43, C: -2, Ctr: -7 }, // FSF 60-23, ED 52, Mid: ESG 6, Out: VSG 6-2
            { glassIdsFRG: [123], spacer1: 8, glassIdsMid: [203, 209, 215, 221], spacer2: 8, glassIdsOut: [226, 233, 240, 274], Rw: 44, C: -1, Ctr: -4 }, // FSF 60-23, ED 54, Mid: ESG 6, Out: VSG 8-2
        ],
    }

    static getAllUniqueValues() {
        let unique_vals = new Set();
        SoundDampening.soundDampeningTable['1-glazed'].map(val => unique_vals.add(`${val.Rw} (${val.C}; ${val.Ctr})`));
        SoundDampening.soundDampeningTable['2-glazed'].map(val => unique_vals.add(`${val.Rw} (${val.C}; ${val.Ctr})`));
        SoundDampening.soundDampeningTable['3-glazed'].map(val => unique_vals.add(`${val.Rw} (${val.C}; ${val.Ctr})`));
        return [...unique_vals].sort(/*(a, b) => a - b*/);
    }

    static getSoundValue(glass_structure) {
        let struct = glass_structure.getGlassStructure();
        let dampening = SoundDampening.soundDampeningTable[struct].filter(val => {
            if (!val.glassIdsFRG.includes(parseInt(glass_structure.fireResistantGlass.id, 10))) {
                return false;
            }
            if (struct === '1-glazed') {
                return true;
            }
            if (!(glass_structure.spacerOutside.thickness == val.spacer1 && val.glassIdsOut.includes(parseInt(glass_structure.outerGlass.id, 10)))) {
                return false;
            }
            if (struct === '2-glazed') {
                return true;
            }
            return (glass_structure.spacerMiddle.thickness == val.spacer2 && val.glassIdsMid.includes(parseInt(glass_structure.middleGlass.id, 10)));
        });
        if (dampening.length === 0) {
            return 'NPD';
        }
        return `${dampening[0].Rw} (${dampening[0].C}; ${dampening[0].Ctr})`;
    }
}