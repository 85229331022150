import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { CUSTOM_EVENTS } from 'utils/constant';
import { emit, on } from 'utils';
import usePopup from './usePopup';

const DeleteAttachmentPopup = ({
	isOpen,
}) => {
	const { closePopup, popupFrame } = usePopup({ isOpen, extraClassName: "popup_in--delete_position_state" });
	const { t } = useTranslation('deleteAttachmentPopup');
	const [attachmentId, setAttachmentId] = useState(null);
	const $deleteButton = useRef(null);

	const listenerCustomEvents = () => {
		on(CUSTOM_EVENTS.DELETE_ATTACHMENT.name, (e, detail) => {
			setAttachmentId(detail.attachmentId);
		});
	};

	const handleDeletePosition = () => {
		closePopup();
		emit(CUSTOM_EVENTS.DELETE_ATTACHMENT.name, { attachmentId, action: 'delete' });
	};

	useEffect(() => {
		listenerCustomEvents();
	}, []);

	useEffect(() => {
		if (isOpen) {
			$deleteButton.current.focus();
		}
	}, [isOpen]);

	return popupFrame(
		<div className="delete_position">
			<button className="popup_close" type="button" onClick={closePopup}>
				<CloseIcon className="icon icon-close size_mod" />
			</button>
			<BlockHeading
				title={t('title')}
				iconName="question"
				offsetMod
			/>
			<div className="delete_position__text">{t('description')}</div>
			<div className="delete_position__button">
				<button
					className="btn_base"
					type="button"
					onClick={handleDeletePosition}
					ref={$deleteButton}
				>
					{t('deleteButton')}
				</button>
			</div>
		</div>,
	);
};

export default DeleteAttachmentPopup;
