import classNames from "classnames";
import React from "react";
import { SanitaizedText } from "components/SanitaizedText";

const FormCheckbox = React.forwardRef(({
	id,
	title = "Checkbox",
	required = false,
	defaultChecked = "",
	noLabel,
	disabled = false,
	onChange,
	onKeyDown,
	isUppercase,
}, ref) => {
	const formCheckboxClasses = classNames("form_checkbox", {
		"form_checkbox--no_label_state": noLabel,
		"form_checkbox--uppercase_mod": isUppercase,
	});

	return (
		<div className={formCheckboxClasses}>
			<label className="form_checkbox__label" htmlFor={id}>
				<input
					id={id}
					ref={ref}
					className="form_checkbox__radiocheck"
					type="checkbox"
					defaultChecked={defaultChecked}
					required={required}
					disabled={disabled}
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
				<span className="form_checkbox__text">
					{!noLabel ? <SanitaizedText>{title}</SanitaizedText> : null}
				</span>
			</label>
		</div>
	);
});

export default FormCheckbox;
