export const TABLET_MEDIA_POINT = 1024;

export const STRING_NAME = 'string';
export const OBJECT_NAME = 'object';
export const ARRAY_NAME = 'array';
export const REFERENCE_NAME = 'Reference';
export const BOOLEAN_NAME = 'Boolean';
export const NUMBER_NAME = 'Number';
export const MEDIA_NAME = 'media';
export const TEMPLATE_NAME = 'template';

export const PROMISE_PENDING_STATE = 'pending';
export const PROMISE_FULFILLED_STATE = 'fulfilled';
export const PROMISE_REJECTED_STATE = 'rejected';
export const PROMISE_DEFAULT_STATE = 'idle';

export const PROMISE_STATES = {
	pending: 'pending',
	fulfilled: 'fulfilled',
	rejected: 'rejected',
	default: 'idle',
};

export const FIREBASE_CONFIGURATIONS_STORAGE_PATH = 'configurations';

export const CONFIGURATION_TYPE = {
	drafts: 'drafts',
	order: 'order',
	request: 'request',
};

export const CONFIGURATION_STATUS = {
	open: 'open',
	closed: 'closed',
	accepted: 'accepted',
};

export const USER_ROLES = {
	admin: 'admin',
	companyAdmin: 'companyAdmin',
	user: 'user',
};

export const SUPPORTED_FILE_ENDINGS = ['.odt', '.ods', '.docx', '.xlsx', '.dxf', '.dwg'];
export const SUPPORTED_MIME_TYPES = [
	// When updating here, don't forget to update the firebase storage rules too
	// or you'll get a permission-denied error
	'image/*',
	'application/pdf',
	'application/dxf', // typically image/vnd.dxf
	'application/dwg', // typically image/vnd.dwg
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
	'application/vnd.oasis.opendocument.text', // ODT
	'application/vnd.oasis.opendocument.spreadsheet', // ODS
];

export const CONFIGURATIONS_COLLECTION_NAME = 'configurations';

export const STATUS_RESPONSE = {
	ok: 200,
	methodNotAllowed: 402,
};

export const CUSTOM_EVENTS = {
	DELETE_POSITION: {
		name: 'delete-position',
		id: '',
		action: '',
	},
	DELETE_ATTACHMENT: {
		name: 'delete-attachment',
		id: '',
		action: '',
	},
};

export const VIDEO_LOADING_STATUS = {
	pending: 'pending',
	loaded: 'loaded',
};

export const MENU_CLOSED_STATE = 'closed';
export const MENU_OPEN_STATE = 'opened';

export const { REACT_APP_CRISP_CHAT_WEBSITE_ID } = process.env;
