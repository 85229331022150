import React, {
	useState,
	useEffect,
	useContext,
} from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import 'styles/blocks/configurator_page.scss';
import 'styles/plugins/_slick.scss';

import { AdditionalPageNavigation } from 'components/AdditionalPageNavigation';
import {
	GlassProductSidebar,
	GlassProductCanvas,
	GlassProductSelector,
	GlassProductSliderControl,
} from 'components/GlassProduct';
import { TABLET_MEDIA_POINT } from 'utils/constant';

import { Api } from 'components/ThreeJs/assets/index.module';
import { Filters } from 'components/ThreeJs/assets/filter/main';
import { GlassStructureContext } from 'context/configuration';
import { AuthContext } from 'context/auth';
import { UiContext } from 'context/ui';
import { useLocation } from 'react-router';
import { getParameterByName } from 'utils';
import { useTranslation } from 'react-i18next';
import DisclaimerBox from 'components/GlassProduct/DisclaimerBox';
import WarningUIElement from 'components/ThreeJs/assets/filter/elementsUI/WarningUIElement';
import { USERS_API } from 'api';

const HomePage = ({ key }) => {
	const location = useLocation();

	const sectionClassNames = classNames('section full_mod section--home_page_mod', {
		'section--home_page_hidden_state': location.pathname !== '/',
	});

	const [bottomFilter, setBottomFilter] = useState({});
	const [rightFilter, setRightFilter] = useState({});
	const {
		currentUser, currentUserAdditionalData, setCurrentUserAdditionalData,
	} = useContext(AuthContext);
	const uiContext = useContext(UiContext);
	// eslint-disable-next-line object-curly-newline
	const { showPopupByKey, windowWidth, setHideMessageStatus, viewApiRef } = uiContext;
	const glassStructureContext = useContext(GlassStructureContext);
	const { setFlatGlassStructure, uiHandlerRef } = glassStructureContext;

	const splitObjects = filterObject => {
		let bottom = {
			manufacturer: filterObject.manufacturer,
			fireResistanceClass: filterObject.fireResistanceClass,
			structure: filterObject.structure,
			insulation: filterObject.insulation,
			application: filterObject.application,
			whiteGlass: filterObject.whiteGlass,
			resistanceClass: filterObject.resistanceClass,
			buttJoints: filterObject.buttJoints,
			sound: filterObject.sound,
		};

		let right = {
			fireResistant: filterObject.fireResistant,
			spacer1: filterObject.spacer1,
			gas1: filterObject.gas1,
			middleGlass: filterObject.middleGlass,
			outsideGlass: filterObject.outsideGlass,
		};

		return { bottom, right };
	};

	window.loaded = false;

	const bottomKeys = Object.keys(bottomFilter);
	const rightKeys = Object.keys(rightFilter);

	const sliderSettings = {
		dots: false,
		infinite: false,
		variableWidth: true,
		arrows: true,
		draggable: false,
		swipe: false,
		nextArrow: <GlassProductSliderControl type="next" />,
		prevArrow: <GlassProductSliderControl type="prev" />,
	};

	const handleUrlParameter = () => {
		// Get the action to complete.
		const mode = getParameterByName('mode');

		if (mode === 'resetPassword') {
			showPopupByKey('resetPassword');
		}
	};

	function useCustomTranslation(namespace) {
		const { t, i18n } = useTranslation(namespace);
		return { t, i18n };
	}

	const { t: tVisualizerComponent, i18n } = useCustomTranslation('visualizerComponent');
	const { t: tGlassCharacteristics } = useCustomTranslation('glassCharacteristics');

	const checkIsConfiguratorLoaded = () => {
		if (i18n.isInitialized && viewApiRef.current.areAllModelsLoaded()) {
			const langLowerCase = i18n.language.toLowerCase();
			const lang = (langLowerCase === 'en' ? 'en_US' : langLowerCase);
			uiHandlerRef.current.i18n.setLanguage(lang);
			uiContext.setIsModelLoaded(true);
		} else {
			setTimeout(checkIsConfiguratorLoaded, 200);
		}
	};

	useEffect(() => {
		if (currentUserAdditionalData) {
			setHideMessageStatus(currentUserAdditionalData.hideStructureChangeMessage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserAdditionalData?.hideStructureChangeMessage]);

	useEffect(() => {
		const langLC = i18n.language.toLowerCase();
		if (currentUserAdditionalData && currentUserAdditionalData.language !== langLC) {
			currentUserAdditionalData.language = langLC;
			USERS_API.updateUserData(currentUser.uid, { language: langLC });
			setCurrentUserAdditionalData(currentUserAdditionalData);
		}
		if (!uiHandlerRef.current) {
			return;
		}
		const lang = (langLC === 'en' ? 'en_US' : langLC);

		uiHandlerRef.current.i18n.setLanguage(lang);

		if (window.filters_handler) {
			window.filters_handler.ui.i18n.setLanguage(lang);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	useEffect(() => {
		const lang = currentUserAdditionalData?.language;
		if (lang && lang !== i18n.language) {
			i18n.changeLanguage(lang);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserAdditionalData?.language]);

	useEffect(() => {
		setTimeout(() => {
			if (!window.loaded) {
				viewApiRef.current = Api;
				viewApiRef.current.load({
					initial_cam_pos: {
						zoom: 8, orientation: 120, tilt: 10, position: { x: 1.0, y: 1.0, z: -1.0 },
					},
					dpr: window.devicePixelRatio,
					invert_mouse: true,
					lang: {
						MeasurementArrows_label_format: tVisualizerComponent('measurementArrowsLabelFormat'),
					},
				});

				window.loaded = true;

				window.filters_handler = new Filters({ translation: tGlassCharacteristics, i18n }, setFlatGlassStructure);
				uiHandlerRef.current = window.filters_handler.ui;
				uiHandlerRef.current.setUiContext(uiContext);
				setTimeout(checkIsConfiguratorLoaded, 500);
			}
		}, 1000);
		handleUrlParameter();

		const { bottom, right } = splitObjects({});
		setBottomFilter(bottom);
		setRightFilter(right);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		uiHandlerRef.current?.setUiContext(uiContext);
	}, [uiContext, uiHandlerRef]);

	return (
		<section id={key} className={sectionClassNames}>
			<div className="section__in">
				<div className="glass_product">
					<div className="glass_product__nav">
						<AdditionalPageNavigation />
					</div>
					<div className="glass_product__row">
						<GlassProductCanvas />
						<GlassProductSidebar rightKeys={rightKeys} />
					</div>
				</div>
			</div>
			<div className="glass_product_bottom">
				<div className="glass_product_category">
					{windowWidth >= TABLET_MEDIA_POINT ? (
						<div>
							<div className="instruction instruction_bottom">
								1.&nbsp;
								{tGlassCharacteristics('commonConfigurationAction:firstSelectProperties')}
							</div>
							<div className="glass_product_category_list glass_product_category_list--style_mod">
								{bottomKeys.map(f => (
									<GlassProductSelector key={f} name={f} uiHandlerRef={uiHandlerRef} wrapperClass="glass_product_category_item_v2" labelClass="glass_product_category_label_v2" />
								))}
							</div>
						</div>
					) : (
						<Slider className="glass_product_category_list" {...sliderSettings}>
							<div className="instruction instruction_bottom">
								{tGlassCharacteristics(
									"commonConfigurationAction:firstSelectProperties",
								)}
							</div>
							{bottomKeys.map((f) => (<GlassProductSelector key={f} name={f} uiHandlerRef={uiHandlerRef} />))}
						</Slider>
					)}
				</div>
				<WarningUIElement uiHandlerRef={uiHandlerRef} />
				<DisclaimerBox />
			</div>
		</section>
	);
};

export default HomePage;
