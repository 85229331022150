import { COMPANIES_API } from 'api';
import { UiContext } from 'context/ui';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jsonAppData from 'utils/jsonAppData';
import ToastifyHandler from 'utils/ToastifyHandler';

const ClientBlockDropdown = ({
	clientCompanyId,
	notifyCompanyDeleted,
	foldUp = false,
}) => {
	const { clientsListDropdown } = jsonAppData;
	const { t } = useTranslation(["commonConfigurationAction", "firebaseErrorMessages"]);
	const { showPopupByKey } = useContext(UiContext);
	const notificationsHandler = useRef(new ToastifyHandler());
	const navigate = useNavigate();

	const deleteClientCompany = async () => {
		notificationsHandler.current.pending(t('notifications:clientCompanyDeletionInProgress'));

		try {
			await COMPANIES_API.deleteCompany(clientCompanyId);

			notificationsHandler.current.success(t('notifications:clientCompanyWasDeletedSuccessfully'));

			if (notifyCompanyDeleted) {
				notifyCompanyDeleted(clientCompanyId);
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const handleClick = (actionType) => {
		if (actionType === 'open' && clientCompanyId) {
			navigate(`/client-dashboard/${clientCompanyId}`);

			return;
		}

		if (actionType === 'delete') {
			if (clientCompanyId) {
				showPopupByKey('genericWarning', {
					title: t('notifications:confirmationOfClientCompanyDeletion'),
					confirmDialog: isConfirmed => isConfirmed && deleteClientCompany(),
				});
			}
		}
	};

	const dropdownFoldUpStyle = {
		top: 'auto',
		bottom: '100%',
	};

	return (
		clientsListDropdown ? (
			<div style={foldUp ? dropdownFoldUpStyle : null} className="client_block_dropdown">
				<ul className="client_block_dropdown__list">
					{clientsListDropdown.map(({
						label,
					}) => {
						return (
							<li className="client_block_dropdown__item" key={label}>
								<button
									className="client_block_dropdown__button"
									type="button"
									onClick={
										(e) => {
											e.stopPropagation();
											handleClick(label);
										}
									}
								>
									{t(label)}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		) : null

	);
};

export default ClientBlockDropdown;
