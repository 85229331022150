import React, {
	useState, useContext, useEffect, useRef, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'i/icons/close_thin.svg';
import { UiContext } from 'context/ui';
import { CUSTOM_EVENTS } from 'utils/constant';
import {
	emit, on, generateUniqueId, formatNumberToFixed,
} from 'utils';
import { DEFAULT_GLASS_SIZES } from 'domain/glass';
import GlassConfigTableRowCell from './GlassConfigTableRowCell';
import GlassConfigTableRowNotesCell from './GlassConfigTableRowNotesCell';

const GlassConfigTableRow = ({
	value,
	parent,
	rowIndex,
	rowId,
	positionIndex,
	decreasePositionIndex,
	lastPositionIndex,
	positionAction,
	totalValues,
	handleInputBlur,
	showPrice,
	setActiveRowIndex,
}) => {
	const { t } = useTranslation('configurationTableHeading');
	const rowIdRef = useRef(rowId);
	const rowIndexRef = useRef(rowIndex);
	const rowParentRef = useRef(parent);
	const cellRefs = useRef([]);
	const { showPopupByKey } = useContext(UiContext);
	const [total, setTotal] = useState({ price: '-', m2Pc: '-', ...DEFAULT_GLASS_SIZES });

	cellRefs.current = Array(value.row.length).fill().map(() => React.createRef());

	const showDeletePositionPopup = () => {
		showPopupByKey('deletePosition');

		emit(CUSTOM_EVENTS.DELETE_POSITION.name, { id: rowIdRef.current });
	};

	const listenerCustomEvents = () => {
		on(CUSTOM_EVENTS.DELETE_POSITION.name, (e, {
			action,
			id,
		}) => {
			if (id === rowIdRef.current && action === 'delete') {
				rowParentRef.current.splice(rowIndexRef.current, 1);
				decreasePositionIndex();
			}
		});
	};

	useEffect(() => {
		listenerCustomEvents();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		rowIndexRef.current = rowIndex;
	}, [rowIndex]);

	useEffect(() => {
		rowParentRef.current = parent;
	}, [parent]);

	useEffect(() => {
		if (totalValues) {
			setTotal(totalValues);
		} else {
			setTotal({ price: '-', m2Pc: '-', ...DEFAULT_GLASS_SIZES });
		}
	}, [totalValues]);

	const handleRemoveFocus = () => {
		value.set('focus', false);
	};

	const handleRemoveInactive = () => {
		value.set('inactive', false);
	};

	const notesIndex = useMemo(() => {
		return value.row.findIndex((e) => e.name === 'notes');
	}, [value.row]);

	const filteredRow = useMemo(() => {
		return value.row.filter((e) => e.name !== 'notes');
	}, [value.row]);
	const formElementIdRef = useRef(generateUniqueId(22));

	return (
		<>
			<li className={classNames("glass_config_table__row glass_config_table__row1", {
				"glass_config_table__row--inactive_mod": value.inactive,
			})}
			>
				{positionIndex ? (
					<div className="glass_config_table__cell glass_config_table__position_idx">
						{positionIndex}
					</div>
				) : null}
				{filteredRow?.length ? (
					<>
						{filteredRow.map((cellItem, index) => (
							<GlassConfigTableRowCell
								key={index}
								ref={cellRefs.current[index]}
								cellRefs={cellRefs}
								cellIndex={index}
								cellData={cellItem}
								rowIndex={rowIndex}
								lastPositionIndex={lastPositionIndex}
								positionAction={positionAction}
								formElementIdRef={formElementIdRef}
								isInactive={value.inactive}
								isFocus={value.focus}
								handleInputBlur={handleInputBlur}
								handleRemoveFocus={handleRemoveFocus}
								handleRemoveInactive={handleRemoveInactive}
								rowId={rowId}
								setActiveRowIndex={setActiveRowIndex}
							/>
						))}
						<div className="glass_config_table__cell glass_config_table__cell--size_state">{formatNumberToFixed(total.m2Pc)}</div>
						<div className="glass_config_table__cell glass_config_table__cell--size_state">{formatNumberToFixed(total.m2)}</div>
						<div className="glass_config_table__cell glass_config_table__cell--size_state">{formatNumberToFixed(total.kg)}</div>
						{
							showPrice
								? (
									(
										<div className="glass_config_table__cell glass_config_table__cell--size_state">
											{
												total.price === '-'
													? '-'
													: formatNumberToFixed(total.price ? total.price : '-')
											}
										</div>
									)
								)
								: null
						}
					</>
				) : null}
				<button
					className="glass_config_table__btn_remove_row"
					type="button"
					onClick={showDeletePositionPopup}
				>
					<CloseIcon className="icon icon-close_thin size_mod" />
				</button>
			</li>
			<li className={classNames("glass_config_table__row glass_config_table__row2", {
				"glass_config_table__row--inactive_mod": value.inactive,
			})}
			>
				<div className="glass_config_table__cell_title glass_config_table__notes_hdr glass_config_table__cell_title--offset_mod">
					{t('notes')}
				</div>
				<GlassConfigTableRowNotesCell
					ref={cellRefs.current[notesIndex]}
					key={notesIndex}
					cellIndex={notesIndex}
					cellData={value.row[notesIndex]}
					cellRefs={cellRefs}
					rowIndex={rowIndex}
					lastPositionIndex={lastPositionIndex}
					positionAction={positionAction}
					formElementIdRef={formElementIdRef}
					handleInputBlur={handleInputBlur}
					setActiveRowIndex={setActiveRowIndex}
				/>
			</li>
		</>
	);
};

export default GlassConfigTableRow;
