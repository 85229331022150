import React, { useContext, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CONFIGURATIONS_API } from 'api';
import { BlockHeading } from 'components/BlockHeading';
import { resetFilters } from 'components/ThreeJs/assets/main';

import { clearAllBrowserUrlParameter } from 'utils';
import { ConfigurationDataContext } from 'context/configuration';
import { AuthContext } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import ToastifyHandler from 'utils/ToastifyHandler';
import { PROMISE_STATES } from 'utils/constant';
import usePopup from './usePopup';

const ResetConfigurationPopup = ({
	isOpen,
}) => {
	const { closePopup, popupFrame } = usePopup({
		isOpen, onPopupExit: clearAllBrowserUrlParameter, extraClassName: "reset_configuration_popup",
	});
	const { t } = useTranslation(['resetConfigurationForm', 'firebaseErrorMessages']);
	const { configuration, setConfiguration } = useContext(ConfigurationDataContext);
	const { guestUser, setGuestUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const notificationsHandler = useRef(new ToastifyHandler());
	const [deletionStatus, setDeletionStatus] = useState(PROMISE_STATES.default);

	const resetConfiguration = () => {
		setConfiguration(null);
		resetFilters();

		if (guestUser) {
			setGuestUser(null);
		}

		navigate('/');
		closePopup();
	};

	const deleteConfiguration = async () => {
		const { code } = configuration;

		notificationsHandler.current.pending(t('notifications:configurationDeletionInProgress'));
		setDeletionStatus(PROMISE_STATES.pending);

		try {
			await CONFIGURATIONS_API.deleteConfiguration(code);

			notificationsHandler.current.success(t('notifications:configurationWasDeletedSuccessfully'));
			setDeletionStatus(PROMISE_STATES.fulfilled);

			resetConfiguration();
		} catch (error) {
			notificationsHandler.current.rejected(t(error.code, { ns: 'firebaseErrorMessages' }));
			setDeletionStatus(PROMISE_STATES.rejected);
		}
	};

	return popupFrame(
		<div className="reset_configuration">
			<BlockHeading title={t('title')} iconName="pensil" />
			{configuration ? (
				<div className="reset_configuration_content">
					<div className="reset_configuration_text">{t('text')}</div>
					<div className="reset_configuration_code">
						<div className="reset_configuration_code_title">{t('configurationCode')}</div>
						<div className="reset_configuration_code_value">{configuration.code}</div>
					</div>
					<div className="reset_configuration_btn	">
						<button
							type="button"
							className="btn_base"
							onClick={resetConfiguration}
						>
							{t('resetButton')}
						</button>
					</div>
					<div className="reset_configuration_or">{t('or')}</div>
					<div className="reset_configuration_text_new">{t('textNew')}</div>
					<div className="reset_configuration_btn	">
						<button
							type="button"
							className="btn_base"
							onClick={deleteConfiguration}
							disabled={deletionStatus === PROMISE_STATES.pending}
						>
							{t('resetDeleteButton')}
						</button>
					</div>
				</div>
			) : ( // configuration does not exist, just show a button to reset the configurator
				<>
					<div className="popup_note">{t('configurationDoesNotExist')}</div>
					<div className="reset_configuration_btn	">
						<button
							type="button"
							className="btn_base"
							onClick={resetConfiguration}
						>
							{t('resetButton')}
						</button>
					</div>
				</>
			)}
		</div>,
	);
};

export default ResetConfigurationPopup;
