import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import 'styles/_loader.scss';
import { UiContext } from 'context/ui';

const GlassProductCanvas = () => {
	const { viewApiRef } = useContext(UiContext);
	const location = useLocation();
	const [isEnabled, setIsEnabled] = useState(location.pathname === '/');

	useEffect(() => {
		const isRoot = location.pathname === '/';
		setIsEnabled(isRoot);
		if (!isRoot) {
			// stop rendering immediately rather than when state update took place
			viewApiRef.current?.stop();
		}
	}, [location]);

	useEffect(() => {
		if (!viewApiRef.current) {
			return;
		}
		if (isEnabled) {
			viewApiRef.current.start();
		}
	}, [isEnabled, viewApiRef.current]);

	useEffect(() => {
		return () => {
			viewApiRef.current?.stop();
		};
	}, []);

	return (
		<div className={classNames("glass_product_col img_mod", {
			"glass_product_col--enabled_mod": isEnabled,
		})}
		>
			<div className="loader">
				<div className="loader__progress-bar">
					<div className="loader__progress-bar-fill" />
				</div>
			</div>
			<canvas className="glass_product_img home__canvas webgl-canvas" />
		</div>
	);
};

export default GlassProductCanvas;
