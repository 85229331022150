import GasSpacerUIElement from './GasSpacerUIElement.js'
import Insulation from '../Glass/InsulationValues.js';

export default class SpacerUIElement extends GasSpacerUIElement {
  constructor(props) {
    super(props);

    this.i18n_option_key_format = 'spacer_format';
    this.options = [8, 10, 12, 14, 16];
  }

  handleSelectChange = (spacer) => {
    let val = spacer.value;
    if (this.uiHandlerRef.current.onSpacerChanged(this.props.id, val)) {
      this.recreate(val);
    }
  }

  setSpacerOptions(options) {
    this.options.splice(0, this.options.length, ...options);
    this.recreate();
  }

  refreshAvailableOptions() {
  }
}