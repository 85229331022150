import { View, Text } from '@react-pdf/renderer';
import React from 'react';

import { useTranslation } from 'react-i18next';
import jsonAppData from 'utils/jsonAppData';

import styles from '../styles';

const PdfGlassConfigTableHeadingRow = ({ showPrice }) => {
	const { t } = useTranslation('configurationTableHeading');
	const glassConfigTableHeadingRow = jsonAppData.cartPage.glassConfigTableHeadingRow.filter((e) => showPrice || e.label !== 'price');

	const getGlassConfigTableCellStyle = isLastCell => {
		if (isLastCell) {
			return {
				...styles.glassConfigTableCell,
				...styles.glassConfigTableCellHeadingState,
				...styles.glassConfigTableCellLastChild,
			};
		}

		return {
			...styles.glassConfigTableCell,
			...styles.glassConfigTableCellHeadingState,
		};
	};

	return (
		<View style={{ ...styles.glassConfigTableRow, ...styles.glassConfigTableRowHeadingState }}>
			{glassConfigTableHeadingRow && glassConfigTableHeadingRow.length ? (
				glassConfigTableHeadingRow.map(({
					label,
				}, index) => {
					const isLastCell = index === glassConfigTableHeadingRow.length - 1;

					if (label === 'notes') {
						return null;
					}

					return (
						<React.Fragment key={index}>
							{index === 0 ? (
								<View style={{ ...styles.glassConfigTableCell, ...styles.glassConfigTableCellFirstChild }} />
							) : null}
							<View style={getGlassConfigTableCellStyle(isLastCell)}>
								{label === 'pc' || label === 'pos' ? (
									<View style={styles.glassConfigTableCellSupWrap}>
										<Text>m</Text>
										<Text style={styles.glassConfigTableCellSup}>2</Text>
										<Text>/</Text>
									</View>
								) : null}

								<Text>{t(label)}</Text>

								{label === 'length' || label === 'width' ? (
									<Text style={styles.glassConfigTableCellHeadingStateSize}> (mm)</Text>
								) : null}

								{label === 'kg' ? (
									<Text>
										/
										{t('pc')}
									</Text>
								) : null}
							</View>
						</React.Fragment>
					);
				})
			) : null}
		</View>
	);
};

export default PdfGlassConfigTableHeadingRow;
