import React from 'react';
import { FormField } from 'components/Form';

const ClientBlockInfo = ({
	id,
	block,
	clientId,
	checkboxChecked,
	title,
	text,
	link,
	linkTarget,
	linkTitle,
	ref,
	type,
	onChange,
	onBlur,
	handleSort,
}) => {
	const renderInfoText = () => {
		if (type === 'link') {
			return (
				<a href={link} title={linkTitle} target={linkTarget} className="client_block_info__text">{text}</a>
			);
		} else if (type === 'checkbox') {
			return (
				<input
					id={id}
					data-client-id={clientId}
					ref={ref}
					className="client_block_info__radiocheck"
					type="checkbox"
					defaultChecked={!!checkboxChecked}
					onChange={onChange}
				// onKeyDown={onKeyDown}
				/>
			);
		} else if (type === 'formfield') {
			return (
				<div className="client_block_info client_block_info__title">
					<FormField
						id={id}
						type="text"
						placeholder="-"
						defaultValue={text}
						handleBlur={onBlur}
					/>
				</div>
			);
		}

		return (<div className="client_block_info__text">{text}</div>);
	};

	return (
		<div className="client_block_info">
			<button type="button" className="client_block_info__title" data-sort-by={block} onClick={handleSort}>{title}</button>
			{renderInfoText()}
		</div>
	);
};

export default ClientBlockInfo;
