import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { DashboardBlock, DashboardCompanyInfo } from 'components/Dashboard';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API } from 'api';
import { AuthContext } from 'context/auth';
import ToastifyHandler from 'utils/ToastifyHandler';
import {
	CONFIGURATIONS_COLLECTION_NAME,
	CONFIGURATION_TYPE,
	USER_ROLES,
} from 'utils/constant';

import {
	collection,
	onSnapshot,
	query,
	where,
} from 'firebase/firestore';
import { firebaseDB } from 'utils/firebase';
import { useParams } from 'react-router-dom';
import { AddNewType } from 'components/AddNewType';
import { AccountPage } from 'pages/AccountPage';

const CustomerDashboardPage = () => {
	const { t } = useTranslation(['commonAppValues', 'firebaseErrorMessages']);
	const { currentUserAdditionalData, currentUserRole } = useContext(AuthContext);
	const notificationsHandler = useRef(new ToastifyHandler());

	const [companyData, setCompanyData] = useState(null);
	const [companyConfigurations, setCompanyConfigurations] = useState(null);

	const [draftsConfigurations, setDraftsConfigurations] = useState(null);
	const [requestsConfigurations, setRequestsConfigurations] = useState(null);
	const [ordersConfigurations, setOrdersConfigurations] = useState(null);

	const [numberOfConfigurationsByType, setNumberOfConfigurations] = useState(null);
	const { companyId } = useParams();

	const subscribeToListenConfigurationsChanges = useRef(null);

	const listenCompanyConfigurationsChanges = () => {
		const citiesRef = collection(firebaseDB, CONFIGURATIONS_COLLECTION_NAME);

		let companyConfigurationsQuery;

		if (currentUserRole === USER_ROLES.admin && companyId) {
			companyConfigurationsQuery = query(citiesRef, where('companyId', '==', companyId));
		} else {
			companyConfigurationsQuery = query(citiesRef, where('companyId', '==', currentUserAdditionalData.companyId));
		}

		subscribeToListenConfigurationsChanges.current = onSnapshot(companyConfigurationsQuery, (querySnapshot) => {
			const allConfigurations = [];

			querySnapshot.forEach((doc) => {
				allConfigurations.push(doc.data());
			});

			setCompanyConfigurations(allConfigurations);
		}, (error) => {
			notificationsHandler.current.rejected(error.message);
		});
	};

	const getCompanyData = async () => {
		if (currentUserRole !== USER_ROLES.admin || !companyId) {
			setCompanyData(currentUserAdditionalData.company);
			return;
		}

		try {
			notificationsHandler.current.pending(t('notifications:loadingCompanyData'));
			const companyRes = await COMPANIES_API.getCompany(companyId);

			if (companyRes) {
				setCompanyData(companyRes);
				notificationsHandler.current.success(t('notifications:dataLoadedSuccessfully'));
			} else {
				notificationsHandler.current.rejected(t('notifications:companyNotFound'));
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	useEffect(() => {
		const splitConfigurationsByType = () => {
			const companyDraftsConfigurations = [];
			const companyRequestsConfigurations = [];
			const companyOrdersConfigurations = [];

			companyConfigurations.forEach(companyConfiguration => {
				switch (companyConfiguration.type) {
					case CONFIGURATION_TYPE.request:
						companyRequestsConfigurations.push(companyConfiguration);

						break;

					case CONFIGURATION_TYPE.order:
						companyOrdersConfigurations.push(companyConfiguration);
						break;

					default:
						companyDraftsConfigurations.push(companyConfiguration);
						break;
				}
			});

			setRequestsConfigurations(companyRequestsConfigurations);
			setOrdersConfigurations(companyOrdersConfigurations);
			setDraftsConfigurations(companyDraftsConfigurations);

			setNumberOfConfigurations({
				requests: companyRequestsConfigurations.length,
				orders: companyOrdersConfigurations.length,
				drafts: companyDraftsConfigurations.length,
			});
		};

		if (companyConfigurations) {
			splitConfigurationsByType();
		}
	}, [companyConfigurations]);

	useEffect(() => {
		if (currentUserAdditionalData) {
			getCompanyData();
			listenCompanyConfigurationsChanges();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserAdditionalData]);

	useEffect(() => {
		return () => {
			if (subscribeToListenConfigurationsChanges.current) {
				subscribeToListenConfigurationsChanges.current();
			}
		};
	}, []);

	if (!companyData) return null;
	return (
		<>
			<AccountPage adminCompanyData={companyData} />
			<section className="section dashboard">
				<div className="section__in">
					<ul className="dashboard__list">
						<li className="dashboard__item">
							<DashboardCompanyInfo
								companyName={companyData.name}
								numberOfConfigurationsByType={numberOfConfigurationsByType}
							/>
						</li>
						<li className="dashboard__item">
							{(!draftsConfigurations) && (!requestsConfigurations) && (!ordersConfigurations) ? (
								<AddNewType isDashboardPage />
							) : null}
						</li>
						{draftsConfigurations && draftsConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('drafts')}
									configurationData={draftsConfigurations}
								/>
							</li>
						) : null}
						{requestsConfigurations && requestsConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('requests')}
									configurationData={requestsConfigurations}
								/>
							</li>
						) : null}
						{ordersConfigurations && ordersConfigurations.length ? (
							<li className="dashboard__item">
								<DashboardBlock
									titleLabel={t('orders')}
									configurationData={ordersConfigurations}
								/>
							</li>
						) : null}
					</ul>
				</div>
			</section>
		</>
	);
};

export default CustomerDashboardPage;
