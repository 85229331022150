import React, { useState } from 'react';

const InfoBox = ({ hoverMessage }) => {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<span
			className="glass_product_info_box"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<span className="glass_product_info_box_icon">ⓘ</span>
			{isHovering && (
				<div className="glass_product_info_box_message">
					{hoverMessage.split('\n').map((line, index) => (
						<React.Fragment key={index}>
							{line}
							<br />
						</React.Fragment>
					))}
				</div>
			)}
		</span>
	);
};

export default InfoBox;
