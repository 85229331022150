import React from 'react';

const ConfigurationBlockCell = ({
	title,
	text,
	link,
	onClickHandler,
	sortType,
	type = "text",
	ellipsized = false,
}) => {
	let preparedText = text || '#';
	if (type === 'configurationCode') {
		preparedText = (<span className="cdr_configuration_code">{preparedText}</span>);
	} else if (type === 'link') {
		preparedText = (<a href={link} title={ellipsized ? preparedText : null}>{preparedText}</a>);
	}

	return (
		<div className="configuration_block__cell">
			{title && (
				<button
					type="button"
					className="configuration_block__title"
					onClick={onClickHandler ? () => onClickHandler(sortType) : null}
				>
					{title}
				</button>
			)}
			{preparedText && (
				<div
					className="configuration_block__text"
					title={ellipsized ? preparedText : null}
				>
					{preparedText}
				</div>
			)}
		</div>
	);
};

export default ConfigurationBlockCell;
