import { Component } from 'react';
import Select from 'react-select';
import { selectBoxStyles } from 'utils/react-select-styles';

class UIGenericGlass extends Component {
  constructor(props) {
    super(props);
    this.i18n_section = 'glassCharacteristics';
    this.defaultOption = null;
    if (props.defaultVisible) {
      this.defaultOption = { value: -1, label: "-" };
    }

    if (this.props.id === 'fire-resistant') {
      this.glassLabel = 'frg';
    } else if (this.props.id === 'middle-glass') {
      this.glassLabel = 'mid';
    } else if (this.props.id === 'outside-glass') {
      this.glassLabel = 'out';
    }

    this.uiHandlerRef = props.uiHandlerRef;
    this.componentInitialized = false;

    this.state = {
      isDisabled: props.isDisabled || false,
      language: null,
      options: [...(this.defaultOption ? [this.defaultOption] : [])],
      selectedOption: null
    };
  }

  get isDisabled() {
    return this.state.isDisabled;
  }

  componentDidMount() {
    this.shouldComponentUpdate(null, null);
  }

  shouldComponentUpdate(_nextProps, nextState) {
    if (this.uiHandlerRef.current === null) {
      return false;
    }
    if (!this.componentInitialized) {
      this.componentInitialized = true;
      const uiHandler = this.uiHandlerRef.current;
      const initSequence = () => {
        uiHandler.setGlassUiElement(this, this.glassLabel);
        uiHandler.i18n.onLanguageChangeSubscribers.push(this);
        this.setState({ options: this.generateOptions(), language: uiHandler.i18n.language });
      };
      if (uiHandler.isDbLoaded) {
        initSequence();
      } else {
        uiHandler.onDbLoadedSubscribers.push(initSequence);
      }
      return false;
    }
    if (nextState.language !== this.uiHandlerRef.current.i18n.language) {
      return false;
    }
    return !this.populateOptions();
  }

  clear() {
    this.setState({
      options: [...(this.defaultOption ? [this.defaultOption] : [])],
      selectedOption: null
    });
  }

  selectGlass(glass) {
    if (glass != this.state.selectedOption) {
      this.setState({
        selectedOption: glass,
      });
    }
  }

  generateOptions() {
    const uiHandler = this.uiHandlerRef.current;
    let options = [
      ...(this.defaultOption ? [this.defaultOption] : []),
      ...uiHandler.dbView[this.glassLabel].map(glass => ({
        value: glass.dbIndex,
        label: this.translateGlass(glass)
      }))
    ];

    let filteredOptions = uiHandler.dbView[this.glassLabel + "Filtered"].map(glass => ({
      value: glass.dbIndex,
      label: this.translateGlass(glass)
    }));

    if (filteredOptions.length) {
      options = [...options, /* filtered optgroup */ { label: this.t('filteredCategoryLabel'), options: filteredOptions }];
    }
    return options;
  }

  populateOptions() {
    const options = this.generateOptions();

    const arrEq = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, index) => value.value === arr2[index].value)
    if (!arrEq(this.state.options, options)) {
      this.setState({ options });
      return true;
    }
    return false;
  }

  setDisabled(value) {
    if (value != this.state.isDisabled) {
      this.setState({ isDisabled: value });
    }
  }

  translateGlass(aGlass) {
    // Implementation of glass translation
    const uiHandler = this.uiHandlerRef.current;
    return uiHandler.dbView.db.translateGlass(aGlass, uiHandler.i18n.language);
  }

  t(str, i18n_section = null) {
    let section = i18n_section || this.i18n_section;
    return this.uiHandlerRef.current.i18n.t(str, section);
  }

  handleSelectChange = (selectedOption) => {
    this.uiHandlerRef.current.onGlassChanged(selectedOption.value, this.glassLabel);
  }

  onLanguageChange(language) {
    if (language != this.state.language) {
      // this.populateOptions(); // takes it's language from the uiHandler which is already set (not from the state)
      this.setState({ options: this.uiHandlerRef.current?.dbView && this.generateOptions(), language });
    }
  }

  render() {
    const { isDisabled, options, selectedOption } = this.state;
    const sizeRestriction = (this.state.selectedOption && this.state.selectedOption.max_height);
    const selectComponent = (
      <Select
        isDisabled={isDisabled}
        id={this.props.id}
        value={selectedOption !== null ? options.find((o) => o.value === selectedOption.dbIndex) : options[0]}
        onChange={this.handleSelectChange}
        options={options}
        styles={selectBoxStyles}
        onMenuOpen={() => document.documentElement.classList.add('select_open_mod')}
        onMenuClose={() => document.documentElement.classList.remove('select_open_mod')}
        isSearchable={false}
        classNamePrefix='r-select'
        inputId={this.props.id.concat('_input')}
        openMenuOnFocus
      />
    );

    return sizeRestriction ? (
      <div className='glass_product_selection_warning warning' data-warning-message={
        this.t('glassMaxSizeWarningFormat')
          .replace("{max_width_mm}", selectedOption.max_width)
          .replace("{max_height_mm}", selectedOption.max_height)
      }>
        {selectComponent}
      </div>
    ) : selectComponent;
  }
}

export default UIGenericGlass;
