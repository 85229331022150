import React, { useContext, useRef, useState } from "react";

import jsonAppData from "utils/jsonAppData";
import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import {
	emailValidation, nameValidation, fieldValidation, VATNumberValidation,
} from "utils";
import { AuthContext } from "context/auth";
import FormField from "./FormField";

const GuestForm = () => {
	const { t } = useTranslation(["baseFormPlaceholders"]);
	const { setGuestUser } = useContext(AuthContext);
	const { guestForm } = jsonAppData;
	const formikRef = useRef();
	const navigate = useNavigate();

	const [isOpenForm, setIsOpenForm] = useState(false);

	const validate = {
		guestUserCompanyName: (value) => fieldValidation(t("userCompanyPlaceholder"), value),
		guestUserFirstName: (firstName) => nameValidation(t("userFirstNamePlaceholder"), firstName),
		guestUserLastName: (lastName) => nameValidation(t("userLastNamePlaceholder"), lastName),
		guestUserEmail: (email) => emailValidation(email),
		guestUserCompanyVATNumber: (VATNumber) => VATNumberValidation(t("userCompanyVATNumber"), VATNumber, true),
		guestUserCompanyStreet: () => {
			return null;
		},
		guestUserCompanyZIP: () => {
			return null;
		},
		guestUserCompanyCity: () => {
			return null;
		},
		guestUserCompanyPhone: () => {
			return null;
		},
		guestGender: (value) => fieldValidation(t("userGenderPlaceholder"), value),
		guestFunction: () => {
			return null;
		},
	};

	const initialValues = {
		guestUserCompanyName: "",
		guestUserCompanyVATNumber: "",
		guestUserFirstName: "",
		guestUserLastName: "",
		guestUserEmail: "",
		guestUserCompanyStreet: "",
		guestUserCompanyZIP: "",
		guestUserCompanyCity: "",
		guestUserCompanyPhone: "",
		guestGender: "",
		guestFunction: "",
	};

	const toggleForm = (e) => {
		e.preventDefault();
		setIsOpenForm(true);
	};

	const createGuestUser = (values) => {
		if (!values) return null;
		return {
			companyName: values.guestUserCompanyName,
			companyVATNumber: values.guestUserCompanyVATNumber,
			userGender: values.guestGender,
			userFirstName: values.guestUserFirstName,
			userLastName: values.guestUserLastName,
			email: values.guestUserEmail,
			function: values.guestFunction,
			companyStreet: values.guestUserCompanyStreet,
			companyZIP: values.guestUserCompanyZIP,
			companyCity: values.guestUserCompanyCity,
			companyPhone: values.guestUserCompanyPhone,
		};
	};

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={(values) => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			// eslint-disable-next-line no-unused-vars
			onSubmit={async (values /* , { resetForm, setSubmitting } */) => {
				const guestUser = createGuestUser(values);

				if (guestUser) {
					setGuestUser(guestUser);
					navigate("/summary");
				}
			}}
			validateOnChange={false}
		>
			{({
				// isSubmitting,
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldTouched,
				setFieldValue,
			}) => (
				<form className="guest_form" onSubmit={handleSubmit}>
					<BlockHeading title={t("guestForm:title")} />
					<div className="guest_form__in">
						<ul className={`form_fields ${!isOpenForm && "limit_mod"}`}>
							{guestForm.filter(o => isOpenForm || o.isRequired).map(
								({
									keyForI18n, id, type, isRequired, options,
								}) => {
									const i8nLabel = t(`${keyForI18n}`);
									let i8nOptions;

									if (options) {
										i8nOptions = options.map((option) => ({
											...option,
											label: t(`${option.label}`),
										}));
									}

									const placeholder = isRequired ? `${i8nLabel} *` : i8nLabel;

									return (
										<li className="form_fields__item" key={id}>
											<FormField
												id={id}
												placeholder={placeholder}
												name={id}
												type={type}
												required={isRequired}
												// iconName={iconName}
												values={values}
												errors={errors}
												handleBlur={handleBlur}
												handleChange={handleChange}
												touched={touched}
												options={i8nOptions}
												setFieldTouched={setFieldTouched}
												setFieldValue={setFieldValue}
											/>
											<div className="form_fields__error">
												{touched[id] && errors[id]}
											</div>
										</li>
									);
								},
							)}
						</ul>
						{!isOpenForm && (
							<a
								className="guest_form__more_button"
								href="#"
								onClick={toggleForm}
							>
								{t("guestForm:additionalInfo")}
							</a>
						)}
						<div className="guest_form__button">
							<button className="btn_base" type="submit">
								{t("guestForm:continue")}
							</button>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default GuestForm;
