import React from 'react';

import { LostPasswordForm } from 'components/Form';
import usePopup from './usePopup';

const LostPasswordPopup = ({
	isOpen,
}) => {
	const { popupFrame } = usePopup({ isOpen });
	return popupFrame(<LostPasswordForm formInPopup />);
};

export default LostPasswordPopup;
