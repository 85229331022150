import React from 'react';

import { RegistrationForm } from 'components/Form';
import usePopup from './usePopup';

const RegistrationPopup = ({
	isOpen,
}) => {
	const { popupFrame } = usePopup({ isOpen });
	return popupFrame(<RegistrationForm formInPopup />);
};

export default RegistrationPopup;
