import React from 'react';

import { LoginForm } from 'components/Form';
import usePopup from './usePopup';

const LoginPopup = ({
	isOpen,
}) => {
	const { popupFrame } = usePopup({ isOpen });
	return (popupFrame(<LoginForm formInPopup />));
};

export default LoginPopup;
