import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DINNextLTProRegular from 'fonts/DINNextLTPro-Regular.ttf';
import DINNextLTProBold from 'fonts/DINNextLTPro-Bold.ttf';

import {
	View,
	Document,
	Page,
	StyleSheet,
	Font,
	Text,
} from '@react-pdf/renderer';
import { PdfGlassConfig } from 'components/PdfComponents/PdfGlassConfig';
import { getCurrentDate } from 'utils';
import { PdfConfigurationCode } from '../PdfConfigurationCode';
import { PdfYourReference } from '../PdfYourReference';
import { PdfHeader } from '../PdfHeader';
import { PdfUploadedDocuments } from '../PdfUploadedDocuments';
import { PdfTotalInfo } from '../PdfTotalInfo';

const styles = StyleSheet.create({
	PDFContainer: {
		paddingHorizontal: 35,
		paddingVertical: 23,
		backgroundColor: '#F7F7F7',
	},
	section: {
		position: 'relative',
		width: '100%',
		minHeight: '100%',
		fontSize: 8,
		fontFamily: 'DINNextLTPro',
	},
	sectionBlock: {
		marginBottom: 30,
	},
	sectionBlockFooter: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		textAlign: 'center',
		minHeight: 50,
		width: '100%',
		paddingVertical: 18,
		paddingHorizontal: 23,
	},
	sectionBlockFooterText: {
		fontSize: 8,
		textTransform: 'uppercase',
		textAlign: 'center',
		color: '#456d86',
	},
});

Font.register({
	family: 'DINNextLTPro',
	fonts: [
		{ src: DINNextLTProRegular },
		{ src: DINNextLTProBold, fontWeight: 700 },
	],
});

const PdfConfigurationsDocument = ({
	totalValuesSum,
	totalValues,
	configuration,
	customerInfo,
	showPrice,
}) => {
	const { t } = useTranslation('pdfPage');
	const pdfCreationDate = useRef(getCurrentDate());

	return (
		<Document style={styles.PDFContainer}>
			{configuration && configuration.glassTypes.map((glassData, index) => {
				return (
					<Page style={styles.PDFContainer} size="A3" key={index}>
						<View style={styles.section}>
							<View style={styles.sectionBlock}>
								<PdfHeader documentType={t(configuration.type)} customerInfo={customerInfo} />
							</View>
							{index === 0 ? (
								<>
									<View style={styles.sectionBlock}>
										<PdfConfigurationCode code={configuration.code} />
									</View>
									<View style={styles.sectionBlock}>
										<PdfYourReference
											reference={configuration.reference}
											buildingReference={configuration.buildingReference}
										/>
									</View>
								</>
							) : null}
							<View style={styles.sectionBlock} key={glassData.id || index} wrap>
								<PdfGlassConfig
									glassData={glassData}
									glassIndex={index + 1}
									totalValues={totalValues?.[index]}
									showPrice={showPrice}
								/>
							</View>
							{index === configuration.glassTypes.length - 1 ? (
								<View style={styles.sectionBlock} wrap={false} key={`t${index}`}>
									<PdfTotalInfo {...totalValuesSum} showPrice={showPrice} />
								</View>
							) : null}
							{index === configuration.glassTypes.length - 1 && configuration.uploadedDocuments.length ? (
								<PdfUploadedDocuments documentsList={configuration.uploadedDocuments} />
							) : null}
							<View style={styles.sectionBlockFooter} fixed>
								<Text
									style={styles.sectionBlockFooterText}
									render={({ pageNumber, totalPages }) => (
										`${pdfCreationDate.current} - ${t('pageFooterFmt').replace('{pageNum}', pageNumber).replace('{numPages}', totalPages)}`
									)}
									fixed
								/>
							</View>
						</View>
					</Page>
				);
			})}
		</Document>
	);
};

export default PdfConfigurationsDocument;
