import React from 'react';

const FormFieldWithLabel = ({
	id,
	placeholder = 'placeholder',
	labelTitle = 'Label title',
	name = 'name',
	type = 'text',
	required = false,
	defaultValue,
	onChange,
	onBlur,
	disabled,
	extraClassName,
}) => {
	return (
		<div className="form_field form_field--label_state">
			<label className="form_field_label" htmlFor={id}>{labelTitle}</label>
			<input
				className={`form_field__input ${extraClassName}`}
				id={id}
				name={name}
				type={type}
				defaultValue={defaultValue}
				placeholder={placeholder}
				required={required}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
			/>
		</div>
	);
};

export default FormFieldWithLabel;
