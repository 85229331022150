import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATION_DE } from './locales/de/translation';
import { TRANSLATION_FR } from './locales/fr/translation';
import { TRANSLATION_IT } from './locales/it/translation';
import { TRANSLATION_EN } from './locales/en/translation';

export const availableLanguages = {
	de: { flag: '🇩🇪', nativeText: TRANSLATION_DE.i18n.languageName },
	en: { flag: '🇬🇧', nativeText: TRANSLATION_EN.i18n.languageName },
	fr: { flag: '🇫🇷', nativeText: TRANSLATION_FR.i18n.languageName },
	it: { flag: '🇮🇹', nativeText: TRANSLATION_IT.i18n.languageName },
};

// const detectedLng = i18n.services.languageDetector.detect();
// const availableLanguages = ['de', 'fr', 'it', 'en_US']; // List your languages here
// const fallbackLng = 'en_US';

// let resources = {};

// for (let lng of availableLanguages) {
//     try {
//         resources[lng] = await fetchTranslation(lng);
//     } catch (error) {
//         console.error(`Failed to load ${lng} translation`, error);
//         if (lng === detectedLng) {
//             // If the detected language fails to load, load the fallback language
//             resources[fallbackLng] = await fetchTranslation(fallbackLng);
//         }
//     }
// }
// // BEGIN remove this code
const resources = {
	de: TRANSLATION_DE,
	fr: TRANSLATION_FR,
	it: TRANSLATION_IT,
	en_US: TRANSLATION_EN,
};
// // END remove this code

i18n
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		keySeparator: false,
		fallbackLng: 'en_US',
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
