import React, { useEffect, useState } from 'react';
import { VIDEO_LOADING_STATUS } from 'utils/constant';
import VideoBlockItem from './VideoBlockItem';

const VideoBlockContainer = ({
	srcMov,
	srcWebm,
	slideIndex,
	videoIndex,
	slideActiveIndex,
	videoLoadingStatus,
	setVideoLoadingStatus,
	renderedVideos,
	currentActiveVideoIndex,
	setCurrentActiveVideoIndex,
}) => {
	const [videoLoadedState, setVideoLoadedState] = useState(() => {
		return [false, false];
	});

	const [isVideoFullyLoaded, setVideoFullyLoaded] = useState(false);
	const [isVideosPlaying, setVideosPlaying] = useState(false);

	useEffect(() => {
		if (
			videoLoadingStatus
			&& videoLoadedState.every(Boolean)
			&& !isVideoFullyLoaded
		) {
			const clonedVideLoadingStatus = [...videoLoadingStatus];

			if (clonedVideLoadingStatus[videoIndex]) {
				clonedVideLoadingStatus[videoIndex].status = VIDEO_LOADING_STATUS.loaded;

				setVideoLoadingStatus(clonedVideLoadingStatus);

				setVideoFullyLoaded(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoLoadedState]);

	// FOR FIRST VIDEO
	useEffect(() => {
		// when two videos loaded, and current slide active, play
		if (
			videoLoadedState.every(Boolean)
			&& videoIndex === 0
		) {
			setVideosPlaying(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoLoadedState]);

	// play video if fully loaded
	useEffect(() => {
		if (currentActiveVideoIndex === videoIndex && isVideoFullyLoaded) {
			setVideosPlaying(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentActiveVideoIndex, isVideoFullyLoaded]);

	return (
		<div className="video_block__container">
			<div className="video_block__col video_block__col--left_lvl_state">
				<VideoBlockItem
					srcWebm={srcWebm}
					srcMov={srcMov}
					slideIndex={slideIndex}
					videoIndex={videoIndex}
					videoIndexPlaying={0}
					slideActiveIndex={slideActiveIndex}
					setVideoLoadedState={setVideoLoadedState}
					videoLoadedState={videoLoadedState}
					renderedVideos={renderedVideos}
					isVideosPlaying={isVideosPlaying}
					setVideosPlaying={setVideosPlaying}
					setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
				/>
			</div>
			<div className="video_block__col video_block__col--right_lvl_state">
				<VideoBlockItem
					srcWebm={srcWebm}
					srcMov={srcMov}
					videoIndexPlaying={1}
					slideIndex={slideIndex}
					videoIndex={videoIndex}
					slideActiveIndex={slideActiveIndex}
					setVideoLoadedState={setVideoLoadedState}
					videoLoadedState={videoLoadedState}
					renderedVideos={renderedVideos}
					isVideosPlaying={isVideosPlaying}
					setVideosPlaying={setVideosPlaying}
					setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
				/>
			</div>
		</div>
	);
};

export default VideoBlockContainer;
