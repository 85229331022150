import React from 'react';

import { ResetPasswordForm } from 'components/Form';
import { clearAllBrowserUrlParameter } from 'utils';
import usePopup from './usePopup';

const ResetPasswordPopup = ({
	isOpen,
}) => {
	const { popupFrame } = usePopup({ isOpen, onPopupExit: clearAllBrowserUrlParameter });

	return popupFrame(<ResetPasswordForm />);
};

export default ResetPasswordPopup;
