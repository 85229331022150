import React, {
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';

import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API } from 'api';
import { AuthContext } from 'context/auth';
import { Spinner } from 'components/Spinner';
import { PROMISE_STATES, USER_ROLES } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import { VATNumberValidation, fieldValidation } from 'utils';
import { Formik } from 'formik';
import i18n, { availableLanguages } from 'utils/i18n';
import FormField from '../FormField';
import FormFieldWithLabel from '../FormFieldWithLabel';

const AccountCompanyForm = ({ adminCompanyData }) => {
	const { t } = useTranslation(['baseFormPlaceholders', 'firebaseErrorMessages']);
	// eslint-disable-next-line object-curly-newline
	const { currentUserRole, currentUserAdditionalData, setCurrentUserAdditionalData } = useContext(AuthContext);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const [initialFormValues, setInitialFormValues] = useState(null);
	// const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
	const notificationsHandler = useRef(new ToastifyHandler());
	const adminMode = !!(currentUserRole === USER_ROLES.admin && adminCompanyData);
	const companyData = (adminMode && adminCompanyData) || currentUserAdditionalData?.company;

	const formikRef = useRef();

	const validate = {
		companyName: value => fieldValidation(t('userCompanyPlaceholder'), value),
		companyVATNumber: VATNumber => VATNumberValidation(t('companyVATNumberPlaceholder'), VATNumber),
		companyAddress: value => fieldValidation(t('userStreetPlaceholder'), value),
		companyCity: value => fieldValidation(t('userCityPlaceholder'), value),
		companyTel: value => fieldValidation(t('userPhonePlaceholder'), value),
		companyZIP: value => fieldValidation(t('userZIPPlaceholder'), value),
		companyLanguage: value => fieldValidation(t('userCompanyLanguage'), value),
		customerReference: () => fieldValidation(t('contactCompanyInformation:customerReference')),
	};

	const createInitialFormValues = ({
		name,
		VATNumber,
		address,
		zip,
		city,
		tel,
		language,
		customerReference,
	}) => {
		const initialValues = {
			companyName: name,
			companyVATNumber: VATNumber,
			companyAddress: address,
			companyZIP: zip,
			companyCity: city,
			companyTel: tel,
			companyLanguage: language || i18n.language,
		};
		if (currentUserRole === USER_ROLES.admin) {
			initialValues.customerReference = customerReference;
		}

		setInitialFormValues(initialValues);
	};

	useMemo(() => {
		if ((adminMode && initialFormValues) || !currentUserAdditionalData) return;
		createInitialFormValues((adminMode && companyData) || currentUserAdditionalData.company);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserAdditionalData]);

	const updateCompanyData = ({
		companyName,
		companyVATNumber,
		companyAddress,
		companyCity,
		companyTel,
		companyZIP,
		companyLanguage,
		customerReference,
	}) => {
		const data = {
			name: companyName,
			VATNumber: companyVATNumber,
			address: companyAddress,
			city: companyCity,
			tel: companyTel,
			zip: companyZIP,
			language: companyLanguage,
		};
		if (currentUserRole === USER_ROLES.admin) {
			data.customerReference = customerReference;
		}

		return data;
	};

	return (
		<div className="account_form">
			<BlockHeading
				title={t('accountPage:companyFormTitle')}
			/>
			<div className="account_form__in">
				{companyData && initialFormValues ? (
					<>
						<Formik
							initialValues={initialFormValues}
							innerRef={formikRef}
							mapPropsToValues={() => {
								return {
									...initialFormValues,
								};
							}}
							validate={values => Object.keys(values).reduce((errors, field) => {
								const error = validate[field](values[field]);
								return {
									...errors,
									...(error && { [field]: error }),
								};
							}, {})}
							onSubmit={async (values) => {
								notificationsHandler.current.pending(t('notifications:pleaseWait'));
								const updatedCompanyData = updateCompanyData({ companyLanguage: i18n.language, ...values });

								try {
									setLoadingStatus(PROMISE_STATES.pending);

									await COMPANIES_API.updateCompanyData(companyData.id, updatedCompanyData);

									if (!adminMode) {
										currentUserAdditionalData.company = { ...companyData, ...updatedCompanyData };
										setCurrentUserAdditionalData(currentUserAdditionalData);
									}

									notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
									setLoadingStatus(PROMISE_STATES.fulfilled);
								} catch (error) {
									notificationsHandler.current.rejected(error.message);
									setLoadingStatus(PROMISE_STATES.rejected);
								}
							}}
							validateOnChange={false}
						>
							{({
								handleSubmit,
								handleBlur,
								handleChange,
								setFieldTouched,
								setFieldValue,
								values,
								errors,
								touched,
							}) => (
								<form onSubmit={handleSubmit}>
									<ul className="form_fields offset_2_mod">
										<li className="form_fields__item">
											<FormField
												id="companyName"
												placeholder={t('userCompanyPlaceholder')}
												name="companyName"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyName && errors.companyName}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyVATNumber"
												placeholder={t('userCompanyVATNumberPlaceholder')}
												name="companyVATNumber"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyVATNumber && errors.companyVATNumber}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyAddress"
												placeholder={t('userStreetPlaceholder')}
												name="companyAddress"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyAddress && errors.companyAddress}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyZIP"
												placeholder={t('userZIPPlaceholder')}
												name="companyZIP"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyZIP && errors.companyZIP}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyCity"
												placeholder={t('userCityPlaceholder')}
												name="companyCity"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyCity && errors.companyCity}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyTel"
												placeholder={t('userPhonePlaceholder')}
												name="companyTel"
												type="text"
												required
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
											/>
											<div className="form_fields__error">{touched.companyTel && errors.companyTel}</div>
										</li>
										<li className="form_fields__item">
											<FormField
												id="companyLanguage"
												name="companyLanguage"
												type="select"
												options={
													Object.keys(availableLanguages).map((l) => {
														return { value: l, label: `${availableLanguages[l].flag} ${availableLanguages[l].nativeText}` };
													})
												}
												values={{ companyLanguage: i18n.language, ...values }}
												setFieldTouched={setFieldTouched}
												setFieldValue={setFieldValue}
											/>
											<div className="form_fields__error">{touched.companyLanguage && errors.companyLanguage}</div>
										</li>
										{currentUserRole === USER_ROLES.admin ? (
											<li className="form_fields__item">
												<FormField
													labelTitle={t('contactCompanyInformation:customerReference')}
													placeholder={t('contactCompanyInformation:customerReference')}
													id="customerReference"
													name="customerReference"
													type="text"
													values={values}
													handleBlur={handleBlur}
													handleChange={handleChange}
												/>
												<div className="form_fields__error">{touched.customerReference && errors.customerReference}</div>
											</li>
										) : null}
									</ul>
									<button
										className="account_form__button"
										type="submit"
										disabled={loadingStatus === PROMISE_STATES.pending}
									>
										<div className="btn_base">{t('accountPage:saveButton')}</div>
									</button>
								</form>
							)}
						</Formik>
						{currentUserRole === USER_ROLES.admin
							? null
							: (
								<ul className="form_fields offset_2_mod" style={{ marginTop: "2em" }}>
									{companyData.customerReference ? (
										<li className="form_fields__item">
											<FormFieldWithLabel
												labelTitle={t('contactCompanyInformation:customerReference')}
												id="customerReference"
												name="customerReference"
												type="text"
												disabled
												defaultValue={companyData.customerReference}
											/>
										</li>
									) : null}
								</ul>
							)}
					</>
				) : (
					loadingStatus === PROMISE_STATES.pending ? (
						<Spinner darkTheme />
					) : (
						<div className="company_users__error">{/* loadingErrorMessage */}</div>
					)
				)}
			</div>
		</div>
	);
};

export default AccountCompanyForm;
