import React, { useEffect, useState } from 'react';
import { cloneObjectWithoutReference } from 'utils';
import { VIDEO_LOADING_STATUS } from 'utils/constant';
import VideoBlockContainer from './VideoBlockContainer';

const VideoBlock = React.forwardRef(({
	videos,
}, ref) => {
	const [allVideos, setAllVideos] = useState(null);
	const [filteredAllVideos, setAllFilteredVideos] = useState(null);
	const [renderedVideos, setRenderedVideos] = useState(null);
	const [videoLoadingStatus, setVideoLoadingStatus] = useState(null);
	const [currentActiveVideoIndex, setCurrentActiveVideoIndex] = useState(0);
	const [currentRenderedVideoIndex, setCurrentRenderedVideoIndex] = useState(0);

	const createVideosArray = () => {
		const clonedVideos = [...allVideos];
		const firstVideoIndex = Math.floor(Math.random() * clonedVideos.length);
		const firstVideoElement = clonedVideos.splice(firstVideoIndex, 1);

		setAllFilteredVideos(firstVideoElement.concat(clonedVideos));
		setRenderedVideos(firstVideoElement);

		const videoStatus = allVideos.map(() => {
			const data = {
				status: VIDEO_LOADING_STATUS.pending,
			};

			return data;
		});

		setVideoLoadingStatus(videoStatus);
	};

	const renderNewVideo = () => {
		if (
			videoLoadingStatus[currentRenderedVideoIndex].status === VIDEO_LOADING_STATUS.loaded
			&& (renderedVideos.length - 1) <= (filteredAllVideos.length - 1)
		) {
			if (currentRenderedVideoIndex + 1 <= filteredAllVideos.length - 1) {
				const nextRenderedVideoIndex = currentRenderedVideoIndex + 1;
				const newVideo = cloneObjectWithoutReference(filteredAllVideos[nextRenderedVideoIndex]);
				const updatedActiveVideos = [...renderedVideos];

				updatedActiveVideos.push(newVideo);

				setRenderedVideos(updatedActiveVideos);
				setCurrentRenderedVideoIndex(nextRenderedVideoIndex);
			}
		}
	};

	useEffect(() => {
		if (videos && videos.length > 1) {
			setAllVideos(videos);
		}
	}, [videos]);

	useEffect(() => {
		if (!videoLoadingStatus && allVideos) {
			createVideosArray();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allVideos]);

	useEffect(() => {
		if (videoLoadingStatus && filteredAllVideos) {
			renderNewVideo();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoLoadingStatus]);

	return (
		<div className="video_block" ref={ref}>
			{renderedVideos && renderedVideos.length ? (
				renderedVideos.map(({
					mov,
					webm,
				}, index) => {
					return (
						<VideoBlockContainer
							key={index}
							srcMov={mov}
							srcWebm={webm}
							videoIndex={index}
							videoLoadingStatus={videoLoadingStatus}
							renderedVideos={renderedVideos}
							setVideoLoadingStatus={setVideoLoadingStatus}
							currentActiveVideoIndex={currentActiveVideoIndex}
							setCurrentActiveVideoIndex={setCurrentActiveVideoIndex}
						/>
					);
				})
			) : null}
		</div>
	);
});

export default VideoBlock;
