import React from 'react';

const MainMenuClosingDecor = React.forwardRef(({
	// eslint-disable-next-line no-unused-vars
	_mod,
}, $ref) => {
	return (
		<div className="menu_bg_w_svg closing_mod">
			<svg preserveAspectRatio="xMinYMid slice" className="menu_bg_svg" id="a8f90aef-9adb-425e-afa0-ac5514b8380c" data-name="ac43bf30-760b-46db-954e-cf64809d0355" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
				<defs>
					<clipPath id="e9adffd7-3445-4993-ba99-3a3c48cf0725">
						<path ref={$ref} d="M1366.81-316.47C1372.16,72,1555,458.7,2138.88,334.09c23.12,90.71-73.68-227.84-192.37-227.84S1731.6,10,1731.62-108.72,1280.74-342.63,1366.81-316.47Z" style={{ fill: 'none', clipRule: 'evenodd' }} />
					</clipPath>
					<radialGradient id="ad0e8227-af62-4ec6-ae4c-45245dbf830e" cx="50%" cy="50%" r="808.45" gradientUnits="userSpaceOnUse">
						<stop offset={0} stopColor="rgb(235,235,235)" />
						<stop offset={1} stopColor="rgb(135,135,135)" />
					</radialGradient>
				</defs>
				<g style={{ clipPath: 'url(#e9adffd7-3445-4993-ba99-3a3c48cf0725)' }}>
					<rect width={1920} height={1080} style={{ fill: 'url(#ad0e8227-af62-4ec6-ae4c-45245dbf830e)' }} transform="scale(1 3) translate(0, -25)" />
				</g>
			</svg>

		</div>
	);
});

export default MainMenuClosingDecor;
