import { COMPANIES_API, USERS_API } from 'api';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useState, useEffect, useRef } from 'react';
import { USER_ROLES } from 'utils/constant';
import { firebaseAuth } from 'utils/firebase';

import AuthContext from './authContext';

const AuthState = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [currentUserAdditionalData, setCurrentUserAdditionalData] = useState(null);
	const [guestUser, setGuestUser] = useState(null);
	const [currentUserRole, setCurrentUserRole] = useState(null);
	const refreshInProgressRef = useRef(false);

	const getUserAdditionalData = async (uid, cid) => {
		try {
			const promises = [];
			promises.push(USERS_API.getUser(uid));
			if (cid) {
				promises.push(COMPANIES_API.getCompany(cid));
			}
			const results = await Promise.all(promises);
			const userRes = results[0];
			if (cid) {
				// eslint-disable-next-line prefer-destructuring
				userRes.company = results[1];
			}

			// console.log('res', res);

			if (userRes) {
				setCurrentUserAdditionalData(userRes);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error.message);
		}
	};

	const setRoles = (user, idTokenResult, repeat = 3) => {
		if (idTokenResult.claims.companyId === undefined) {
			if (repeat > 0) {
				setTimeout(() => user.getIdTokenResult(true).then(_idTokenResult => setRoles(user, _idTokenResult, repeat - 1)), 3000);
			} else {
				refreshInProgressRef.current = false;
			}
			// newly created user.. wait for login
			return;
		}
		refreshInProgressRef.current = false;
		setCurrentUser(user);
		getUserAdditionalData(user.uid, idTokenResult.claims.companyId);
		setGuestUser(null);

		if (idTokenResult.claims.admin) {
			setCurrentUserRole(USER_ROLES.admin);
			return;
		}

		if (idTokenResult.claims.companyAdmin) {
			setCurrentUserRole(USER_ROLES.companyAdmin);
		}
	};

	const refreshUserTokenAndRoles = async (user) => {
		refreshInProgressRef.current = true;
		user.getIdTokenResult(true).then(idTokenResult => setRoles(user, idTokenResult));
	};

	useEffect(() => {
		onAuthStateChanged(firebaseAuth, async (user) => {
			if (user) {
				if (!refreshInProgressRef.current) {
					refreshInProgressRef.current = true;
					user.getIdTokenResult().then(idTokenResult => setRoles(user, idTokenResult, 0));
				}
			} else {
				setCurrentUser(null);
				setCurrentUserAdditionalData(null);
				setGuestUser(null);
				setCurrentUserRole(USER_ROLES.user);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				currentUser,
				setCurrentUser,
				currentUserAdditionalData,
				setCurrentUserAdditionalData,
				guestUser,
				setGuestUser,
				refreshUserTokenAndRoles,
				currentUserRole,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthState;
