import React, { useContext } from 'react';
import {
	setItemToLocalStorage,
} from 'utils';
import { AppDataContext } from 'context/appData';
import { useTranslation } from 'react-i18next';

const MainMenuLangList = React.forwardRef(({
	// eslint-disable-next-line no-unused-vars
	_props,
}, ref) => {
	const { langApp, setLangApp, themeSettings } = useContext(AppDataContext);
	const { i18n } = useTranslation();

	const changeLanguageHandler = (lang) => {
		const langLowerCase = lang.toLowerCase();
		i18n.changeLanguage(langLowerCase);
		setItemToLocalStorage('user-language', langLowerCase);
		setLangApp(langLowerCase);
	};

	return (
		themeSettings && themeSettings.languages ? (
			<ul className="main_menu_lang_list" ref={ref}>
				{themeSettings.languages.map(({
					code,
				}, index) => {
					let upperCaseCodeValue = code.toUpperCase();
					const buttonClassName = langApp === code.toLowerCase() ? 'active_mod' : '';

					return (
						<li className={`main_menu_lang_item mod_${index + 1}`} key={index}>
							<button
								className={`main_menu_lang_link ${buttonClassName}`}
								onClick={() => changeLanguageHandler(code)}
								type="button"
							>
								{upperCaseCodeValue}
							</button>
						</li>
					);
				})}
			</ul>
		) : null
	);
});

export default MainMenuLangList;
