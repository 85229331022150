import React, { useEffect, useState } from 'react';
import { BlockHeading } from 'components/BlockHeading';
import { ConfigurationBlockList } from 'components/ConfigurationBlock';
import { FormSearchField, FormNumEntriesSelector } from 'components/Form';
import { PaginationBlock } from 'components/PaginationBlock';
import { useTranslation } from 'react-i18next';
import { chunkArray, cloneObjectWithoutReference, sortReferenceByAlphaNumeric } from 'utils';

const SORT_TYPES = {
	lastEdited: 'lastEdited',
	created: 'created',
	reference: 'reference',
	user: 'user',
};

const DashboardBlock = ({
	titleLabel,
	configurationData,
}) => {
	const { t } = useTranslation('baseFormPlaceholders');
	const [sortedConfigurationData, setSortedConfigurationData] = useState(null);
	const [chunkedConfigurationData, setChunkedConfigurationData] = useState(null);
	const [activeSortType, setActiveSortType] = useState(null);
	const [activePaginationPage, setActivePaginationPage] = useState(1);
	const [totalConfigurationItemsCount, setTotalConfigurationItemsCount] = useState(1);
	const [isSearchApplied, setSearchApplied] = useState(null);
	const [isPaginationShown, setPaginationShown] = useState(false);
	const [configurationCountPerPage, setConfigurationCountPerPage] = useState(5);
	const entriesPerPageOptions = [
		{ value: 3, label: '3' },
		{ value: 5, label: '5' },
		{ value: 10, label: '10' },
		{ value: 15, label: '15' },
	];

	const sortConfigurationsByDate = (dateSortType, dataToSort) => {
		if (!dateSortType) return;

		const preparedDataToSort = dataToSort || configurationData;

		const duplicatedConfigurationData = cloneObjectWithoutReference(preparedDataToSort);

		const ts = (o) => ((typeof o) === 'object' ? o.seconds * 1000 + o.nanoseconds / 1_000_000 : o);

		const sortData = (a, b) => {
			const dateA = dateSortType === SORT_TYPES.created ? ts(a.publishedAt) : a.lastEditedDateInMilliseconds;
			const dateB = dateSortType === SORT_TYPES.created ? ts(b.publishedAt) : b.lastEditedDateInMilliseconds;

			return dateB - dateA;
			// if (activeSortTypeDirection === DIRECTION_UP) return dateB - dateA;
			// if (activeSortTypeDirection === DIRECTION_BOTTOM) return dateA - dateB;
		};

		duplicatedConfigurationData.sort(sortData);

		setSortedConfigurationData(duplicatedConfigurationData);
		setActiveSortType(dateSortType);
	};

	const sortConfigurationByReference = () => {
		const sortedConfigurations = sortReferenceByAlphaNumeric(sortedConfigurationData || configurationData);

		if (sortedConfigurations) {
			setSortedConfigurationData(sortedConfigurations);
			setActiveSortType(SORT_TYPES.reference);
		}
	};

	const handleClickOnSortByDateButton = (sortType) => {
		if (!sortType) return;

		if (sortType !== activeSortType) {
			sortConfigurationsByDate(sortType, sortedConfigurationData);
		}
	};

	const handleClickOnSortByReferenceButton = () => {
		sortConfigurationByReference();
	};

	const handleClickOnSortByUserButton = () => {
		// TODO
	};

	const handleClickOnSort = (criteria) => {
		switch (criteria) {
			case SORT_TYPES.user: return handleClickOnSortByUserButton();
			case SORT_TYPES.reference: return handleClickOnSortByReferenceButton();
			case SORT_TYPES.created: return handleClickOnSortByDateButton(criteria);
			case SORT_TYPES.lastEdited: return handleClickOnSortByDateButton(criteria);
			default:
		}
		return null;
	};

	const setNewActivePaginationPage = (pageNumber) => {
		let page = pageNumber;
		if (page > chunkedConfigurationData.length) {
			page = chunkedConfigurationData.length;
		}
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[page - 1]);

		setActivePaginationPage(pageNumber);
		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
	};

	const updateCurrentActivePaginationPage = () => {
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[activePaginationPage - 1]);

		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
	};

	const handlePaginationPageChange = (pageNumber) => {
		setNewActivePaginationPage(pageNumber);
	};

	const handleOnChangeSearchInput = (e) => {
		const inputValueLowerCase = e.target.value.toLowerCase();

		if (inputValueLowerCase.trim() !== '') {
			const filteredArray = configurationData.filter(({
				reference,
				buildingReference,
				created,
				lastEdited,
			}) => {
				const referenceLowerCase = reference.toLowerCase();
				const buildingReferenceLowerCase = buildingReference.toLowerCase();
				const createdLowerCase = created.toLowerCase();
				const lastEditedLowerCase = lastEdited.toLowerCase();

				return (
					referenceLowerCase.includes(inputValueLowerCase)
					|| buildingReferenceLowerCase.includes(inputValueLowerCase)
					|| createdLowerCase.includes(inputValueLowerCase)
					|| lastEditedLowerCase.includes(inputValueLowerCase)
				);
			});

			setSortedConfigurationData(filteredArray);
			setSearchApplied(true);
		} else {
			if (isPaginationShown) {
				updateCurrentActivePaginationPage();
			} else {
				sortConfigurationsByDate(activeSortType, configurationData);
			}

			setSearchApplied(false);
		}
	};

	const splitConfigurationsByPage = () => {
		const chunkedData = chunkArray(configurationData, configurationCountPerPage);
		let page = activePaginationPage;
		if (page > chunkedData.length) {
			page = chunkedData.length;
			setActivePaginationPage(chunkedData.length);
		}
		const activePageData = cloneObjectWithoutReference(chunkedData[page - 1]);

		setTotalConfigurationItemsCount(configurationData.length);
		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
		setChunkedConfigurationData(chunkedData);
		setPaginationShown(chunkedData.length > 1);
	};

	useEffect(() => {
		if (configurationData) {
			if (configurationData.length > configurationCountPerPage) {
				splitConfigurationsByPage();
			} else {
				setPaginationShown(false);
				switch (activeSortType) {
					case SORT_TYPES.created:
						sortConfigurationsByDate(SORT_TYPES.created);
						break;
					case SORT_TYPES.reference:
						sortConfigurationByReference();
						break;
					default:
						sortConfigurationsByDate(SORT_TYPES.lastEdited);
						break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationData, configurationCountPerPage]);

	return (
		<div className="dashboard_block">
			<div className="dashboard_block__heading">
				<BlockHeading
					title={t(titleLabel)}
				/>
				<div className="dashboard_block__header_container">
					<div className="dashboard_block__field">
						<FormNumEntriesSelector
							id={`${titleLabel}-NumEntries`}
							defaultValue={entriesPerPageOptions.find((o) => o.value === configurationCountPerPage)}
							options={entriesPerPageOptions}
							onChange={(selectedOption) => {
								setConfigurationCountPerPage(selectedOption.value);
							}}
						/>
					</div>
					<div className="dashboard_block__field">
						<FormSearchField
							id={titleLabel}
							placeholder={t('searchField')}
							name={titleLabel}
							onChange={handleOnChangeSearchInput}
						/>
					</div>
				</div>
			</div>
			{sortedConfigurationData && sortedConfigurationData.length > 0 ? (
				<ConfigurationBlockList
					configurationData={sortedConfigurationData}
					handleClickOnSort={handleClickOnSort}
				/>
			) : null}
			{configurationData && isPaginationShown && !isSearchApplied ? (
				<PaginationBlock
					activePaginationPage={activePaginationPage}
					itemsCountPerPage={configurationCountPerPage}
					totalItemsCount={totalConfigurationItemsCount}
					handlePaginationPageChange={handlePaginationPageChange}
				/>
			) : null}

		</div>
	);
};

export default DashboardBlock;
