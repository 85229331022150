import Select from 'react-select';
import AbstractUIElement from "./AbstractUIElement";
import FilterUIElement from './FilterUIElement';

export default class GasSpacerUIElement extends AbstractUIElement {
  constructor(props) {
    super(props, { menuPlacement: 'bottom', i18nSection: 'filters', defaultVisible: true });
    this.i18n_option_key_format = '';

    this.filteredOptions = [];
    this.filteredOptionsUValue = [];
  }

  initComponent() {
    super.initComponent();
    this.recreate();
  }

  set(value) {
    this.recreate(value);
  }

  resetFilteredOptions() {
    this.filteredOptions = [];
    this.filteredOptionsUValue = [];
  }

  tVal(value) {
    if (!value) {
      // null for gas, 0 for spacer
      return '-';
    }
    return this.t(this.i18n_option_key_format.replace('{}', value)).replace('{}', value);
  }

  generateOptions() {
    let options = [];
    let filteredOptions = [];

    this.options.forEach(value => {
      var option = {
        value: value,
        label: this.tVal(value),
      };

      let filteredOptionIndex = this.filteredOptions.indexOf(value);
      if (filteredOptionIndex >= 0) {
        let v = this.filteredOptionsUValue[filteredOptionIndex];
        if (v !== -1) {
          option.label += ` (${this.t('insulation_UValueFormat').replace('{}', v.toFixed(1))})`;
        }
        filteredOptions = [...filteredOptions, option];
      } else {
        options = [...options, option];
      }
    });

    return [
      ...(this.defaultOption !== null ? [this.defaultOption] : []),
      ...options,
      /* filtered optgroup */
      ...(filteredOptions.length > 0 ? [{ label: this.t("changesUValue"), options: filteredOptions }] : []),
    ];
  }

  recreate(selectedValue = null) {
    super.recreate(selectedValue);
    let options = this.generateOptions();

    this.updateState({ options, selectedValue });
  }
}