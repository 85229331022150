import { api } from 'utils/api';

const getAllPages = async () => {
	const res = await api.get('/wp/v2/pages?per_page=50');
	return res.data;
};

const getMainMenuItems = async () => {
	const res = await api.get('/nav/main-menu');
	return res.data;
};

const getAllFaqData = async () => {
	const res = await api.get('/wp/v2/faqs/archive');
	return res.data;
};

const getAllFaqCategories = async () => {
	const res = await api.get('/wp/v2/faqs/categories');
	return res.data;
};

const getThemeSettings = async () => {
	const res = await api.get('/wp/v2/theme-settings');
	return res.data;
};

export {
	getAllPages,
	getThemeSettings,
	getMainMenuItems,
	getAllFaqData,
	getAllFaqCategories,
};
