import React from 'react';
import { useTranslation } from 'react-i18next';

const DisclaimerBox = () => {
	const { t } = useTranslation("homePage");

	return (
		<div className="disclaimer">
			{t('allStatementsWithoutGuarantee')}
			&nbsp;|&nbsp;
			<a href={t('disclaimerLink')}>{t('disclaimer')}</a>
			&nbsp;|&nbsp;
			<a href={t('termsAndConditionsLink')}>{t('termsAndConditions')}</a>
			&nbsp;|&nbsp;
			<a href="#" id="open_preferences_center">{t('cookieSettings')}</a>
		</div>
	);
};

export default DisclaimerBox;
