import { Graphics, ResourceBatch } from 'ohzi-core';
import { RenderLoop } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { EventManager } from 'ohzi-core';
import { Debug } from 'ohzi-core';
import { Initializer } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import Loader from './Loader';

// APP
import MainApplication from './MainApplication';

class AppApi {
  init() {
    this.application = new MainApplication();
    this.loader = new Loader(this);

    this.render_loop = new RenderLoop(this.loader, Graphics);

    let app_container = document.querySelector('.webgl-canvas');
    let canvas = document.querySelector('.webgl-canvas');

    Initializer.init(canvas, app_container, {
      antialias: true,
      logarithmicDepthBuffer: true,
    });
    Configuration.dpr = 1;//window.devicePixelRatio;
    this.application.init(Graphics);
    this.updateLabelsCallback = undefined;
    this.lastArgs = null;
  }

  load(settings = {}) {
    this.init();

    window.glass_app = this.application;
    window.glass_ViewApi = this;
    window.glass_settings = settings;

    this.loader.load();

    for (const [key, value] of Object.entries(settings)) {
      Configuration[key] = value;
    }
  }

  dispose() {
    this.application.dispose();
    Initializer.dispose(this.render_loop);
  }

  draw_debug_axis() {
    Debug.draw_axis();
  }

  register_event(name, callback) {
    EventManager.on(name, callback);
  }

  set_resource(name, resource) {
    ResourceContainer.set_resource(name, resource);
  }

  set_settings(settings) {
    window.glass_settings = settings;
  }

  start_main_app() {
    this.render_loop.set_state(this.application);
  }

  start() {
    this.render_loop.start();
  }

  stop() {
    this.render_loop.stop();
  }

  /**
   *
   * @param {*} callback
   * @param {*} awaitTransition If true, wait for current transition animation to finish before taking screenshot
   * @param {*} reset_cam Reset the camera position prior to taking a screenshot
   * @param {*} dimensions Object with width and height properties. Screen/Canvas dimensions if null.
   */
  take_screenshot({ callback, awaitTransition = true, reset_cam = true, width = null, height = null }) {
    const take_screenshot = () => {
      if (width && height) {
        Graphics.take_screenshot(callback, width, height);
      } else {
        Graphics.take_screenshot(callback);
      }
    };
    if (awaitTransition || this.lastArgs === null) {
      this.application.structure.setTransitionEndCallback(() => {
        (reset_cam || reset_cam === null) && this.reset_cam();
        take_screenshot();
      }, (this.lastArgs !== null));
    } else {
      reset_cam && this.reset_cam(); // only if explicitly asked for
      take_screenshot();
    }
  }

  download_blob(blob) {
    Graphics.download_screenshot(blob);
  }

  setInsideOutside(inside_outside) {
    this.application.setInsideOutside(inside_outside);
  }

  reset_cam() {
    this.application.scene_controller.reset_cam_pos();
  }

  setTranslation(language) {
    Configuration['lang'] = language;
    this.updateLabels();
  }

  updateLabels(frg_w, og_w, mg_w, so_w, sm_w, u_value, g_o, g_m, rotate_frg) {
    if (arguments.length == 0) {
      if (this.lastArgs) {
        this.updateLabels(...this.lastArgs);
      }
      return;
    }

    this.lastArgs = arguments;
    const timeout = () => {
      return setTimeout((() => {
        // what wants to be called
        this.application.updateLabels(frg_w, og_w, mg_w, so_w, sm_w, u_value, g_o, g_m, rotate_frg);
      }).bind(this), 100);
    };

    // we damp the call
    if (this.updateLabelsCallback === undefined) {
      this.updateLabelsCallback = timeout();
    }
    else {
      clearTimeout(this.updateLabelsCallback);
      this.updateLabelsCallback = timeout();
    }
  }

  areAllModelsLoaded() {
    // helper function to ensure all async models are loaded
    // call this and make sure it returns true before calling other functions such as setInsideOutside
    return !!(this.application.structure
      && this.application.scene_controller.sun
      && this.application.scene_controller.indoor_model[1]
      && this.application.scene_controller.outdoor_model[1]);
  }
}

const Api = new AppApi();
export { Api, ResourceBatch };
