import { BlockHeading } from 'components/BlockHeading';
import { FormNumEntriesSelector, FormSearchField } from 'components/Form';
import React, {
	useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API, USERS_API } from 'api';
import { ClientBlock } from 'components/ClientBlock';
import { chunkArray, cloneObjectWithoutReference } from 'utils';
import { PaginationBlock } from 'components/PaginationBlock';
import ToastifyHandler from 'utils/ToastifyHandler';
import emailjs from "emailjs-com";
import { Spinner } from 'components/Spinner';

const ClientsList = ({
	setTotalNumberClientsCompany,
}) => {
	const { t } = useTranslation('baseFormPlaceholders');
	const [allCompanies, setAllCompanies] = useState(null);
	const [pagedFilteredSortedCompanies, setPagedFilteredSortedCompanies] = useState(null);
	const [chunkedCompaniesData, setChunkedCompaniesData] = useState(null);
	const [isSearchApplied, setSearchApplied] = useState(null);
	const [isPaginationShown, setPaginationShown] = useState(false);
	const [activePaginationPage, setActivePaginationPage] = useState(1);
	const [totalClientsCompanyItemsCount, setClientsCompanyItemsCount] = useState(1);
	const notificationsHandler = useRef(new ToastifyHandler());
	const [clientCompanyCountPerPage, setClientCompanyCountPerPage] = useState(10);
	const entriesPerPageOptions = [
		{ value: 10, label: '10' },
		{ value: 15, label: '15' },
		{ value: 25, label: '25' },
		{ value: 50, label: '50' },
	];

	const updateCurrentActivePaginationPage = () => {
		const activePageData = cloneObjectWithoutReference(chunkedCompaniesData[activePaginationPage - 1]);

		setPagedFilteredSortedCompanies(activePageData);
	};

	const sendNotificationOfPricesToCustomer = async (company) => {
		let person = await USERS_API.getUser(company.adminUserId);
		const lang = company.language || person.language;
		const lngOpts = lang ? { lng: lang } : {};
		person.firstName = person.name;
		const salutation = t(`email:salutationFmt_${person.gender}`, lngOpts).replace(/\{(\w+)\}/g, (_match, key) => person[key] || '');
		const vars = {
			salutation,
			company: company.name,
			configuratorURL: "https://configurator.cdr.ch",
		};
		const htmlEmailBody = t('email:showPricesFmt', lngOpts).replace(/\{(\w+)\}/g, (_match, key) => vars[key] || '');
		const emailProps = {
			subject: t('email:showPricesSubject', lngOpts),
			html_body: htmlEmailBody,
			from_name: 'CDR Configurator',
			from_email: 'configurator@cdr.ch',
			to_email: company.creatorCompanyEmail,
			bcc_email: 'info@cdr.ch',
			reply_to: 'info@cdr.ch',
		};

		await emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
			emailProps,
			process.env.REACT_APP_EMAILJS_USER_ID,
		);
	};

	const handleOnChangeSearchInput = (e) => {
		const inputValueLowerCase = e.target.value.toLowerCase();

		if (inputValueLowerCase.trim() !== '') {
			const filteredArray = allCompanies.filter(({
				address,
				city,
				creatorCompanyEmail,
				name,
				tel,
				zip,
			}) => {
				const addressLowerCase = address ? address.toLowerCase() : '';
				const cityReferenceLowerCase = city ? city.toLowerCase() : '';
				const emailLowerCase = creatorCompanyEmail ? creatorCompanyEmail.toLowerCase() : '';
				const nameEditedLowerCase = name ? name.toLowerCase() : '';
				const telEditedLowerCase = tel ? tel.toLowerCase() : '';
				const zipEditedLowerCase = zip ? zip.toLowerCase() : '';

				return (
					addressLowerCase.includes(inputValueLowerCase)
					|| cityReferenceLowerCase.includes(inputValueLowerCase)
					|| emailLowerCase.includes(inputValueLowerCase)
					|| nameEditedLowerCase.includes(inputValueLowerCase)
					|| telEditedLowerCase.includes(inputValueLowerCase)
					|| zipEditedLowerCase.includes(inputValueLowerCase)
				);
			});

			setPagedFilteredSortedCompanies(filteredArray);
			setSearchApplied(true);
		} else {
			if (isPaginationShown) {
				updateCurrentActivePaginationPage();
			} else {
				const clonedFilteredCompanies = cloneObjectWithoutReference(allCompanies);

				setPagedFilteredSortedCompanies(clonedFilteredCompanies);
			}
			setSearchApplied(false);
		}
	};

	const splitCompaniesByPage = (clientsCompanyListData) => {
		if (!clientsCompanyListData) return;

		const chunkedData = chunkArray(clientsCompanyListData, clientCompanyCountPerPage);
		let page = activePaginationPage;
		if (activePaginationPage > chunkedData.length) {
			page = chunkedData.length;
			setActivePaginationPage(page);
		}
		const activePageData = cloneObjectWithoutReference(chunkedData[page - 1]);

		setClientsCompanyItemsCount(clientsCompanyListData.length);
		setPagedFilteredSortedCompanies(activePageData);
		setChunkedCompaniesData(chunkedData);
		setPaginationShown(chunkedData.length > 1);
	};

	const setShowPrices = async (event) => {
		const { target } = event;
		const companyId = target.dataset.clientId;
		const showPrices = target.checked;
		if (!companyId) return;

		const company = allCompanies.find(o => o.id === companyId);
		if (company.showPrices === showPrices) return;

		target.disabled = true;
		notificationsHandler.current.pending(t('notifications:pleaseWait'));
		try {
			await COMPANIES_API.updateCompanyData(companyId, { showPrices });
			notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
			if (showPrices) {
				sendNotificationOfPricesToCustomer(company);
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			target.disabled = false;
		}
	};

	const setCustomerReference = async (event) => {
		const { target } = event;
		const companyId = target.id.substr('customer_ref_'.length);
		if (!companyId) return;
		const customerReference = target.value;

		const company = allCompanies.find(o => o.id === companyId);
		if (company.customerReference === customerReference) return;

		target.disabled = true;
		notificationsHandler.current.pending(t('notifications:pleaseWait'));
		try {
			await COMPANIES_API.updateCompanyData(companyId, { customerReference });
			setAllCompanies(allCompanies); // we updated showPrices
			notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			target.disabled = false;
		}
	};

	const setNewActivePaginationPage = (pageNumber) => {
		let page = pageNumber;
		if (page > chunkedCompaniesData.length) {
			page = chunkedCompaniesData.length;
		}
		const activePageData = cloneObjectWithoutReference(chunkedCompaniesData[page - 1]);

		setActivePaginationPage(page);
		setPagedFilteredSortedCompanies(activePageData);
	};

	const handlePaginationPageChange = (pageNumber) => {
		setNewActivePaginationPage(pageNumber);
	};

	const handleSort = (e) => {
		console.log(e.target.dataset.sortBy); // "address"
	};

	const fetchAllClientsCompanies = async () => {
		const res = await COMPANIES_API.getAllCompaniesWithNumAccounts();

		if (res?.data) {
			setAllCompanies(res.data);
		}
	};

	const notifyCompanyDeleted = (companyId) => {
		const remainingCompanies = allCompanies.filter((o) => o.companyId !== companyId);
		setAllCompanies(remainingCompanies);
	};

	useEffect(() => {
		fetchAllClientsCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!allCompanies) {
			return;
		}
		const numCompanies = allCompanies.length;
		if (setTotalNumberClientsCompany) {
			setTotalNumberClientsCompany(numCompanies);
		}

		if (numCompanies > clientCompanyCountPerPage) {
			splitCompaniesByPage(allCompanies);
		} else {
			setPaginationShown(false);
			const clonedFilteredCompanies = cloneObjectWithoutReference(allCompanies);
			setPagedFilteredSortedCompanies(clonedFilteredCompanies);
		}
	}, [allCompanies, clientCompanyCountPerPage]);

	return (
		<div className="dashboard_block">
			<div className="dashboard_block__heading">
				<BlockHeading
					title={t('adminDashboardPage:clientsTitle')}
					iconName="user"
				/>
				<div className="dashboard_block__header_container">
					<div className="dashboard_block__field">
						<FormNumEntriesSelector
							id="clientsNumEntries"
							defaultValue={entriesPerPageOptions.find((o) => o.value === clientCompanyCountPerPage)}
							options={entriesPerPageOptions}
							onChange={(selectedOption) => {
								setClientCompanyCountPerPage(selectedOption.value);
							}}
						/>
					</div>
					<div className="dashboard_block__field">
						<FormSearchField
							id="clientSearchField"
							placeholder={t('searchField')}
							name="clientSearchField"
							onChange={handleOnChangeSearchInput}
						/>
					</div>
				</div>
			</div>
			{pagedFilteredSortedCompanies && pagedFilteredSortedCompanies.length > 0 ? (
				<ul className="clients_list">
					{pagedFilteredSortedCompanies.map(({
						showPrices,
						customerReference,
						address,
						city,
						creatorCompanyEmail,
						id,
						name,
						tel,
						zip,
						language,
						numAccounts,
					}) => {
						return (
							<li className="clients_list__item" key={id}>
								<ClientBlock
									showPrices={showPrices || false}
									setShowPrices={setShowPrices}
									customerReference={customerReference}
									setCustomerReference={setCustomerReference}
									address={address}
									city={city}
									email={creatorCompanyEmail}
									id={id}
									name={name}
									tel={tel}
									zip={zip}
									language={language}
									numAccounts={numAccounts}
									notifyCompanyDeleted={notifyCompanyDeleted}
									handleSort={handleSort}
								/>
							</li>
						);
					})}
				</ul>
			) : (allCompanies ? null : <Spinner darkTheme />)}
			{pagedFilteredSortedCompanies && isPaginationShown && !isSearchApplied ? (
				<PaginationBlock
					activePaginationPage={activePaginationPage}
					itemsCountPerPage={clientCompanyCountPerPage}
					totalItemsCount={totalClientsCompanyItemsCount}
					handlePaginationPageChange={handlePaginationPageChange}
				/>
			) : null}
		</div>
	);
};

export default ClientsList;
