import React from 'react';
import { useTranslation } from 'react-i18next';
import FaqItem from './FaqItem';

const FaqResults = ({
	searchResults,
}) => {
	const { t } = useTranslation(["faqPage"]);

	return (
		<div className="faq_result">
			<div className="faq_result_title">
				{
					searchResults.length === 1
						? t("foundSingleAnswer")
						: t("foundMultipleAnswers").replace('{numAnswers}', searchResults.length)
				}
			</div>
			{searchResults && searchResults.length ? (
				<div className="faq_content">
					{searchResults.map(({
						question,
						answer,
					}, index) => {
						return (
							<FaqItem
								key={index}
								title={question}
								text={answer}
								fullMod
							/>
						);
					})}
				</div>
			) : null}
		</div>
	);
};

export default FaqResults;
