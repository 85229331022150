import { CONFIGURATIONS_API } from 'api';
import { AuthContext } from 'context/auth';
import { ConfigurationDataContext } from 'context/configuration';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cloneObjectWithoutReference, generateUniqueId, getCurrentDate } from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfConfigurationsDocument } from 'components/PdfComponents';
import { CONFIGURATION_STATUS, CONFIGURATION_TYPE, STATUS_RESPONSE } from 'utils/constant';
import { UiContext } from 'context/ui';

const ConfigurationBlockDraftsOptions = ({
	configuration,
	configurationCode,
	dropdownOptions,
	foldUp = false,
}) => {
	const { t } = useTranslation(['commonConfigurationAction', 'firebaseErrorMessages']);
	const { currentUser, currentUserAdditionalData } = useContext(AuthContext);
	const { showPopupByKey } = useContext(UiContext);
	const [pdfLoading, setPdfLoading] = useState(false);
	const notificationsHandler = useRef(new ToastifyHandler());
	const {
		setConfiguration,
		totalGlassSizes,
		totalGlassSizesSum,
	} = useContext(ConfigurationDataContext);
	const navigate = useNavigate();

	const deleteConfiguration = async () => {
		notificationsHandler.current.pending(t('notifications:configurationDeletionInProgress'));

		if (configurationCode === configuration?.code) {
			setConfiguration(null);
		}

		try {
			await CONFIGURATIONS_API.deleteConfiguration(configurationCode);
			notificationsHandler.current.success(t('notifications:configurationWasDeletedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const editConfiguration = () => {
		if (!configuration) return;

		setConfiguration(configuration);
		navigate('/cart');
	};

	const changeConfigurationType = async (configurationType) => {
		if (!configurationType) return;

		notificationsHandler.current.pending(t('notifications:configurationTypeChangeInProgress'));

		try {
			const configurationCopy = cloneObjectWithoutReference(configuration);
			configurationCopy.type = configurationType;
			await CONFIGURATIONS_API.addNewConfiguration(configurationCopy);
			notificationsHandler.current.success(t('notifications:configTypeChangedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const createNewConfigurationBasedOnExisting = (newConfigurationCode) => {
		const duplicatedConfiguration = cloneObjectWithoutReference(configuration);
		const currentDate = getCurrentDate();
		const dateNow = Date.now();

		duplicatedConfiguration.code = newConfigurationCode;
		duplicatedConfiguration.created = currentDate;
		duplicatedConfiguration.lastEdited = currentDate;
		duplicatedConfiguration.publishedAt = dateNow;
		duplicatedConfiguration.lastEditedDateInMilliseconds = dateNow;
		duplicatedConfiguration.type = CONFIGURATION_TYPE.drafts;
		duplicatedConfiguration.status = CONFIGURATION_STATUS.open;

		if (currentUser && currentUserAdditionalData) {
			duplicatedConfiguration.creatorUserId = currentUser.uid;
			duplicatedConfiguration.lastEditedUserId = currentUser.uid;
			duplicatedConfiguration.lastEditedUserEmail = currentUserAdditionalData.email;
		}

		return duplicatedConfiguration;
	};

	const duplicateConfiguration = async () => {
		notificationsHandler.current.pending(t('notifications:duplicatingConfigWithAllFilesInProgress'));

		try {
			const functions = getFunctions();
			const copyStorageFilesFromOneBucketToAnother = httpsCallable(functions, 'copyStorageFilesFromOneBucketToAnother');
			const newConfigurationCode = generateUniqueId();
			const duplicatedConfiguration = createNewConfigurationBasedOnExisting(newConfigurationCode);

			if (duplicatedConfiguration.uploadedDocuments && duplicatedConfiguration.uploadedDocuments.length) {
				const copyFilesRes = await copyStorageFilesFromOneBucketToAnother({
					companyId: configuration.companyId,
					srcConfigurationCode: configurationCode,
					destConfigurationCode: newConfigurationCode,
				});

				if (copyFilesRes.data.status && copyFilesRes.data.status === STATUS_RESPONSE.ok) {
					duplicatedConfiguration.uploadedDocuments.forEach((document, index) => {
						duplicatedConfiguration.uploadedDocuments[index].url = document.url.replace(configurationCode, newConfigurationCode);
					});
				}
			}

			await CONFIGURATIONS_API.addNewConfiguration(duplicatedConfiguration);
			notificationsHandler.current.success(t('notifications:configurationDuplicationCompletedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const changeConfigurationStatus = async (configurationStatus) => {
		if (!configurationStatus) return;

		notificationsHandler.current.pending(t('notifications:configurationStatusChangeInProgress'));
		try {
			const configurationCopy = cloneObjectWithoutReference(configuration);
			configurationCopy.lastEditedUserId = currentUser.uid;
			configurationCopy.status = configurationStatus;
			await CONFIGURATIONS_API.addNewConfiguration(configurationCopy);
			notificationsHandler.current.success(t('notifications:configurationStatusChangedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const generatePdf = async () => {
		setPdfLoading(true);
		const currentDate = getCurrentDate();
		const documentFileName = t("commonConfigurationAction:configurationDotPdfFmt").replace('{date}', currentDate);
		const pdfDocument = (
			<PdfConfigurationsDocument
				configuration={configuration}
				totalValues={totalGlassSizes}
				totalValuesSum={totalGlassSizesSum}
				showPrice={currentUserAdditionalData?.company?.showPrices || false}
			/>
		);
		const blob = await pdf(pdfDocument).toBlob();
		setPdfLoading(false);
		saveAs(blob, documentFileName);
	};

	const handleClick = (action) => {
		if (action === 'delete') {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfConfigurationDeletion'),
				confirmDialog: isConfirmed => isConfirmed && deleteConfiguration(),
			});
		} else if (action === 'edit') {
			editConfiguration();
		} else if (action === 'order' || action === 'request') {
			showPopupByKey('genericWarning', {
				title: t('notifications:confirmationOfChangeTypeConfiguration'),
				confirmDialog: isConfirmed => isConfirmed && changeConfigurationType(action),
			});
		} else if (action === 'complete') {
			changeConfigurationStatus(CONFIGURATION_STATUS.closed);
		} else if (action === 'reopen') {
			changeConfigurationStatus(CONFIGURATION_STATUS.open);
		} else if (action === 'duplicate' || action === 'duplicateAsDraft') {
			duplicateConfiguration();
		} else if (action === 'saveAsPdf') {
			generatePdf();
		}
	};

	const dropdownFoldUpStyle = {
		top: 'auto',
		bottom: '100%',
	};

	return (
		dropdownOptions ? (
			<ul style={foldUp ? dropdownFoldUpStyle : null} className="configuration_block_options">
				{dropdownOptions.map(({
					label,
				}, index) => {
					return (
						<li className="configuration_block_options__item" key={index}>
							{(pdfLoading && label === 'saveAsPdf') ? (
								<span className="configuration_block_options__title">...</span>
							) : (
								<button
									className="configuration_block_options__title"
									type="button"
									onClick={
										(e) => {
											e.stopPropagation();
											handleClick(label);
										}
									}
								>
									{t(label)}
								</button>
							)}
						</li>
					);
				})}
			</ul>
		) : null
	);
};

export default ConfigurationBlockDraftsOptions;
