/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useContext } from "react";

import { AdditionalPageNavigation } from "components/AdditionalPageNavigation";
import { ConfigurationCode } from "components/ConfigurationCode";
import { PageNavigation } from "components/PageNavigation";
import { PageNavHeading } from "components/PageNavHeading";
import { GuestForm, RegistrationForm } from "components/Form";
import { ConfigurationDataContext } from "context/configuration";
import { SaveAsSection } from "components/SaveAsSection";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/auth";
import { Navigate } from "react-router-dom";

const GetMyGlassPage = () => {
  const { configuration } = useContext(ConfigurationDataContext);
  const { currentUser, guestUser } = useContext(AuthContext);
  const { t } = useTranslation("cartPage");

  return (currentUser || guestUser) ? (
    <Navigate to="/summary" />
  ) : (
    configuration ? (
      <>
        <section className="section">
          <div className="section__in">
            <div className="section__block">
              <div className="page_head">
                <AdditionalPageNavigation />
                <PageNavigation />
              </div>
            </div>
            {configuration && configuration.code ? (
              <div className="section__block">
                <ConfigurationCode code={configuration.code} />
              </div>
            ) : null}
            <div className="section__block">
              <div className="auth_list">
                <div className="auth_block">
                  <GuestForm />
                </div>
                <div className="auth_block">
                  <RegistrationForm />
                </div>
              </div>
            </div>
          </div>
        </section>
        <SaveAsSection />
      </>
    ) : (
      <section className="section">
        <div className="section__in">
          <PageNavHeading />
          <div className="section__block">
            <h1 className="section__title section__title--center_hr_state">
              {t('cartIsEmpty')}
            </h1>
          </div>
        </div>
      </section>
    )
  );
};

export default GetMyGlassPage;
