import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { getCurrentDate, generateUniqueId, cloneObjectWithoutReference } from 'utils';
import { CONFIGURATIONS_API } from 'api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth';
import { CONFIGURATION_TYPE, CONFIGURATION_STATUS } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import jsonAppData from 'utils/jsonAppData';
import { DEFAULT_GLASS_SIZES } from 'domain/glass';
import UiContext from './ConfigurationDataContext';

const ConfigurationDataState = ({ children }) => {
	const [configuration, setConfiguration] = useState(null);
	const [editingGlassId, setEditingGlassId] = useState(null);
	const [errorFields, setErrorFields] = useState([]);
	const [showErrors, setShowErrors] = useState(false);
	const [showEmptyReferenceWarning, setShowEmptyReferenceWarning] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [totalGlassSizes, setTotalGlassSizes] = useState([]);
	const [totalGlassSizesSum, setTotalGlassSizesSum] = useState(DEFAULT_GLASS_SIZES);
	const [isSizesFilled, setIsSizesFilled] = useState(true);
	const [userCodeConfiguration, setUserCodeConfiguration] = useState(null);
	const { currentUser, currentUserAdditionalData } = useContext(AuthContext);
	const { t } = useTranslation(['notifications', 'firebaseErrorMessages']);
	const navigate = useNavigate();
	const notificationsHandler = useRef(new ToastifyHandler());
	const isUserAuthorized = useRef(false);

	const createNewConfiguration = (newGlassData) => {
		const currentDate = getCurrentDate();
		const currentDateInMilliseconds = Date.now();
		const uniqueId = generateUniqueId();
		const uniqueGlassId = generateUniqueId();

		const glassPositions = [...jsonAppData.defaultGlassPositionsData];

		glassPositions[0].id = generateUniqueId();

		const newConfiguration = {
			code: uniqueId,
			publishedAt: currentDateInMilliseconds,
			created: currentDate,
			lastEdited: currentDate,
			lastEditedDateInMilliseconds: currentDateInMilliseconds,
			reference: '',
			createdUserId: null,
			lastEditedUserId: null,
			lastEditedUserEmail: null,
			companyId: null,
			status: CONFIGURATION_STATUS.open,
			type: CONFIGURATION_TYPE.drafts,
			buildingReference: '',
			glassTypes: [{
				...newGlassData,
				id: uniqueGlassId,
				positions: [...glassPositions],
			}],
			amountGlassTypes: 1,
			uploadedDocuments: [],
		};

		if (currentUserAdditionalData) {
			newConfiguration.createdUserId = currentUser.uid;
			newConfiguration.lastEditedUserId = currentUser.uid;
			newConfiguration.lastEditedUserEmail = currentUserAdditionalData.email;
			newConfiguration.companyId = currentUserAdditionalData.companyId;
		}

		return newConfiguration;
	};

	const loadConfigurationToFirebase = async (newConfiguration, showNotification = true) => {
		try {
			if (showNotification) notificationsHandler.current.pending(t('loadConfiguration'));
			setIsLoading(true);

			await CONFIGURATIONS_API.addNewConfiguration(newConfiguration);

			if (showNotification) notificationsHandler.current.success(t('configurationUpdatedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code || 'unknown', { ns: 'firebaseErrorMessages' }));
			if (!code) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const addNewGlassToConfiguration = async (newGlassData) => {
		const currentDate = getCurrentDate();
		const updatedConfiguration = cloneObjectWithoutReference(configuration);
		const glassPositions = [...jsonAppData.defaultGlassPositionsData];

		glassPositions[0].id = generateUniqueId();

		const updatedGlassData = cloneObjectWithoutReference(newGlassData);

		updatedGlassData.id = generateUniqueId();
		updatedGlassData.positions = [...glassPositions];

		updatedConfiguration.glassTypes.push(updatedGlassData);
		updatedConfiguration.amountGlassTypes = updatedConfiguration.glassTypes.length;
		updatedConfiguration.lastEdited = currentDate;
		updatedConfiguration.lastEditedUserId = currentUser.uid;
		updatedConfiguration.lastEditedUserEmail = currentUserAdditionalData.email;

		setConfiguration(updatedConfiguration);
		await loadConfigurationToFirebase(updatedConfiguration);
		navigate('/cart');
		document.activeElement.blur();
	};

	const updateGlassInConfiguration = async (editedGlassData) => {
		const currentDate = getCurrentDate();
		const updatedConfiguration = cloneObjectWithoutReference(configuration);
		const updatedGlassData = cloneObjectWithoutReference(editedGlassData);

		let glassDataIdx = updatedConfiguration.glassTypes.findIndex(o => o.id === editingGlassId);
		if (glassDataIdx < 0) {
			return;
		}
		const glassPositions = [...updatedConfiguration.glassTypes[glassDataIdx].positions];
		updatedGlassData.positions = [...glassPositions];
		updatedConfiguration.glassTypes[glassDataIdx] = updatedGlassData;
		updatedConfiguration.lastEdited = currentDate;

		setConfiguration(updatedConfiguration);
		await loadConfigurationToFirebase(updatedConfiguration);
		navigate('/cart');
	};

	const addNewConfiguration = async newGlassData => {
		if (!configuration) {
			const newConfiguration = createNewConfiguration(newGlassData);

			setConfiguration(newConfiguration);
			await loadConfigurationToFirebase(newConfiguration);
			navigate('/cart');
			document.activeElement.blur();
		} else {
			addNewGlassToConfiguration(newGlassData);
		}
	};

	const editConfiguration = editGlassData => {
		if (!configuration) {
			return;
		}

		setEditingGlassId(editGlassData.id);
		window.filters_handler.ui.applyFlatSnapshot(editGlassData.flatGlassStructure);
		navigate('/');
	};

	const saveEditedConfiguration = async saveEditedGlassData => {
		if (!configuration) {
			return;
		}

		setEditingGlassId(null);
		updateGlassInConfiguration(saveEditedGlassData);
	};

	const saveGuestConfigurationToCompany = async () => {
		const newConfiguration = cloneObjectWithoutReference(configuration);

		newConfiguration.createdUserId = currentUser.uid;
		newConfiguration.lastEditedUserId = currentUser.uid;
		newConfiguration.lastEditedUserEmail = currentUserAdditionalData.email;
		newConfiguration.companyId = currentUserAdditionalData.companyId;

		try {
			notificationsHandler.current.pending(t('loadConfiguration'));
			setIsLoading(true);

			await CONFIGURATIONS_API.addNewConfiguration(newConfiguration);

			setConfiguration(newConfiguration);
			notificationsHandler.current.success(t('configurationIsSavedInTheCompany'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (currentUser && currentUserAdditionalData) {
			if (!isUserAuthorized.current && configuration) {
				saveGuestConfigurationToCompany();
			}
			isUserAuthorized.current = true;
		} else {
			isUserAuthorized.current = false;
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, currentUserAdditionalData]);

	return (
		<UiContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				isLoading,
				configuration,
				setConfiguration,
				addNewConfiguration,
				editConfiguration,
				editingGlassId,
				saveEditedConfiguration,
				userCodeConfiguration,
				setUserCodeConfiguration,
				loadConfigurationToFirebase,
				totalGlassSizes,
				setTotalGlassSizes,
				totalGlassSizesSum,
				setTotalGlassSizesSum,
				isSizesFilled,
				setIsSizesFilled,
				errorFields,
				setErrorFields,
				showErrors,
				setShowErrors,
				showEmptyReferenceWarning,
				setShowEmptyReferenceWarning,
			}}
		>
			{children}
		</UiContext.Provider>
	);
};

export default ConfigurationDataState;
