import React from 'react';
import { useTranslation } from 'react-i18next';

const PageNavigationButton = ({
	onClickHandler,
	i18nLabel,
	url,
}) => {
	const { t } = useTranslation('pageNavigation');

	return (
		<button
			className="page_navigation__link"
			type="button"
			onClick={(e) => onClickHandler(e, url)}
		>
			{t(i18nLabel)}
		</button>
	);
};

export default PageNavigationButton;
