import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixedPdf as formatNumberToFixed } from 'utils';
import { DEFAULT_GLASS_SIZES } from 'domain/glass';
import PdfGlassConfigTableRowCell from './PdfGlassConfigTableRowCell';

import styles from '../styles';

const PdfGlassConfigTableRow = ({
	tableRow,
	positionIndex,
	totalValues,
	showPrice,
}) => {
	const { t } = useTranslation('configurationTableHeading');
	const notes = tableRow.row.find((cellItem) => cellItem.name === "notes") || { value: '-' };
	const total = totalValues || { m2Pc: '-', ...DEFAULT_GLASS_SIZES };

	return (
		<>
			<View style={styles.glassConfigTableRow}>
				{positionIndex ? (
					<View style={{ ...styles.glassConfigTableCell, ...styles.glassConfigTableCellFirstChild }}>
						<Text>{positionIndex}</Text>
					</View>
				) : null}
				{tableRow.row && tableRow.row.length ? (
					<>
						{tableRow.row.map((cellItem, index) => {
							if (cellItem.name === "notes") return null;
							let cellData = { ...cellItem, value: cellItem.name === 'cantonalInsurance' ? cellItem.value : formatNumberToFixed(cellItem.value, 0) };
							const isIncomplete = (cellItem.name === "length" || cellItem.name === "width" || cellItem.name === "quantity") && (cellItem.value === "");
							return (
								<PdfGlassConfigTableRowCell
									key={index}
									cellData={cellData}
									isIncomplete={isIncomplete}
								/>
							);
						})}
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.m2Pc) }} />
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.m2) }} />
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.kg) }} isLastCell={!showPrice} />
						{
							showPrice ? (<PdfGlassConfigTableRowCell cellData={{ value: total.price === null ? '-' : formatNumberToFixed(total.price) }} isLastCell />) : null
						}
					</>
				) : null}
			</View>
			<View style={{ ...styles.glassConfigTableRow, ...styles.glassConfigTableRow2 }}>
				<Text style={{
					...styles.glassConfigTableCell,
					...styles.glassConfigTableCellFirstChild,
					...styles.glassConfigTableCellTitle,
				}}
				>
					{t('notes')}

				</Text>
				<PdfGlassConfigTableRowCell cellData={notes} isLastCell isNotes />
			</View>
		</>
	);
};

export default PdfGlassConfigTableRow;
