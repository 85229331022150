import React from 'react';

import GlassConfigDetailItem from '../GlassConfigDetailItem';

const GlassConfigDetailFirst = ({
	configDetails,
}) => {
	const { structure } = configDetails.flatGlassStructure;
	return (
		<ul className="glass_config__details">
			<GlassConfigDetailItem
				title="glassType"
				categoryLabel="fireResistant"
				selected={configDetails.fireResistant}
			/>
			{structure !== '1-glazed' ? (
				<>
					{structure === '3-glazed' ? (
						<>
							<GlassConfigDetailItem
								categoryLabel="spacer1"
								selected={configDetails.spacer1}
							/>
							<GlassConfigDetailItem
								categoryLabel="gas1"
								selected={configDetails.gas1}
							/>
							<GlassConfigDetailItem
								categoryLabel="middleGlass"
								selected={configDetails.middleGlass}
							/>
						</>
					) : null}
					<GlassConfigDetailItem
						categoryLabel="spacer2"
						selected={configDetails.spacer2}
					/>
					<GlassConfigDetailItem
						categoryLabel="gas2"
						selected={configDetails.gas2}
					/>
					<GlassConfigDetailItem
						categoryLabel="outsideGlass"
						selected={configDetails.outsideGlass}
					/>
				</>
			) : null}
			<GlassConfigDetailItem
				categoryLabel="thickness"
				selected={configDetails.thickness}
			/>
		</ul>
	);
};

export default GlassConfigDetailFirst;
