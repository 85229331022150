import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';
import UploadedDocumentsList from './UploadedDocumentsList';
import UploadedDocumentsArea from './UploadedDocumentsArea';

const UploadedDocuments = ({
	uploadedDocuments,
	previewPage,
}) => {
	const { t } = useTranslation('cartPage');

	return (
		<div className="uploaded_doc">
			<BlockHeading
				title={t('uploadedDocuments')}
				iconName="document"
			/>
			{!previewPage ? (
				<div className="uploaded_doc__area_wrap">
					<UploadedDocumentsArea id="uploadedDocumentsArea" uploadedDocuments={uploadedDocuments} />
				</div>
			) : null}
			{uploadedDocuments && uploadedDocuments.length ? (
				<UploadedDocumentsList
					documentsList={uploadedDocuments}
				/>
			) : null}
		</div>
	);
};

export default UploadedDocuments;
