import Insulation from "./Glass/InsulationValues";
import Papa from 'papaparse';
import SoundDampening from "./Glass/SoundDampeningValues";

export default class GlassDBLoader {
  static loadDb(glassDb, onLoaded) {
    let onLoadCounter = 5;
    const onLoadCallback = () => {
      onLoadCounter--;
      if (onLoadCounter == 0) {
        onLoaded();
      }
    };
    async function fetchGlassTranslations() {
      try {
        const response = await fetch('./data/glass_translation.json');
        const translation = await response.json();
        glassDb.addTranslation(translation);
      } catch (error) {
        console.error('Error fetching glass translations:', error);
      } finally {
        onLoadCallback();
      }
    };

    fetchGlassTranslations();
    const glass_csvs = {
      'frg': './data/frg.csv',
      'out': './data/Outside-Glass.csv',
      'mid': './data/Middle-Glass.csv',
      'price': './data/preismatrix.csv'
    };
    Object.keys(glass_csvs).forEach(k => {
      Papa.parse(glass_csvs[k], {
        download: true,
        step: function (row) {
          if (row.data.length > 2 /* CRLF */) {
            try {
              glassDb.addRow(k, row.data);
            } catch (e) {
              console.error(`Error parsing ${row.data}`);
            }
          }
        },
        complete: function () {
          onLoadCallback();
        }
      });
    });

    glassDb.db['uValue']['2-glazed'] = Insulation.uValueTable['2-glazed'];
    glassDb.db['uValue']['3-glazed'] = Insulation.uValueTable['3-glazed'].filter(v => v.spacer1 === v.spacer2); // only load values with equal spacers

    glassDb.db['sound'] = SoundDampening.soundDampeningTable;
  }
}
