import React from 'react';

import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';
import PdfGlassConfigDetailItem from './PdfGlassConfigDetailItem';

import styles from '../styles';

const PdfGlassConfigDetailFirst = ({
	configDetails,
}) => {
	const { structure } = configDetails.flatGlassStructure;
	const { t } = useTranslation('configurationCategory');

	return (
		<View style={styles.glassConfigDetails}>
			<PdfGlassConfigDetailItem
				title={t('glassType')}
				categoryLabel="fireResistant"
				selected={configDetails.fireResistant}
				isFirstItem
			/>
			{structure !== '1-glazed' ? (
				<>
					{structure === '3-glazed' ? (
						<>
							<PdfGlassConfigDetailItem
								categoryLabel="spacer1"
								selected={configDetails.spacer1}
								accentMod
							/>
							<PdfGlassConfigDetailItem
								categoryLabel="gas1"
								selected={configDetails.gas1}
								accentMod
							/>
							<PdfGlassConfigDetailItem
								categoryLabel="middleGlass"
								selected={configDetails.middleGlass}
							/>
						</>
					) : null}
					<PdfGlassConfigDetailItem
						categoryLabel="spacer2"
						selected={configDetails.spacer2}
						accentMod
					/>
					<PdfGlassConfigDetailItem
						categoryLabel="gas2"
						selected={configDetails.gas2}
						accentMod
					/>
					<PdfGlassConfigDetailItem
						categoryLabel="outsideGlass"
						selected={configDetails.outsideGlass}
					/>
				</>
			) : null}
			<PdfGlassConfigDetailItem
				categoryLabel="thickness"
				selected={configDetails.thickness}
			/>
		</View>
	);
};

export default PdfGlassConfigDetailFirst;
