import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	block: {
		marginBottom: 20,
	},
	glassConfig: {
		position: 'relative',
	},
	glassConfigImageW: {
		height: 324,
		marginBottom: 35,
	},
	glassConfigImageWlgState: {
		height: 545,
		marginBottom: 35,
	},
	glassConfigImage: {
		display: 'block',
		height: '100%',
		width: '100%',
		objectFit: 'contain',
	},
	glassConfigDetails: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignContent: 'flex-start',
		alignItems: 'stretch',
		marginBottom: 6,
		borderRadius: 2,
	},
	glassConfigDetailsIndentState: {
		marginRight: -8,
		// marginBottom: -8,
		marginBottom: 1,
	},
	glassConfigItem: {
		flexGrow: 1,
	},
	glassConfigItemShortState: {
		paddingRight: 8,
		paddingBottom: 8,
		minHeight: 38,
		width: '16.66%',
		flexGrow: 1,
	},
	glassConfigBlockLgState: {
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'flex-end',
		alignItems: 'flex-end',
		justifyContent: 'flex-start',
		paddingTop: 15,
		paddingHorizontal: 12,
		paddingBottom: 18,
		minHeight: 8,
		flexGrow: 1,
		backgroundColor: '#fff',
	},
	glassConfigBLockLgAccentState: {
		backgroundColor: '#fbfbfb',
	},
	glassConfigBLockLgFirstItem: {
		paddingLeft: 30,
	},
	glassConfigBlockSmState: {
		paddingTop: 12,
		paddingBottom: 4,
		paddingHorizontal: 8,
		textAlign: 'center',
		backgroundColor: '#fff',
		borderRadius: 2,
	},
	glassConfigBlockTitle: {
		marginBottom: 3,
		fontSize: 10,
		fontWeight: 700,
		textTransform: 'uppercase',
		color: '#575757',
	},
	glassConfigBlockType: {
		fontSize: 7,
		fontWeight: 400,
		textTransform: 'uppercase',
		color: '#8b8b8b',
	},
	glassConfigBlockSelected: {
		fontSize: 9,
		fontWeight: 400,
		lineHeight: 1.8,
		color: '#707070',
		textTransform: 'uppercase',
	},
	glassConfigTableList: {
		marginBottom: 15,
	},
	glassConfigTableRow: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignContent: 'flex-start',
		paddingTop: 9,
		paddingBottom: 3,
		paddingRight: 41,
		paddingLeft: 9,
		backgroundColor: '#fff',
	},
	glassConfigTableRowTotalMod: {
		flexWrap: 'wrap',
		marginBottom: 20,
	},
	glassConfigTableRow2: {
		marginBottom: 2,
	},
	glassConfigTableRowHeadingState: {
		backgroundColor: '#F2F2F2',
		marginBottom: 0,
	},
	glassConfigTableCellTitle: {
		fontSize: 10,
		fontWeight: 700,
		textTransform: 'uppercase',
	},
	glassConfigTableCellTitleValueMod: {
		fontWeight: 400,
	},
	glassConfigTableCellTitleColor1: {
		color: '#5486a4',
	},
	glassConfigTableCellTitleOffsetMod: {
		marginRight: 4,
	},
	glassConfigTableTotalCell: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'center',
		paddingHorizontal: 20,
	},
	glassConfigTableTotalCellFirst: {
		paddingHorizontal: 0,
		alignItems: 'flex-start',
	},
	glassConfigTableCell: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'baseline',
		width: '11.0%',
		flexShrink: 0,
		height: 21,
		paddingHorizontal: 8,
		paddingBottom: 2,
		textAlign: 'center',
		overflow: 'visible',
		fontSize: 10,
		color: '#575757',
		// borderWidth: '1px',
		// borderColor: 'yellow',
	},
	glassConfigTableCellIncomplete: {
		backgroundColor: '#ffe2dc',
	},
	glassConfigTableCellNotes: {
		width: 'auto',
		textAlign: 'left',
		flexWrap: 'nowrap',
		// borderColor: 'red',
	},
	glassConfigTableCellFirstChild: {
		width: '5%',
		padding: 0,
		fontWeight: 700,
		// borderColor: 'orange',
	},
	glassConfigTableCellLastChild: {
		paddingRight: 0,
		paddingLeft: 4,
		height: 'auto',
		width: '15%',
		minHeight: 21,
	},
	glassConfigTableCellHeadingState: {
		fontWeight: 700,
		textTransform: 'uppercase',
		lineHeight: 1.4,
	},
	glassConfigTableCellHeadingStateSize: {
		textTransform: 'none',
		fontWeight: 400,
		fontSize: 6,
	},
	glassConfigTableCellValueIn: {
		fontFamily: 'DINNextLTPro',
	},
	glassConfigTableCellSupWrap: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
	},
	glassConfigTableCellSup: {
		fontSize: 7,
	},
	glassConfigTableFormCheckbox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	glassConfigTableFormCheckboxLabel: {
		display: 'inline-block',
	},
	glassConfigTableFormCheckboxLabelTextUnchecked: {
		width: 12,
		height: 12,
		border: 1,
		borderStyle: 'solid',
		borderColor: '#e5e5e5',
	},
	glassConfigTableFormCheckboxLabelTextChecked: {
		width: 12,
		height: 12,
		border: 1,
		borderStyle: 'solid',
		borderColor: '#e5e5e5',
		padding: 1,
	},
	glassConfigTableFormCheckboxLabelTextCheckedImage: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
});

export default styles;

// .glass_config__item:nth-child(2) &,
// .glass_config__item:nth-child(3) &,
// .glass_config__item:nth-child(5) &,
// .glass_config__item:nth-child(6) & {
// 	background-color: $gray_5;
// }

// .glass_config__item.bg_mod & {
// 	background-color: $gray_5;
// }

// .glass_config__item:first-child & {
// 	padding-left: 4rem;
// }

// .glass_config__item:last-child & {
// 	padding-right: 9rem;
// }
