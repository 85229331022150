import React from 'react';
import FilterUIElement from 'components/ThreeJs/assets/filter/elementsUI/FilterUIElement';
import { useTranslation } from 'react-i18next';
import 'styles/blocks/selector.scss';
import camelToDash from './camelToDash';
import InfoBox from './InfoBox';

const GlassProductSelector = ({
	name,
	uiHandlerRef,
	wrapperClass,
	labelClass,
}) => {
	const dashkey = camelToDash(name);
	const { t } = useTranslation('configurationCategory');
	let label = t(name);
	let infoLabel = t(`${name}_info`);

	return (
		<label htmlFor={dashkey.concat('_input')} className={wrapperClass}>
			<FilterUIElement isDisabled id={dashkey} uiHandlerRef={uiHandlerRef} defaultVisible={dashkey === 'manufacturer' || dashkey === 'clear-glass' || dashkey === 'resistance-class'} />
			<div style={{ fontVariant: 'small-caps' }} className={labelClass}>
				{label}
				{infoLabel
					? (
						<span className="glass_product_selection_info">
							<sup>
								<InfoBox hoverMessage={infoLabel} />
							</sup>
						</span>
					)
					: (null)}
			</div>
		</label>
	);
};

export default GlassProductSelector;
