/* eslint-disable */
"use strict";

const __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(resolve => resolve(value)); }
    return new (P || (P = Promise))((resolve, reject) => {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator.throw(value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};

const __generator = (this && this.__generator) || function (thisArg, body) {
    let _ = { label: 0, sent: () => { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    g = { next: verb(0), throw: verb(1), return: verb(2) };
    if (typeof Symbol === "function") g[Symbol.iterator] = function () { return this; };
    return g;

    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y.return : op[0] ? y.throw || ((t = y.return) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};

import fetch from "node-fetch";

const host = "https://www.zefix.ch/ZefixREST/api/v1/firm";
const headers = { "content-type": "application/json" };

const jsonRequest = async (path, { method = "GET", data } = {}) => {
    const body = JSON.stringify(data);
    const response = await fetch(host + path, { method, body, headers });
    return response.json();
};

export const getSearch = async (name) => {
    const data = { name, maxEntries: 15, languageKey: "en", offset: 0 };
    const j = await jsonRequest("/search.json", { data, method: "POST" });
    if (j.error) {
        const { code } = j.error;
        if (code === "API.ZFR.SEARCH.NORESULT") {
            return { list: [], hasMoreResults: false };
        }
        throw new Error(code);
    }
    return j;
};

export const byEhraid = async (ehraid) => {
    const url = `/${ehraid}.json`;
    const j = await jsonRequest(url, {});
    if (j.error) {
        throw new Error(j.error.code);
    }
    return j;
};

export const getMeta = async (name) => {
    const s = await getSearch(name);
    return byEhraid(s[0].id);
};
