import React from 'react';

import Pagination from 'react-js-pagination';
import { ReactComponent as RightIcon } from 'i/icons/arrow_right.svg';
import { ReactComponent as LeftIcon } from 'i/icons/arrow_left.svg';

const PaginationBlock = ({
	activePaginationPage,
	totalItemsCount,
	itemsCountPerPage,
	handlePaginationPageChange,
}) => {
	return (
		<div className="pag_block">
			<Pagination
				activePage={activePaginationPage}
				prevPageText={<LeftIcon />}
				nextPageText={<RightIcon />}
				firstPageText={null}
				lastPageText={null}
				itemsCountPerPage={itemsCountPerPage}
				totalItemsCount={totalItemsCount}
				onChange={handlePaginationPageChange}
			/>
		</div>
	);
};

export default PaginationBlock;
