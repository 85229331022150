import React, { useContext, useRef, useState } from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { firebaseAuth } from 'utils/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { BlockHeading } from 'components/BlockHeading';
import { UiContext } from 'context/ui';
import { PROMISE_STATES } from 'utils/constant';
import { ButtonSubmit } from 'components/Buttons';
import { emailValidation } from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';
import FormField from './FormField';

const LostPasswordForm = ({
	formInPopup,
	toggleLogged,
}) => {
	const { t } = useTranslation(['lostPasswordForm', 'firebaseErrorMessages']);
	const formikRef = useRef(null);
	const { showPopupByKey } = useContext(UiContext);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const notificationsHandler = useRef(new ToastifyHandler());

	const closePopup = () => {
		showPopupByKey();
	};

	const showPopup = popupKey => {
		if (!popupKey) return;

		showPopupByKey(popupKey);
	};

	const validate = {
		resetEmail: email => emailValidation(email),
	};

	const initialValues = {
		resetEmail: '',
	};

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={values => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={async (values) => {
				const { resetEmail } = values;
				try {
					setLoadingStatus(PROMISE_STATES.pending);
					notificationsHandler.current.pending(t('notifications:pleaseWait'));

					await sendPasswordResetEmail(firebaseAuth, resetEmail);

					setLoadingStatus(PROMISE_STATES.fulfilled);
					showPopupByKey();

					notificationsHandler.current.setAutoCloseDuration(3000);
					notificationsHandler.current.success(t('notifications:emailWithInstuctionsToResetPasswordHasBeenSent'));
				} catch (error) {
					const { code } = error;
					notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
					setLoadingStatus(PROMISE_STATES.rejected);
				}
			}}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
			}) => (
				<form className="lost_password_form" onSubmit={handleSubmit}>
					<button className="popup_close" type="button" onClick={closePopup}>
						<CloseIcon className="icon icon-close size_mod" />
					</button>
					<div className="lost_password_form__in">
						<BlockHeading title={t('title')} />
						<div className="form_fields">
							<div className="form_fields__item">
								<FormField
									id="resetEmail"
									placeholder={t('baseFormPlaceholders:userEmailPlaceholder')}
									name="resetEmail"
									iconName="mail"
									type="email"
									required
									values={values}
									errors={errors}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<div className="form_fields__error">{touched.resetEmail && errors.resetEmail}</div>
							</div>
						</div>
						<div className="popup_btn">
							<ButtonSubmit
								buttonText={t('submitButton')}
								isDisabled={loadingStatus === PROMISE_STATES.pending}
							/>
						</div>
						<div className="login_form__note">
							{t('dontHaveAccount')}
							<a
								href="#"
								onClick={(e) => {
									if (formInPopup) {
										e.stopPropagation();
										showPopup('registration');
									} else {
										toggleLogged();
									}
								}}
							>
								{t('signUp')}
							</a>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default LostPasswordForm;
