// eslint-disable-next-line object-curly-newline
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import GlassConfigTableTextarea from './GlassConfigTableTextarea';
import { POSITION_INCREASE } from './constant';

const GlassConfigTableRowNotesCell = React.forwardRef(({
	cellData,
	cellIndex,
	cellRefs,
	lastPositionIndex,
	rowIndex,
	positionAction,
	formElementIdRef,
	handleInputBlur,
	setActiveRowIndex,
}, ref) => {
	const { /* name, */value } = cellData;
	const [isInputOpened, setInputOpened] = useState(false);
	const [textareaValue, setTextareaValue] = useState(value);
	const [storedInputValueState, setStoredInputValueState] = useState(value);
	const inputRef = useRef(null);

	useEffect(() => {
		if (value !== storedInputValueState) {
			let trans = cellData.transact();
			trans.value = storedInputValueState;
			cellData.run();
		}
	}, [cellData, value, storedInputValueState]);

	const glassConfigTableCellClasses = classNames('glass_config_table__notes_cell', 'glass_config_table__cell', {
		'glass_config_table__cell--notes_state': value !== '-' && value !== '',
	});

	const hideInput = () => {
		setInputOpened(false);
		inputRef.current.blur();
	};

	const showInput = () => {
		setInputOpened(true);
	};

	useImperativeHandle(ref, () => ({
		showInput,
	}));

	const setInputValue = e => {
		const { value: inputValue } = e.target;

		if (inputValue !== value) {
			setStoredInputValueState(inputValue);
		}

		hideInput();
	};

	const handleInputKeyDown = e => {
		if (e.which === 13 /* enter */ || e.which === 9 /* tab */) {
			setInputValue(e);
			if (e.shiftKey) {
				cellRefs.current[cellIndex - 1].current.showInput();
			} else {
				setActiveRowIndex(rowIndex + 1);
			}
		}
	};

	const onTextareaChangeHandler = e => {
		const { value: areaValue } = e.target;

		setTextareaValue(areaValue);
	};

	const onBlurHandler = e => {
		setInputValue(e);
		if (handleInputBlur) {
			handleInputBlur();
		}
	};

	const onFocusHandler = () => {
		showInput();
	};

	useEffect(() => {
		if (isInputOpened && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isInputOpened]);

	useEffect(() => {
		if (lastPositionIndex !== null && lastPositionIndex >= 0 && lastPositionIndex === rowIndex && cellIndex === 0 && positionAction === POSITION_INCREASE) {
			showInput();
		}
	}, [lastPositionIndex, rowIndex, cellIndex, positionAction]);

	return (
		<div className={glassConfigTableCellClasses}>
			<div className="glass_config_table__cell_value">
				{!isInputOpened ? (
					<button
						className="glass_config_table__cell_value_in"
						onClick={showInput}
						onFocus={onFocusHandler}
						type="button"
					>
						{value === '' ? '-' : value}
					</button>
				) : (
					<GlassConfigTableTextarea
						ref={inputRef}
						id={formElementIdRef.current}
						name={formElementIdRef.current}
						value={textareaValue}
						onBlur={onBlurHandler}
						onChange={onTextareaChangeHandler}
						onKeyDown={handleInputKeyDown}
					/>
				)}
			</div>
		</div>
	);
});

export default GlassConfigTableRowNotesCell;
