import React, { useContext, useState } from 'react';
import { BlockHeading } from 'components/BlockHeading';
import { FormFieldWithLabel } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { ConfigurationDataContext } from 'context/configuration';

const YourReference = ({
	values,
	parent,
}) => {
	const { t } = useTranslation('cartPage');
	const [inputsValue, setInputsValue] = useState({
		...values,
	});
	const { showEmptyReferenceWarning } = useContext(ConfigurationDataContext);

	const setValue = (inputName, inputValue) => {
		parent.set(inputName, inputValue);
	};

	const onBlurHandler = e => {
		const { value, name } = e.target;

		if (value !== inputsValue[name]) {
			const updatedState = {
				...inputsValue,
				[name]: value,
			};

			setInputsValue(updatedState);
			setValue(name, value);
		}
	};

	return (
		<div className="your_reference">
			<BlockHeading
				title={t('yourReference')}
				optionalTitle={t('yourReferenceOptionalTitle')}
				iconName="duble_arrow"
			/>
			<div className="your_reference_container">
				<div className="your_reference_w">
					<div className="your_reference_item">
						<div className="your_reference_item_inner">
							<FormFieldWithLabel
								id="reference"
								labelTitle={t('yourReferenceInputLabel')}
								placeholder={t('yourReferenceInputLabelPlaceholder')}
								defaultValue={inputsValue.reference}
								name="reference"
								onBlur={onBlurHandler}
								extraClassName={
									showEmptyReferenceWarning
									&& inputsValue.reference.trim().length === 0
									&& inputsValue.buildingReference.trim().length === 0
									&& 'your_reference_empty'
								}
							/>
						</div>
					</div>
					<div className="your_reference_item">
						<div className="your_reference_item_inner">
							<FormFieldWithLabel
								id="buildingReference"
								labelTitle={t('buildingReferenceInputLabel')}
								defaultValue={inputsValue.buildingReference}
								placeholder={t('buildingReferenceInputLabelPlaceholder')}
								name="buildingReference"
								onBlur={onBlurHandler}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourReference;
