import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth';
import { Formik } from 'formik';
import {
	nameValidation,
	fieldValidation,
} from 'utils';
import { updateUserData } from 'domain/user';
import { PROMISE_STATES } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import { USERS_API } from 'api';
import { updateProfile } from 'firebase/auth';
import jsonAppData from "utils/jsonAppData";
import FormField from '../FormField';
import FormCheckbox from '../FormCheckbox';

const UpdateUserNameForm = () => {
	const { t } = useTranslation(['baseFormPlaceholders', 'firebaseErrorMessages']);
	const { currentUser, currentUserAdditionalData, setCurrentUserAdditionalData } = useContext(AuthContext);
	const [initialFormValues, setInitialFormValues] = useState(null);
	const [hideMessageStatus, setHideMessageStatus] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const formikRef = useRef();
	const notificationsHandler = useRef(new ToastifyHandler());
	const genderFieldData = jsonAppData.registrationNewUserForm.find(o => o.id === 'userGender');
	const genderOptions = genderFieldData.options.map((option) => ({
		...option,
		label: t(option.label),
	}));
	const functionFieldData = jsonAppData.registrationNewUserForm.find(o => o.id === 'userFunction');
	const functionOptions = functionFieldData.options.map((option) => ({
		...option,
		label: t(option.label),
	}));

	const formikValidate = {
		userFirstName: firstName => nameValidation(t('userFirstNamePlaceholder'), firstName),
		userLastName: lastName => nameValidation(t('userLastNamePlaceholder'), lastName),
		userGender: (value) => fieldValidation(t("userGenderPlaceholder"), value),
		userFunction: () => {
			return null;
		},
	};

	const createInitialFormValues = () => {
		const {
			name: userFirstName, lastName: userLastName, gender: userGender, function: userFunction,
		} = currentUserAdditionalData;
		const initialValues = {
			userFirstName,
			userLastName,
			userGender,
			userFunction,
		};
		setInitialFormValues(initialValues);
	};

	useEffect(() => {
		if (currentUser) {
			createInitialFormValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	useEffect(() => {
		if (currentUserAdditionalData) {
			setHideMessageStatus(currentUserAdditionalData.hideStructureChangeMessage);
		}
	}, [currentUserAdditionalData]);

	const hideMessageHandler = () => {
		setHideMessageStatus(!hideMessageStatus);
	};

	return (
		initialFormValues ? (
			<Formik
				initialValues={initialFormValues}
				innerRef={formikRef}
				mapPropsToValues={() => {
					return {
						...initialFormValues,
					};
				}}
				validate={values => Object.keys(values).reduce((errors, field) => {
					const error = formikValidate[field](values[field]);
					return {
						...errors,
						...(error && { [field]: error }),
					};
				}, {})}
				onSubmit={async (values) => {
					const {
						userFirstName: name,
						userLastName: lastName,
						userGender,
						userFunction,
					} = values;

					const updatedUserData = updateUserData(currentUserAdditionalData, !hideMessageStatus);

					const newUpdatedUserData = {
						...updatedUserData,
						name,
						lastName,
						gender: userGender,
						function: userFunction,
						hideStructureChangeMessage: hideMessageStatus,
					};

					setLoadingStatus(PROMISE_STATES.pending);
					notificationsHandler.current.pending(t('notifications:pleaseWait'));

					try {
						await updateProfile(currentUser, {
							displayName: `${name} ${lastName}`,
						});

						await USERS_API.updateUserData(currentUser.uid, newUpdatedUserData);
						setCurrentUserAdditionalData(newUpdatedUserData);

						setLoadingStatus(PROMISE_STATES.fulfilled);
						notificationsHandler.current.success(t('notifications:dataHasBeenSuccessfullyUpdated'));
					} catch (error) {
						const { code } = error;
						notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
						setLoadingStatus(PROMISE_STATES.rejected);
					}
				}}
				validateOnChange={false}
			>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					values,
					errors,
					touched,
					setFieldTouched,
					setFieldValue,
				}) => (
					<form className="account_form_el" onSubmit={handleSubmit}>
						<ul className="form_fields offset_2_mod">
							<li className="form_fields__item">
								<FormField
									id="userFirstName"
									placeholder={t('userFirstNamePlaceholder')}
									name="userFirstName"
									type="text"
									required
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
								/>
								<div className="form_fields__error">{touched.userFirstName && errors.userFirstName}</div>
							</li>
							<li className="form_fields__item">
								<FormField
									id="userLastName"
									placeholder={t('userLastNamePlaceholder')}
									name="userLastName"
									type="text"
									required
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
								/>
								<div className="form_fields__error">{touched.userLastName && errors.userLastName}</div>
							</li>
							<li className="form_fields__item">
								<FormField
									id={genderFieldData.id}
									placeholder={t(genderFieldData.keyForI18n)}
									name={genderFieldData.id}
									required={genderFieldData.isRequired}
									values={values}
									options={genderOptions}
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
								/>
							</li>
							<li className="form_fields__item">
								<FormField
									id={functionFieldData.id}
									placeholder={t(functionFieldData.keyForI18n)}
									name={functionFieldData.id}
									required={functionFieldData.isRequired}
									values={values}
									options={functionOptions}
									setFieldTouched={setFieldTouched}
									setFieldValue={setFieldValue}
								/>
							</li>
							<li className="form_fields__item">
								<FormCheckbox
									name="hideMessage"
									defaultChecked={hideMessageStatus}
									onChange={hideMessageHandler}
									isUppercase
									title={t('hideMessage')}
								/>
							</li>
						</ul>
						<button
							className="account_form__button"
							type="submit"
							disabled={loadingStatus === PROMISE_STATES.pending}
						>
							<div className="btn_base">{t('accountPage:saveButton')}</div>
						</button>
					</form>
				)}
			</Formik>
		) : null
	);
};

export default UpdateUserNameForm;
