import { Component, createRef } from "react";
import Select from 'react-select';
import { selectBoxStyles } from "utils/react-select-styles";

export default class AbstractUIElement extends Component {
  constructor(props, opts) {
    super(props);
    const { i18nSection, defaultVisible, menuPlacement } = opts;
    this.ref = createRef();
    this.uiHandlerRef = props.uiHandlerRef;
    this.componentInitialized = false;
    this.initialRenderPerformed = false;

    this.i18n_section = i18nSection || null;
    this.menuPlacement = menuPlacement || props.menuPlacement || "top";

    this.defaultOption = null;
    if (defaultVisible) {
      this.defaultOption = { value: 0, label: "-" };
    }

    this.state = {
      isDisabled: props.isDisabled || false,
      language: null,
      options: [...(this.defaultOption ? [this.defaultOption] : [])],
      selectedValue: null,
    };
  }

  t(str, i18n_section = null) {
    let section = i18n_section || this.i18n_section;
    return this.uiHandlerRef.current?.i18n.t(str, section);
  }

  /// Called by uiHandler.setLanguage
  onLanguageChange(language) {
    if (language != this.state.language) {
      let options = this.generateOptions();
      this.setState({ options, language });
    }
  }

  generateOptions() {
  }

  recreate(_selectedValue) {
  }

  set(_selectedValue) {
  }

  initComponent() {
    const uiHandler = this.uiHandlerRef.current;
    uiHandler.setUiElement(this, this.props.id)
    uiHandler.i18n.onLanguageChangeSubscribers.push(this);
    this.setState({ language: uiHandler.i18n.language });
  }

  componentDidMount() {
    this.shouldComponentUpdate(null, null);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.uiHandlerRef.current === null) {
      if (!this.initialRenderPerformed) {
        return true;
      }
      return false; // now wait for uiHandler
    }
    if (!this.componentInitialized) {
      this.initComponent();
      this.componentInitialized = true;
      return true;
    }
    if (nextState !== null && nextState.language !== this.uiHandlerRef.current.i18n.language) {
      // pending language change, don't bother updating
      return false;
    }
    return true;
  }

  clear() {
    this.setState({
      options: [...(this.defaultOption ? [this.defaultOption] : [])],
      selectedValue: null
    });
  }

  updateState(newState) {
    if (newState.language && newState.language != this.state.language) {
      this.setState(newState);
    }
    const uiHandler = this.uiHandlerRef.current;
    const arrEq = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, index) => value.value === arr2[index].value)
    if (this.state.selectedValue != newState.selectedValue || !arrEq(this.state.options, newState.options)) {
      this.setState(newState);
    }
  }

  setDisabled(value) {
    if (value != this.state.isDisabled) {
      this.setState({ isDisabled: value });
    }
  }

  handleSelectChange = (_selectedOption) => {
    console.warn("Handler not properly linked");
  }

  render() {
    const { isDisabled, options, selectedValue } = this.state;

    return (
      <div ref={this.ref}>
        <Select
          isDisabled={isDisabled}
          id={this.props.id}
          value={selectedValue !== null ? options.find(o => o.value === selectedValue) : options[0]}
          onChange={this.handleSelectChange}
          options={options}
          styles={this.selectStyles || selectBoxStyles}
          onMenuOpen={() => document.documentElement.classList.add('select_open_mod')}
          onMenuClose={() => document.documentElement.classList.remove('select_open_mod')}
          isSearchable={false}
          classNamePrefix='r-select'
          inputId={this.props.id.concat('_input')}
          menuPlacement={this.menuPlacement}
          openMenuOnFocus
        />
      </div>
    );
  }
}
