/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useContext, useRef, useState } from "react";
import emailjs from "emailjs-com";

import { pdf } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { PageNavHeading } from "components/PageNavHeading";
import { SaveAsSection } from "components/SaveAsSection";
import { ConfigurationBlock } from "components/ConfigurationBlock";
import { BlockHeading } from "components/BlockHeading";
import { TermsBlock } from "components/TermsBlock";
import { OrderConfirmationSection } from "components/OrderConfirmationSection";
import { resetFilters } from "components/ThreeJs/assets/main";
import { AuthContext } from "context/auth";
import { ConfigurationDataContext } from "context/configuration";
import { CONFIGURATION_TYPE, PROMISE_STATES } from "utils/constant";
import { PdfConfigurationsDocument } from "components/PdfComponents";
import { CONFIGURATIONS_API } from "api";
import { cloneObjectWithoutReference, convertFileToBase64 } from "utils/index";
import ToastifyHandler from "utils/ToastifyHandler";
import userInfo from "utils/userInfo";
import i18n from "utils/i18n";

const SummaryPage = () => {
  const { t } = useTranslation(['requestOrOrderPage', 'firebaseErrorMessages']);
  const { guestUser, currentUser, currentUserAdditionalData, setGuestUser } =
    useContext(AuthContext);
  const {
    configuration,
    setConfiguration,
    totalGlassSizes,
    totalGlassSizesSum,
  } = useContext(ConfigurationDataContext);
  const [sendingEmailStatus, setSendingEmailStatus] = useState(
    PROMISE_STATES.default
  );
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [confirmationPageData, setConfirmationPageData] = useState(null);
  const notificationsHandler = useRef(new ToastifyHandler());
  const deOrderType = (orderType) => t(orderType, { ns: 'email', lng: 'de' });
  const toEmail = (orderType) => (orderType === CONFIGURATION_TYPE.order
    ? 'order@cdr.ch'
    : 'sub@cdr.ch');

  const createClientEmailData = (configurationPdfFileInBase64, attachments, orderType, reference) => {
    let orderTypeDE = deOrderType(orderType);
    let person = null;

    if (currentUser) {
      const { name, lastName, gender, email } = currentUserAdditionalData;
      person = {
        email,
        firstName: name,
        lastName,
        gender,
      };
    } else if (guestUser) {
      const { userFirstName, userLastName, userGender, email } = guestUser;
      person = {
        email,
        firstName: userFirstName,
        lastName: userLastName,
        gender: userGender,
      };
    } else {
      return null;
    }

    const salutation = t(`email:salutationFmt_${person.gender}`).replace(/\{(\w+)\}/g, (_match, key) => person[key] || '');
    const html_body = t(`email:confirmationBodyFmt_${orderType}`).replace('{salutation}', salutation);
    const subjectReference = reference ? `: ${reference}` : '';
    const subject = `${t(`email:confirmationSubject_${orderType}`)}${subjectReference}`;
    const reply_to = toEmail(orderType);
    const from_email = 'configurator@cdr.ch';

    return {
      from_email,
      reply_to,
      to_name: `${person.firstName} ${person.lastName}`,
      user_email: person.email,
      order_type_de: orderTypeDE,
      order_date: new Date(configuration.publishedAt).toLocaleString("de-CH"),
      subject,
      html_body,
      configuration_filename: 'Configuration.pdf',
      configuration_data: configurationPdfFileInBase64,
      num_attachments: Object.keys(attachments.files).length,
      ...attachments.files,
      ...attachments.names,
    };
  };

  const createAdminEmailData = (configurationPdfFileInBase64, attachments, orderType, reference) => {
    const orderTypeDE = deOrderType(orderType, { ns: 'email' });
    const to_email = toEmail(orderType);
    const from_email = 'configurator@cdr.ch';
    let ret = null;

    if (currentUser) {
      const { name: companyName, VATNumber, tel, zip, city, address } = currentUserAdditionalData.company;
      ret = {
        client_company_name: companyName,
        client_company_vat_number: VATNumber,
        client_email: currentUserAdditionalData.email,
        client_first_name: currentUserAdditionalData.name,
        client_last_name: currentUserAdditionalData.lastName,
        client_company_street: address,
        client_company_zip: zip,
        client_company_city: city,
        client_company_phone: tel,
        guest_order: 'Nein',
      };
    } else if (guestUser) {
      ret = {
        client_company_name: guestUser.companyName,
        client_company_vat_number: guestUser.companyVATNumber,
        client_email: guestUser.email,
        client_first_name: guestUser.userFirstName,
        client_last_name: guestUser.userLastName,
        client_company_street: guestUser.companyStreet,
        client_company_zip: guestUser.companyZIP,
        client_company_city: guestUser.companyCity,
        client_company_phone: guestUser.companyPhone,
        guest_order: 'Ja',
      };
    } else {
      return null;
    }

    return {
      ...ret,
      reference,
      client_lang: i18n.language.toUpperCase(),
      from_email,
      to_email,
      order_type_de: orderTypeDE,
      order_date: new Date(configuration.publishedAt).toLocaleString("de-CH"),
      configuration_filename: 'Configuration.pdf',
      configuration_data: configurationPdfFileInBase64,
      num_attachments: Object.keys(attachments.files).length,
      ...attachments.files,
      ...attachments.names,
    };
  };

  const sendEmail = async (
    updatedConfiguration,
    configurationPdfFileInBase64,
    attachmentList,
    orderType
  ) => {
    if (!configurationPdfFileInBase64) return;
    if (!updatedConfiguration) return;

    notificationsHandler.current.pending(
      t("notifications:emailSendingInProgress")
    );
    setSendingEmailStatus(PROMISE_STATES.pending);

    const attachments = attachmentList.reduce((acc, curr, index) => {
      const attachmentKey = `file${index + 1}`;
      acc.files[attachmentKey] = curr.url;
      acc.names[`${attachmentKey}_name`] = curr.name;
      return acc;
    }, { files: {}, names: {} });

    const clientEmail = createClientEmailData(
      configurationPdfFileInBase64,
      attachments,
      orderType,
      updatedConfiguration.reference || updatedConfiguration.buildingReference,
    );
    const adminEmail = createAdminEmailData(
      configurationPdfFileInBase64,
      attachments,
      orderType,
      updatedConfiguration.reference || updatedConfiguration.buildingReference,
    );

    const promises = [];
    promises.push(emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_ADMIN_TEMPLATE_ID,
      adminEmail,
      process.env.REACT_APP_EMAILJS_USER_ID,
    ));
    promises.push(emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_USER_TEMPLATE_ID,
      clientEmail,
      process.env.REACT_APP_EMAILJS_USER_ID,
    ));
    promises.push(CONFIGURATIONS_API.addNewConfiguration(updatedConfiguration));
    const [adminEmailRes, clientEmailRes, configRes] = await Promise.allSettled(promises);
    if (adminEmailRes.status === 'fulfilled' && clientEmailRes.status === 'fulfilled' && configRes.status === 'fulfilled') {
      notificationsHandler.current.success(
        t(orderType === CONFIGURATION_TYPE.order ? "notifications:orderSuccessful" : "notifications:quoteSuccessful")
      );
    } else {
      notificationsHandler.current.rejected(t("notifications:anErrorHasOccurred"));
      setSendingEmailStatus(PROMISE_STATES.rejected);
      if (adminEmailRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error sending admin email");
      }
      if (clientEmailRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error sending client email");
      }
      if (configRes.status !== 'fulfilled') {
        // eslint-disable-next-line no-console
        console.error("Error updating configuration");
      }
      return;
    }

    setConfirmationPageData({ orderType, email: clientEmail.user_email });
    setSendingEmailStatus(PROMISE_STATES.success);

    setConfiguration(null);
    if (guestUser) {
      setGuestUser(null);
    }
    resetFilters();
  };

  const handleSubmit = async (orderType) => {
    const clonedConfiguration = cloneObjectWithoutReference(configuration);

    const preparedOrderType = orderType || CONFIGURATION_TYPE.request;

    clonedConfiguration.type = preparedOrderType;

    const getConfigurationPdfFile = async () => {
      const blob = await pdf(
        <PdfConfigurationsDocument
          configuration={clonedConfiguration}
          customerInfo={userInfo({ guestUser, currentUser, currentUserAdditionalData, companyInfo: currentUserAdditionalData?.company })}
          totalValues={totalGlassSizes}
          totalValuesSum={totalGlassSizesSum}
          showPrice={currentUserAdditionalData?.company?.showPrices || false}
        />
      ).toBlob();
      const file = new File([blob], "ConfigurationPDF.pdf");

      return file;
    };

    try {
      const configurationPdfFile = await getConfigurationPdfFile();
      const configurationPdfFileInBase64 = await convertFileToBase64(
        configurationPdfFile
      );
      const attachments = clonedConfiguration.uploadedDocuments;

      sendEmail(
        clonedConfiguration,
        configurationPdfFileInBase64,
        attachments,
        preparedOrderType
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  if (sendingEmailStatus === PROMISE_STATES.success && confirmationPageData) {
    return (
      <OrderConfirmationSection {...confirmationPageData} />
    );
  }

  if (!configuration || !(guestUser || currentUser)) {
    return (
      <section className="section">
        <div className="section__in">
          <PageNavHeading />
          <div className="section__block">
            <h1 className="section__title section__title--center_hr_state">
              {t('cartPage:cartIsEmpty')}
            </h1>
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className="section request_or_order_page">
        <div className="section__in">
          <PageNavHeading />
          <BlockHeading
            title={t("commonAppValues:configuration")}
            iconName="config_code"
          />
          <ConfigurationBlock
            reference={configuration.reference}
            buildingReference={configuration.buildingReference}
            amountGlassTypes={configuration.amountGlassTypes}
            created={configuration.created}
            lastEdited={configuration.lastEdited}
            code={configuration.code}
            isOrderPage
          />
          <TermsBlock setIsTermsChecked={setIsTermsChecked} />
          <div className="request_or_order_page__buttons">
            <button
              className="btn_v3"
              type="button"
              onClick={() => handleSubmit("request")}
              disabled={
                sendingEmailStatus === PROMISE_STATES.pending ||
                !isTermsChecked
              }
            >
              {t("request")}
            </button>
            <button
              className="btn_v3"
              type="button"
              disabled={
                sendingEmailStatus === PROMISE_STATES.pending ||
                !isTermsChecked
              }
              onClick={() => handleSubmit("order")}
            >
              {t("order")}
            </button>
          </div>
        </div>
      </section>
      <SaveAsSection />
    </>
  );
};

export default SummaryPage;
