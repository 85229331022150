import React from "react";

import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import {
	confirmFilterChanges,
	abortFilterChanges,
} from "components/ThreeJs/assets/main";

import { clearAllBrowserUrlParameter } from 'utils';
import { useNavigate } from 'react-router-dom';
import HideStructureChangeCheckbox from 'components/Form/HideStructureChangeCheckbox';
import usePopup from "./usePopup";

const ConfirmFilterChangesPopup = ({ isOpen }) => {
	const { closePopup, popupData, popupFrame } = usePopup({
		isOpen, onPopupExit: clearAllBrowserUrlParameter, extraClassName: "reset_glass_popup",
	});
	const { t } = useTranslation("confirmFilterChangesForm");
	const navigate = useNavigate();

	const confirmChanges = () => {
		confirmFilterChanges();

		navigate("/");
		closePopup();
	};

	const abortChanges = () => {
		abortFilterChanges();

		navigate("/");
		closePopup();
	};

	return popupFrame(
		<div className="popup_content">
			{popupData.popupType === "StructureChanged" && (
				<>
					<BlockHeading
						title={t("titleStructureChanged")}
						iconName="pensil"
					/>
					<div className="popup_note">
						{t("textStructureChanged")
							.replace("{oldStruct}", popupData.oldStruct)
							.replace("{newStruct}", popupData.newStruct)}
					</div>
				</>
			)}
			{popupData.popupType === "FiltersReset" && (
				<>
					<BlockHeading
						title={t("titleFiltersReset")}
						iconName="pensil"
					/>
					<div className="popup_note">
						{t("textFiltersReset").replace(
							"{filters}",
							popupData.filters.join(", "),
						)}
					</div>
				</>
			)}
			<div className="confirm_changes">
				<div className="confirm_btn">
					<button
						type="button"
						className="btn_base"
						onClick={confirmChanges}
					>
						{t("yesChange")}
					</button>
				</div>
				<div className="abort_btn">
					<button
						type="button"
						className="btn_base"
						onClick={abortChanges}
					>
						{t("noAbort")}
					</button>
				</div>
				<HideStructureChangeCheckbox title={t('hideMessage')} />
			</div>
		</div>,
	);
};

export default ConfirmFilterChangesPopup;
