import React from 'react';

import { ConfigurationCodeForm } from 'components/Form';
import usePopup from './usePopup';

const ConfigurationCodePopup = ({
	isOpen,
}) => {
	const { popupFrame } = usePopup({ isOpen, extraClassName: "v2_mod" });
	return popupFrame(<ConfigurationCodeForm />);
};

export default ConfigurationCodePopup;
