import React, { useRef, useState } from 'react';

import { ReactComponent as FileIcon } from 'i/icons/file_text.svg';
import { ReactComponent as ViewIcon } from 'i/icons/view.svg';
import { ReactComponent as BurgerIcon } from 'i/icons/burger.svg';

import { CONFIGURATION_STATUS, CONFIGURATION_TYPE } from 'utils/constant';
import jsonAppData from 'utils/jsonAppData';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigurationBlockDraftsOptions } from './partials';

const ConfigurationBlockControls = ({
	configurationType,
	configurationCode,
	configurationStatus,
	uploadedDocuments,
	configuration,
}) => {
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const menuButtonRef = useRef(null);

	const toggleDropdownPopup = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const hideDropdownPopup = () => {
		setTimeout(() => {
			setDropdownOpen(false);
		}, 200);
	};

	const handleOnClickViewButton = () => {
		const previewPageUrl = `/preview/${configurationCode}`;

		navigate(previewPageUrl);
	};

	const checkDropdownFoldingUpwards = () => {
		const viewportHeight = window.innerHeight;
		const { bottom } = menuButtonRef.current.getBoundingClientRect();

		const spaceBelow = viewportHeight - bottom;
		// Check if the space below the button is less than 20% of the viewport height
		return (spaceBelow < viewportHeight * 0.2);
	};

	const renderConfigurationOptions = () => {
		// on admin dashboard page show different options
		const foldUp = checkDropdownFoldingUpwards();
		if (location.pathname === '/admin-dashboard') {
			if (configurationStatus === CONFIGURATION_STATUS.open) {
				return (
					<ConfigurationBlockDraftsOptions
						configurationCode={configurationCode}
						configuration={configuration}
						setOptionsPopupOpen={setDropdownOpen}
						dropdownOptions={jsonAppData.openOrderAndRequestConfigurationDropdown}
						foldUp={foldUp}
					/>
				);
			}

			return (
				<ConfigurationBlockDraftsOptions
					configurationCode={configurationCode}
					configuration={configuration}
					setOptionsPopupOpen={setDropdownOpen}
					dropdownOptions={jsonAppData.closedOrderAndRequestConfigurationDropdown}
					foldUp={foldUp}
				/>
			);
		}

		if (configurationType === CONFIGURATION_TYPE.drafts) {
			return (
				<ConfigurationBlockDraftsOptions
					configurationCode={configurationCode}
					configuration={configuration}
					setOptionsPopupOpen={setDropdownOpen}
					dropdownOptions={jsonAppData.draftsConfigurationDropdown}
					foldUp={foldUp}
				/>
			);
		} else {
			return (
				<ConfigurationBlockDraftsOptions
					configurationCode={configurationCode}
					configuration={configuration}
					setOptionsPopupOpen={setDropdownOpen}
					dropdownOptions={jsonAppData.orderAndRequestConfigurationDropdown}
					foldUp={foldUp}
				/>
			);
		}
	};

	return (
		<div className="configuration_block_controls">
			{uploadedDocuments && uploadedDocuments.length ? (
				<button className="configuration_block_controls__button configuration_block_controls__button--file_state" type="button">
					<FileIcon className="icon icon-file_text" />
				</button>
			) : null}
			<button
				className="configuration_block_controls__button configuration_block_controls__button--view_state"
				type="button"
				onClick={handleOnClickViewButton}
			>
				<ViewIcon className="icon icon-view" />
			</button>
			<div
				className="configuration_block_controls__button configuration_block_controls__button--menu_state"
				onClick={toggleDropdownPopup}
				onBlur={hideDropdownPopup}
				type="button"
				onKeyDown={toggleDropdownPopup}
				role="button"
				tabIndex="0"
			>
				<BurgerIcon ref={menuButtonRef} className="icon icon-burger" />
				{isDropdownOpen ? (
					renderConfigurationOptions()
				) : null}
			</div>
		</div>
	);
};

export default ConfigurationBlockControls;
