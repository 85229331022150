import React,
{
	useRef,
	useContext,
	useEffect,
} from 'react';
import { ReactComponent as FaqIcon } from 'i/icons/faq_2.svg';
import { AuthContext } from 'context/auth';
import { UiContext } from 'context/ui';
import FAQArrowImage from 'i/faq-arrow.png';

import { gsap } from 'gsap';
import { AppDataContext } from 'context/appData';
import { useTranslation } from 'react-i18next';

const FaqBlock = () => {
	const { t } = useTranslation('faq');
	const { showPopupByKey, faqBlockAnimTl } = useContext(UiContext);
	const { currentUser } = useContext(AuthContext);
	const $elem = useRef();
	const $decor = useRef();
	const $btn = useRef();
	const $arrow = useRef();

	const { pagesData } = useContext(AppDataContext);

	const initAnim = () => {
		const elemHoverTl = gsap.timeline({
			paused: true,
		});

		const elemClickTl = gsap.timeline({
			paused: true,
		});
		const elemArrowTl = gsap.timeline({});

		faqBlockAnimTl
			.addLabel('onload_anim_start')
			.to($elem.current, {
				opacity: 1,
				pointerEvents: 'auto',
				duration: 0,
			}, 'onload_anim_start')
			.fromTo($decor.current, {
				scaleX: 0,
				scaleY: 2,
			}, {
				scaleX: 1,
				scaleY: 1,
				duration: 0.8,
				ease: 'power2.out',
			}, 'onload_anim_start')
			.fromTo($btn.current, {
				opacity: 0,
			}, {
				opacity: 1,
				duration: 0.6,
			}, 'onload_anim_start+=.5')
			.addLabel('onload_anim_end');

		elemHoverTl
			.fromTo($decor.current, {
				scaleX: 1,
				scaleY: 1,
			}, {
				scaleX: 1.05,
				scaleY: 0.98,
				duration: 0.4,
			});

		elemClickTl
			.to($btn.current, {
				scale: 1.1,
				duration: 0.2,
				ease: 'none',
			})
			.to($btn.current, {
				scale: 1,
				duration: 0.2,
				ease: 'none',
			});

		if (currentUser) {
			$arrow.current.hidden = true;
		} else {
			elemArrowTl
				.addLabel('onload_anim_start')
				.to($arrow.current, {
					opacity: 1,
					duration: 0,
				}, 'onload_anim_start')
				.fromTo($arrow.current, {
					opacity: 0,
				}, {
					opacity: 1,
					duration: 0.8,
					ease: 'power2.out',
				}, 'onload_anim_start+=.5')
				.addLabel('onload_anim_end')
				.to($arrow.current, {
					x: '-120%',
					duration: 0.8,
					ease: 'power3.out',
				}, 'onload_anim_start+=2.5')
				.to($arrow.current, {
					opacity: 0,
					duration: 0,
				}, 'onload_anim_start+=3.3')
				.fromTo($decor.current, {
					scaleX: 1,
					scaleY: 1,
				}, {
					scaleX: 1.15,
					scaleY: 0.95,
					duration: 0.4,
				})
				.to($decor.current, {
					scaleX: 1,
					scaleY: 1,
					duration: 0.4,
				});
		}

		faqBlockAnimTl.play();

		$elem.current.addEventListener('mouseenter', () => {
			elemHoverTl.play();
		});

		$elem.current.addEventListener('mouseleave', () => {
			elemHoverTl.reverse();
		});

		$btn.current.addEventListener('click', () => {
			elemClickTl.restart();
		});
	};

	const handleOnClick = () => {
		showPopupByKey('faq');
	};

	useEffect(() => {
		if (pagesData) {
			initAnim();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagesData]);

	return (
		<div className="faq_block" ref={$elem}>
			<div className="faq_block_decor">
				<div className="faq_block_decor_in" ref={$decor} />
			</div>
			<button
				className="faq_block_btn"
				type="button"
				onClick={handleOnClick}
				ref={$btn}
			>
				<FaqIcon className="icon icon-faq size_mod" />
			</button>
			<div className="faq_block_instruction" ref={$arrow}>
				<div className="faq_block_instruction_text">{t('instruction')}</div>
				<img src={FAQArrowImage} alt="" className="faq_block_instruction_arrow" />
			</div>
		</div>
	);
};

export default FaqBlock;
