// main logic
import UIHandler from './UIHandler.js';
// filters
import FilterHandler from './FilterHandler.js';

class Filters {

  constructor(context, setCurrentGlassConfig) {
    this.filterHandler = new FilterHandler();
    this.filterHandler.initFilters();

    this.ui = new UIHandler(this.filterHandler, window.glass_ViewApi, context, setCurrentGlassConfig);
    this.ui.loadDb();
  }
};

export { Filters };
