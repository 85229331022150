import React, { useContext } from 'react';
import { AppDataContext } from 'context/appData';

const FaqNotFoundResult = () => {
	const { langApp, themeSettings } = useContext(AppDataContext);

	return (
		themeSettings ? (
			<div className="faq_result">
				{themeSettings.no_results_title && (
					<div className="faq_result_title offset_mod">{themeSettings.no_results_title[langApp]}</div>
				)}
				{themeSettings.no_results_text && (
					<div className="faq_search_descr">{themeSettings.no_results_text[langApp]}</div>
				)}
			</div>
		) : null
	);
};

export default FaqNotFoundResult;
