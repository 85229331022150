import React from 'react';

import Logo from 'i/logo.png';
import { View, Image, Text } from '@react-pdf/renderer';
import jsonAppData from 'utils/jsonAppData';
import styles from './styles';

const PdfHeader = ({ documentType, customerInfo }) => {
	const { contactInformation } = jsonAppData;

	return (
		<View style={styles.header}>
			<View style={styles.headerLeftGroup}>
				<View style={styles.headerLogo}>
					<Image src={Logo} style={styles.headerLogoImg} />
				</View>

				{contactInformation && contactInformation.length ? (
					<View style={styles.headerContactInfo}>
						{contactInformation.map(({
							title,
						}, index) => {
							return (
								<Text style={styles.headerContactInfoItem} key={index}>{title}</Text>
							);
						})}
					</View>
				) : null}
			</View>

			{documentType ? (
				<Text style={styles.headerCenter}>{documentType}</Text>
			) : null}

			<View style={styles.headerRightGroup}>
				{customerInfo ? (
					<View style={styles.headerContactInfo}>
						{customerInfo.companyName ? <Text style={styles.headerCustomerInfoItem} key="companyName">{customerInfo.companyName}</Text> : null}
						{customerInfo.companyStreet ? <Text style={styles.headerCustomerInfoItem} key="companyStreet">{customerInfo.companyStreet}</Text> : null}
						{customerInfo.userDisplayName ? <Text style={styles.headerCustomerInfoItem} key="userDisplayName">{customerInfo.userDisplayName}</Text> : null}
						{customerInfo.companyZip ? <Text style={styles.headerCustomerInfoItem} key="companyZipCity">{`${customerInfo.companyZip} ${customerInfo.companyCity}`}</Text> : null}
						{customerInfo.companyPhone ? <Text style={styles.headerCustomerInfoItem} key="companyPhone">{customerInfo.companyPhone}</Text> : null}
						{customerInfo.companyVATNumber ? <Text style={styles.headerCustomerInfoItemLC} key="companyVATNumber">{customerInfo.companyVATNumber}</Text> : null}
						{customerInfo.userEmail ? <Text style={styles.headerCustomerInfoItemLC} key="userEmail">{customerInfo.userEmail}</Text> : null}
					</View>
				) : (
					<Text style={styles.headerCustomerInfoItem}>&nbsp;</Text>
				)}
			</View>
		</View>
	);
};

export default PdfHeader;
