/* eslint-disable react/react-in-jsx-scope */
import DOMPurify from "isomorphic-dompurify";

export const SanitaizedText = ({ children }) => {
	if (!children) return null;

	const sanitized = DOMPurify.sanitize(children);

	// eslint-disable-next-line react/no-danger
	return <span dangerouslySetInnerHTML={{ __html: sanitized }} />;
};

export default SanitaizedText;
