// eslint-disable-next-line object-curly-newline
import React, { useEffect, useState, useMemo, useContext } from 'react';
import detectBrowserLanguage from 'detect-browser-language';

import {
	getAllPages,
	getThemeSettings,
	getMainMenuItems,
	getAllFaqData,
	getAllFaqCategories,
} from 'api/data';
import { getItemFromLocalStorage } from 'utils';
import i18n from 'utils/i18n';
import { AuthContext } from 'context/auth';
import AppDataContext from './appDataContext';

const AppDataState = ({ children }) => {
	const { currentUserAdditionalData } = useContext(AuthContext);
	const [pagesData, setPagesData] = useState(null);
	const [mainMenuData, setMainMenuData] = useState(null);
	const [themeSettings, setThemeSettings] = useState(null);
	const [allFaqArchive, setAllFaqArchive] = useState(null);
	const [allFaqCategories, setAllFaqCategories] = useState(null);
	const [langApp, setLangApp] = useState(null);
	const [isAppDataLoaded, setAppDataLoaded] = useState(false);

	useMemo(() => {
		if (langApp && i18n.language !== langApp) {
			i18n.changeLanguage(langApp);
		}
	}, [langApp]);

	const languageDetection = () => {
		if (currentUserAdditionalData?.language) {
			setLangApp(currentUserAdditionalData.language);
			return false;
		}
		const userLocalStorageLang = getItemFromLocalStorage('user-language');
		if (userLocalStorageLang) {
			setLangApp(userLocalStorageLang);
			return false;
		}

		const userBrowserLanguage = detectBrowserLanguage();
		let preparedLangApp = 'de';

		if (themeSettings?.languages) {
			themeSettings.languages.forEach(({
				code,
			}) => {
				let lowerCaseCodeValue = code.toLowerCase();

				if (userBrowserLanguage.includes(lowerCaseCodeValue)) {
					preparedLangApp = lowerCaseCodeValue;
				}
			});
		}

		setLangApp(preparedLangApp);
		return false;
	};

	const getAllAppData = async () => {
		// Start all asynchronous operations simultaneously
		const promises = [
			getAllPages(),
			getMainMenuItems(),
			getThemeSettings(),
			getAllFaqData(),
			getAllFaqCategories(),
		];

		// Wait for all promises to settle
		const results = await Promise.allSettled(promises);

		// results is an array of objects with {status, value, reason}
		// Each object represents the outcome of the corresponding promise

		// Check and handle each result, update state as necessary
		const [resAllPagesData, resMainMenu, themeSettingsData, allFaqData, allFaqCategoriesData] = results;

		if (resAllPagesData.status === 'fulfilled' && resAllPagesData.value) {
			setPagesData(resAllPagesData.value);
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to load all pages data:', resAllPagesData.reason);
		}

		if (resMainMenu.status === 'fulfilled' && resMainMenu.value) {
			setMainMenuData(resMainMenu.value);
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to load main menu items:', resMainMenu.reason);
		}

		if (themeSettingsData.status === 'fulfilled' && themeSettingsData.value) {
			setThemeSettings(themeSettingsData.value);
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to load theme settings:', themeSettingsData.reason);
		}

		if (allFaqData.status === 'fulfilled' && allFaqData.value) {
			setAllFaqArchive(allFaqData.value);
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to load FAQ data:', allFaqData.reason);
		}

		if (allFaqCategoriesData.status === 'fulfilled' && allFaqCategoriesData.value) {
			setAllFaqCategories(allFaqCategoriesData.value);
		} else {
			// eslint-disable-next-line no-console
			console.error('Failed to load FAQ categories:', allFaqCategoriesData.reason);
		}

		// Indicate that the app data has finished loading
		setAppDataLoaded(true);
	};

	useEffect(() => {
		getAllAppData();
	}, []);

	useEffect(() => {
		languageDetection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserAdditionalData?.language, themeSettings]);

	return (
		<AppDataContext.Provider
			value={useMemo(() => {
				return {
					themeSettings,
					langApp,
					setLangApp,
					pagesData,
					setPagesData,
					mainMenuData,
					allFaqArchive,
					setAllFaqArchive,
					allFaqCategories,
					setAllFaqCategories,
					isAppDataLoaded,
				};
			}, [themeSettings, langApp, pagesData, allFaqArchive, allFaqCategories, isAppDataLoaded, mainMenuData])}
		>
			{children}
		</AppDataContext.Provider>
	);
};

export default AppDataState;
