import React, {
	useContext,
} from 'react';

import { updateUserData } from 'domain/user';
import { AuthContext } from 'context/auth';
import { UiContext } from 'context/ui';
import { USERS_API } from 'api';
import FormCheckbox from './FormCheckbox';

const HideStructureChangeCheckbox = ({ title }) => {
	const { currentUser, currentUserAdditionalData, setCurrentUserAdditionalData } = useContext(AuthContext);
	const { hideMessageStatus, setHideMessageStatus } = useContext(UiContext);

	const hideMessageHandler = async () => {
		if (currentUser) {
			const updatedUserData = updateUserData(currentUserAdditionalData, !hideMessageStatus);
			await USERS_API.updateUserData(currentUser.uid, {
				...updatedUserData,
			});
			setCurrentUserAdditionalData(updatedUserData);
		}
		setHideMessageStatus(!hideMessageStatus);
	};

	return (
		<FormCheckbox
			id="HideStructureChangeCheckbox"
			name="HideStructureChangeCheckbox"
			title={title}
			isUppercase
			defaultChecked={hideMessageStatus}
			onChange={hideMessageHandler}
		/>
	);
};

export default HideStructureChangeCheckbox;
