import emailjs from "emailjs-com";
import {
	telLink,
	telFmt,
	encodeHTML,
} from "utils";
import jsonAppData from "utils/jsonAppData";

export const sendNewCompanyUserEmail = async (newUser, company, t, peerRegistration = null) => {
	// CAREFUL: only VATNumber from company is to be trusted/same as the existing company
	// here the new user's entries for the existing company and not the DB records are used.
	const userName = encodeHTML(`${newUser.name} ${newUser.lastName}`);
	const fnLabel = jsonAppData.registrationNewCompanyUserForm
		.find(o => o.id === 'userFunction')?.options
		.find(o => o.value === newUser.function)?.label;
	const title = peerRegistration
		? `Ein neuer Benutzer wurde im Konfigurator von ${encodeHTML(peerRegistration)} bei ${encodeHTML(company.name)} registriert`
		: `Ein neuer Benutzer hat sich im Konfigurator bei ${encodeHTML(company.name)} registriert`;
	const htmlEmailBody = `<p>${title}</p>
		<p><ul>
		<li>Firma: <strong>${encodeHTML(company.name)}</strong></li>
		<li>Name: <strong>${userName}</strong></li>
		<li>Funktion: <strong>${encodeHTML(fnLabel ? t(fnLabel, { ns: 'baseFormPlaceholders', lng: 'de' }) : newUser.function)}</strong></li>
		<li>Tel: <strong><a href="${telLink(company.tel)}">${telFmt(company.tel)}</a></strong></li>
		<li>Email: <strong><a href="mailto:${(encodeURIComponent(newUser.email))}">${encodeHTML(newUser.email)}</a></strong></li>
		<li>MwSt Nr: <strong><a href="https://www.uid.admin.ch/Detail.aspx?uid_id=${encodeURIComponent(company.VATNumber)}">${encodeHTML(company.VATNumber)}</a></strong></li>
		<li>Sprache: <strong>${newUser.language}</strong></li>
		</ul></p>`;
	const emailProps = {
		subject: `Neuer Benutzer ${userName} bei Firma ${company.name}`,
		html_body: htmlEmailBody,
		from_name: 'CDR Configurator',
		from_email: 'configurator@cdr.ch',
		to_email: 'gabriel@cdr.ch',
		cc_email: 'info@cdr.ch',
		bcc_email: null,
		reply_to: 'gabriel@cdr.ch',
	};

	await emailjs.send(
		process.env.REACT_APP_EMAILJS_SERVICE_ID,
		process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
		emailProps,
		process.env.REACT_APP_EMAILJS_USER_ID,
	);
};

export const sendNewCompanyEmail = async (newUser, newCompany, duplicateVAT) => {
	const oneLineAddr = encodeHTML(`${newCompany.address}, ${newCompany.zip} ${newCompany.city}`);
	const duplicateVATWarning = duplicateVAT
		? '<p style="color: red; font-weight: bold">ACHTUNG DIESE MwSt Nr IST BEREITS IM SYSTEM HINTERLEGT</p>'
		: '';
	const htmlEmailBody = `<p>Ein neuer Kunde hat sich im Konfigurator registriert</p>
		<p><ul>
		<li>Firma: <strong>${encodeHTML(newCompany.name)}</strong></li>
		<li>Name: <strong>${(encodeHTML(`${newUser.name} ${newUser.lastName}`))}</strong></li>
		<li>Email: <strong><a href="mailto:${(encodeURIComponent(newUser.email))}">${encodeHTML(newUser.email)}</a></strong></li>
		<li>MwSt Nr: <strong><a href="https://www.uid.admin.ch/Detail.aspx?uid_id=${encodeURIComponent(newCompany.VATNumber)}">${encodeHTML(newCompany.VATNumber)}</a></strong></li>
		<li>Adresse: <strong>${oneLineAddr}</strong> <a href="https://www.google.ch/maps/search/${encodeURIComponent(oneLineAddr)}">(Google Maps)</a></li>
		<li>Tel: <strong><a href="${telLink(newCompany.tel)}">${telFmt(newCompany.tel)}</a></strong></li>
		<li>Sprache: <strong>${newCompany.language}</strong></li>
		</ul></p>${duplicateVATWarning}<p>Logge dich mit einem Admin Account ein um die Preisfreigabe im
		<strong><a href="https://configurator.cdr.ch/admin-dashboard">Admin Dashboard</a></strong>
		zu erteilen und die ERP Kunden Nr. zu hinterlegen</p>`;
	const emailProps = {
		subject: `Neue Firma Registriert: ${newCompany.name}`,
		html_body: htmlEmailBody,
		from_name: 'CDR Configurator',
		from_email: 'configurator@cdr.ch',
		to_email: 'gabriel@cdr.ch',
		cc_email: 'info@cdr.ch',
		bcc_email: null,
		reply_to: 'gabriel@cdr.ch',
	};

	await emailjs.send(
		process.env.REACT_APP_EMAILJS_SERVICE_ID,
		process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
		emailProps,
		process.env.REACT_APP_EMAILJS_USER_ID,
	);
};
