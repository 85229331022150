import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	headerLeftGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerRightGroup: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	headerLogo: {
		width: 56,
		height: 35,
		marginRight: 26,
	},
	headerLogoImg: {
		objectFit: 'contain',
		width: '100%',
		height: '100%',
	},
	headerContactInfo: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		maxWidth: 210,
	},
	headerContactInfoItem: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
		textTransform: 'uppercase',
	},
	headerCenter: {
		position: 'absolute',
		left: '50%',
		top: '5%',
		transform: 'translate(-50%, 0)',
		fontSize: 14,
		fontWeight: 700,
		textTransform: 'uppercase',
		color: '#456d86',
		lineHeight: 0.9,
		width: 'auto',
		height: '100%',
	},
	headerCustomerInfoItem: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
		textTransform: 'uppercase',
	},
	headerCustomerInfoItemLC: {
		width: '50%',
		maxWidth: '210px',
		fontSize: 8,
		color: '#707070',
	},
});

export default styles;
