import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import { UiContext } from 'context/ui';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { CSSTransition } from 'react-transition-group';

const usePopup = ({
	isOpen,
	onPopupExit,
	extraClassName,
}) => {
	const [ignoreEventState, setIgnoreEventState] = useState(true);
	const $popupRef = useRef(null);
	const { showPopupByKey, popupData } = useContext(UiContext);

	const closePopup = () => {
		showPopupByKey();
	};

	const handleClickOutside = (event) => {
		if (isOpen && $popupRef.current && !$popupRef.current.contains(event.target)) {
			showPopupByKey();
		}
	};

	useEffect(() => setIgnoreEventState(false), []);

	// eslint-disable-next-line no-underscore-dangle
	const _onPopupEntering = () => {
		document.body.classList.add('filter_mod');
		if (ignoreEventState) {
			document.addEventListener('click', handleClickOutside);
		}
	};

	// eslint-disable-next-line no-underscore-dangle
	const _onPopupExit = () => {
		document.body.classList.remove('filter_mod');
		document.removeEventListener('click', handleClickOutside);
		if (onPopupExit) onPopupExit();
	};

	const popupFrame = (children) => {
		return (
			<CSSTransition
				in={isOpen}
				timeout={{
					enter: 500,
					exit: 350,
				}}
				classNames="popup"
				onEntering={_onPopupEntering}
				onExit={_onPopupExit}
				mountOnEnter
				unmountOnExit
			>
				<div className="popup">
					<div ref={$popupRef} className={`popup_in ${extraClassName}`}>
						<button className="popup_close popupClose" type="button" onClick={closePopup}>
							<CloseIcon className="icon icon-close size_mod" />
						</button>
						{children}
					</div>
				</div>
			</CSSTransition>
		);
	};

	return { closePopup, popupFrame, popupData };
};

export default usePopup;
