import { useContext, useEffect } from 'react';
import { getGlassSizes } from 'domain/glass';
import { ConfigurationDataContext } from 'context/configuration';
import { AuthContext } from 'context/auth';

const useGlassSize = (configurationJsonData) => {
	const {
		setTotalGlassSizes,
		setTotalGlassSizesSum,
		setIsSizesFilled,
		setErrorFields,
	} = useContext(ConfigurationDataContext);
	const { currentUserAdditionalData } = useContext(AuthContext);

	useEffect(() => {
		if (!configurationJsonData?.glassTypes?.length) return;
		const {
			values, valuesSum, isSizesValid, emptyFields,
		} = getGlassSizes(configurationJsonData.glassTypes, currentUserAdditionalData?.company?.showPrices || false);

		setTotalGlassSizes(values);
		setIsSizesFilled(isSizesValid);
		setTotalGlassSizesSum(valuesSum);
		setErrorFields(emptyFields);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configurationJsonData, currentUserAdditionalData]);
};

export default useGlassSize;
