import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';
import usePopup from './usePopup';

const GenericWarningPopup = ({
	isOpen,
}) => {
	const { closePopup, popupData, popupFrame } = usePopup({
		isOpen,
		extraClassName: "generic_warning",
		onPopupExit: () => popupData.confirmDialog && popupData.confirmDialog(false),
	});
	const { t } = useTranslation('genericWarningPopup');
	const $confirmButton = useRef(null);

	const handleConfirm = () => {
		closePopup();
		if (popupData.confirmDialog) {
			popupData.confirmDialog(true);
		}
	};

	useEffect(() => {
		if (isOpen && $confirmButton.current) {
			$confirmButton.current.focus();
		}
	}, [isOpen, $confirmButton]);

	return popupFrame(
		<>
			<div className="generic_warning__in">
				<BlockHeading title={popupData.title || t('warning')} iconName={popupData.confirmDialog ? "question" : "warning"} offsetMod />
				<div className="generic_warning__text popup_note">{popupData.message}</div>
			</div>
			{
				popupData.confirmDialog
					? (
						<div className="generic_warning__button">
							<button
								className="btn_base"
								type="button"
								onClick={handleConfirm}
								ref={$confirmButton}
							>
								{t('confirm')}
							</button>
						</div>
					)
					: null
			}
		</>,
	);
};

export default GenericWarningPopup;
